/**
 * FeaturePath: 會員系統-其它-工具
 * Accountable: AlexCH Cheng, Landy Chu
 */

import auth from 'react/utils/auth';
import { easyFetch, bodyFetch, bodyFetchMainSite, errorProcessing } from './endpoint';

const authRequest = {
  async fetchAuthStatus() {
    return easyFetch('/api/user/status', 'GET')
      .then(({ user }) => Promise.resolve({ success: true, user }))
      .catch(errorProcessing);
  },
  async toggleWish(postData) {
    return bodyFetch('/api/user/togglewish', 'POST', postData)
      .then(({ user: { wishList } }) => Promise.resolve({ success: true, wishList }))
      .catch(auth.errorProcessing);
  },
  async fetchBanner() {
    return easyFetch('/api/home/banner', 'GET', 'from=ilearn')
      .then(({ banners }) => Promise.resolve({ success: true, banners }))
      .catch(errorProcessing);
  },
  async clickBanner(id) {
    return easyFetch(`/api/home/banner/click/${id}`, 'GET')
      .then(() => Promise.resolve({ success: true }))
      .catch(errorProcessing);
  },
  async modifyPwd(postData) {
    return bodyFetchMainSite('/api/account/modifyPwd', 'POST', postData)
      .then(result => Promise.resolve({ success: true, result }))
      .catch(err => Promise.resolve({ success: false, result: err }));
  },
  async modifyInfo(postData) {
    return bodyFetchMainSite('/api/account/modify/v2', 'POST', postData)
      .then(result => Promise.resolve({ success: true, result }))
      .catch(auth.errorProcessing);
  },
};

export default authRequest;
