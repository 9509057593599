/**
 * FeaturePath: 會員系統-其它-首頁
 * Accountable: AlexCH Cheng, Landy Chu
 */

import React from 'react';
import classnames from 'classnames';

import styles from './styles.css';


const Icon = ({ img, count, dotShow }) => (
  <div className={styles.icon}>
    <img src={img} alt="icon" />
    <span>{count}</span>
    <div className={classnames(styles.dot, {
      [styles.show]: dotShow,
    })}
    />
  </div>
);

export default Icon;
