/**
 * FeaturePath: 會員系統-其它-條款-隱私權政策
 * FeaturePath: 會員系統-其它-條款-服務條款
 * Accountable: Tom Jhuang, Landy Chu
 */

import { bodyFetch, errorProcessing } from './endpoint';

const ROOT_NAME = 'article';

const articleRequest = {
  /**
   * 取得條款內容
   * @param data.type 1.服務條款 2.隱私權政策
   */
  async fetchArticle(data: {
    type: number;
  }): Promise<{ success: boolean; content?: any }> {
    return bodyFetch(`/api/${ROOT_NAME}/find`, 'POST', data)
      .then(({ content }) => Promise.resolve({ success: true, content }))
      .catch(errorProcessing);
  },
};

export default articleRequest;
