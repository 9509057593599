exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1_7lx5gLjD61SVwDwVRYos{width:100%;height:60px;line-height:60px;border-radius:30px;border:none;background:linear-gradient(90deg,#5ac864,#3caa8c);padding:0;cursor:pointer;margin:3px 0;outline:none;color:#fff;font-size:20px;text-align:center}._1_7lx5gLjD61SVwDwVRYos.Tfsx_wrVD0YVNTZgLbTDe{border-radius:50px;width:50px}._1_7lx5gLjD61SVwDwVRYos.Tfsx_wrVD0YVNTZgLbTDe._3ewoytLar7cB9G0W46Deat>div>span:first-child{width:100%;margin:0}._1_7lx5gLjD61SVwDwVRYos._3AkqXOJ_ijoCFuWwMhbNPw{background:#ccc;cursor:not-allowed}._1_7lx5gLjD61SVwDwVRYos._3AkqXOJ_ijoCFuWwMhbNPw:active{background:#ccc}@media screen and (1100px > width >= 720px),screen and (width < 720px){._1_7lx5gLjD61SVwDwVRYos{height:40px;border-radius:20px;font-size:18px}}._1FHPBeeq7yqnrIJkUpN50F{background:#f29600;font-size:18px;border-radius:20px}._3ZldBOQ6gMw6Mtqs6xuT1Y{background:#3caa8c;font-size:18px;border-radius:20px}._3TBlriTnwE6l0ahp3M8r_j{height:40px;line-height:40px;border-radius:20px}._21w1pqqjtn4OaCyesN9d3C{height:50px;line-height:50px;border-radius:25px}._3nKgbzayBShff5HmJimGww{height:32px;line-height:32px;border-radius:16px}", ""]);

// exports
exports.locals = {
	"basic": "_1_7lx5gLjD61SVwDwVRYos",
	"square": "Tfsx_wrVD0YVNTZgLbTDe",
	"icon": "_3ewoytLar7cB9G0W46Deat",
	"disabled": "_3AkqXOJ_ijoCFuWwMhbNPw",
	"orange": "_1FHPBeeq7yqnrIJkUpN50F _1_7lx5gLjD61SVwDwVRYos",
	"green": "_3ZldBOQ6gMw6Mtqs6xuT1Y _1_7lx5gLjD61SVwDwVRYos",
	"height_40px": "_3TBlriTnwE6l0ahp3M8r_j",
	"height_50px": "_21w1pqqjtn4OaCyesN9d3C",
	"height_32px": "_3nKgbzayBShff5HmJimGww"
};