/**
 * FeaturePath: 會員系統-其它-工具
 * Accountable: AlexCH Cheng, Landy Chu
 */

import _ from 'lodash';
import auth from '../utils/auth';
import { log } from '../utils/utils';

import { API_ENDPOINT, MAIN_ENDPOINT, CHAT_API_ENDPOINT, CLIENT_ID, CLIENT_SECRET } from '../../config';

const isIE = /* @cc_on!@ */false || !!document.documentMode;

function processStatus(response) {
  if (response.ok) {
    return response.json().then(res =>
      Promise.resolve(_.extend(res, {
        success: response.ok,
        status: response.status,
      })));
  }

  // Note: 測試用的 API 回傳資料，因為格式不太一樣
  if (response.unitTest) {
    return response;
  }

  return response.json()
    .then(res => Promise.resolve(_.extend(res, {
      success: false,
      status: response.status,
    })))
    .catch(err => {
      log('err :>> ', err);
      return Promise.resolve({
        success: false,
        status: response.status,
      });
    });
}

async function processChatStatus(response) {
  if (response.ok) {
    const json = await response.json();
    return Promise.resolve(_.extend(json, {
      success: json.code === 0,
      status: response.status,
    }));
  }

  return Promise.resolve({
    success: false,
    status: response.status,
  });
}

function errorHandler(error) {
  log(`There has been a problem with your fetch operation: ${error.message}`);
}

function responsePackage(jsonRes, url) {
  if (jsonRes.success) {
    return Promise.resolve(jsonRes.extras);
  }

  if (jsonRes.extras) {
    return Promise.reject(_.extend(jsonRes.extras, {
      status: jsonRes.status,
      message: jsonRes.extras.msg || jsonRes.extras.error || 0,
      url: url || '',
    }));
  }

  return Promise.reject({
    status: jsonRes.status,
    msg: 0,
    message: 0,
    url: url || '',
  });
}

function responseMainSitePackage(jsonRes, url) {
  if (jsonRes.message === '') {
    return Promise.resolve(jsonRes.result);
  }

  return Promise.reject({
    code: jsonRes.code,
    status: jsonRes.status,
    message: jsonRes.result ? jsonRes.result.msg : jsonRes.message || 0,
    url: url || '',
  });
}

function responseChatPackage(jsonRes, url) {
  if (!jsonRes) {
    return Promise.reject({
      status: 999,
      url: url || '',
    });
  }

  if (jsonRes.success) {
    return Promise.resolve(jsonRes.result);
  }

  return Promise.reject({
    status: jsonRes.status,
    code: jsonRes.code || -1,
    message: jsonRes.message || '',
    url: url || '',
  });
}

export const errorProcessing = error => {
  log('error :>> ', error);
  if (error) {
    return Promise.resolve({ success: false, err: error });
  }

  return Promise.resolve({ success: false });
};

/* Easy fetch is xhr for GET, DELETE..., request without body */
export const easyFetch = async (url, method, query) => {
  const options = {
    method,
    credentials: 'include',
  };
  if (isIE) {
    /* eslint  no-param-reassign: 0 */
    query += `ie=${new Date().getTime()}`;
  }
  const requestUri = query ? `${API_ENDPOINT}${url}?${query}` : `${API_ENDPOINT}${url}`;

  return fetch(requestUri, options)
    .then(processStatus)
    .catch(errorHandler)
    .then(jsonRes => responsePackage(jsonRes, requestUri));
};

export const authEasyFetch = async (url, method, query) => {
  const options = {
    method,
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.token()}`,
    },
  };
  if (isIE) {
    /* eslint  no-param-reassign: 0 */
    query += `ie=${new Date().getTime()}`;
  }
  const requestUri = query ? `${API_ENDPOINT}${url}?${query}` : `${API_ENDPOINT}${url}`;

  return fetch(requestUri, options)
    .then(processStatus)
    .catch(errorHandler)
    .then(jsonRes => responsePackage(jsonRes, requestUri));
};

/* Body fetch is xhr for POST, PUT..., request with body */
export const bodyFetch = async (url, method, postData, query) => {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    method,
    body: JSON.stringify(postData),
  };
  if (isIE) {
    /* eslint  no-param-reassign: 0 */
    query += `ie=${new Date().getTime()}`;
  }
  // eslint-disable-next-line no-nested-ternary
  const requestUri = query ? `${API_ENDPOINT}${url}?${query}` : `${API_ENDPOINT}${url}`;

  return fetch(requestUri, options)
    .then(processStatus)
    .catch(errorHandler)
    .then(jsonRes => responsePackage(jsonRes, requestUri));
};

export const bodyFetchMainSite = async (url, method, postData, query) => {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    method,
    body: JSON.stringify(postData),
  };
  if (isIE) {
    /* eslint  no-param-reassign: 0 */
    query += `ie=${new Date().getTime()}`;
  }
  // eslint-disable-next-line no-nested-ternary
  const requestUri = query ? `${MAIN_ENDPOINT}${url}?${query}` : `${MAIN_ENDPOINT}${url}`;

  return fetch(requestUri, options)
    .then(processStatus)
    .catch(errorHandler)
    .then(jsonRes => responseMainSitePackage(jsonRes, requestUri));
};

export const fetchMainSite = async (url, method, postData, query) => {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    method,
    body: JSON.stringify(postData),
  };
  if (isIE) {
    /* eslint  no-param-reassign: 0 */
    query += `ie=${new Date().getTime()}`;
  }
  // eslint-disable-next-line no-nested-ternary
  const requestUri = query ? `${MAIN_ENDPOINT}${url}?${query}` : `${MAIN_ENDPOINT}${url}`;

  return fetch(requestUri, options)
    .then(processStatus)
    .catch(errorHandler)
    .then(jsonRes => {
      const { success, result } = jsonRes;
      if (success) {
        return Promise.resolve(result);
      }

      if (jsonRes.extras) {
        return Promise.reject(_.extend(jsonRes.extras, {
          status: jsonRes.status,
          message: jsonRes.extras.msg || jsonRes.extras.error || 0,
          url: url || '',
        }));
      }

      return Promise.reject({
        status: jsonRes.status,
        msg: 0,
        message: 0,
        url: url || '',
      });
    });
};

export const authBodyFetch = async (url, method, postData, query) => {
  const options = {
    method,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.token()}`,
    },
    credentials: 'include',
    body: JSON.stringify(postData),
  };
  if (isIE) {
    /* eslint  no-param-reassign: 0 */
    query += `ie=${new Date().getTime()}`;
  }
  const requestUri = query ? `${API_ENDPOINT}${url}?${query}` : `${API_ENDPOINT}${url}`;

  return fetch(requestUri, options)
    .then(processStatus)
    .catch(errorHandler)
    .then(jsonRes => responsePackage(jsonRes, requestUri));
};

export const authBodyFetchRedirect = async (url, method, postData, query) => {
  const options = {
    method,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.token()}`,
    },
    credentials: 'include',
    body: JSON.stringify(postData),
    redirect: 'follow',
  };
  if (isIE) {
    /* eslint  no-param-reassign: 0 */
    query += `ie=${new Date().getTime()}`;
  }
  const requestUri = query ? `${API_ENDPOINT}${url}?${query}` : `${API_ENDPOINT}${url}`;

  return fetch(requestUri, options)
    .then(response => {
      if (response.ok) {
        if (response.redirected) {
          window.location.href = response.url;
        }
      }
    })
    .catch(errorHandler);
};

export const authUploadFetch = async (url, formData, query) => {
  const options = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${auth.token()}`,
    },
    body: formData,
  };
  const requestUri = query ? `${API_ENDPOINT}${url}?${query}` : `${API_ENDPOINT}${url}`;

  return fetch(requestUri, options)
    .then(processStatus)
    .catch(errorHandler)
    .then(jsonRes => responsePackage(jsonRes, requestUri));
};

export const uploadFetch = async (url, formData, query) => {
  const options = {
    method: 'POST',
    credentials: 'include',
    body: formData,
  };
  const requestUri = query ? `${API_ENDPOINT}${url}?${query}` : `${API_ENDPOINT}${url}`;

  return fetch(requestUri, options)
    .then(processStatus)
    .catch(errorHandler)
    .then(jsonRes => responsePackage(jsonRes, requestUri));
};

export const chatEasyFetch = async (url, method, query) => {
  const options = {
    method,
    credentials: 'include',
  };
  if (isIE) {
    /* eslint  no-param-reassign: 0 */
    query += `ie=${new Date().getTime()}`;
  }
  const requestUri = query ? `${CHAT_API_ENDPOINT}${url}?${query}` : `${CHAT_API_ENDPOINT}${url}`;

  return fetch(requestUri, options)
    .then(processChatStatus)
    .catch(errorHandler)
    .then(jsonRes => responseChatPackage(jsonRes, requestUri));
};

export const chatAuthEasyFetch = async (url, method, query) => {
  const options = {
    method,
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `basic ${btoa(`${CLIENT_ID}:${CLIENT_SECRET}`)}`,
    },
  };
  if (isIE) {
    /* eslint  no-param-reassign: 0 */
    query += `ie=${new Date().getTime()}`;
  }
  const requestUri = query ? `${CHAT_API_ENDPOINT}${url}?${query}` : `${CHAT_API_ENDPOINT}${url}`;

  return fetch(requestUri, options)
    .then(processChatStatus)
    .catch(errorHandler)
    .then(jsonRes => responseChatPackage(jsonRes, requestUri));
};

export const fileBufferFetch = async (url, method, query, fileExtension) => {
  const options = {
    method,
    credentials: 'include',
  };
  if (isIE) {
    /* eslint  no-param-reassign: 0 */
    query += `ie=${new Date().getTime()}`;
  }
  const requestUri = query ? `${API_ENDPOINT}${url}?${query}` : `${API_ENDPOINT}${url}`;

  try {
    const res = await fetch(requestUri, options);
    if (res.ok) {
      const disposition = res.headers.get('Content-Disposition');
      const filename = (disposition && disposition.split('=')[1]) || `file.${fileExtension}`;

      return {
        buffer: await res.arrayBuffer(),
        filename,
      };
    }
    // error handle
    const response = await res.json();
    const error = new Error();
    error.response = response;
    error.status = res.status;
    if (response.extras.msg === 0) {
      error.message = response.extras.error;
      throw error;
    }

    error.message = '發生未知錯誤，請聯絡 LINE 線上客服反應';
    throw error;
  } catch (err) {
    throw err;
  }
};

/**
 * 提供一個共用呼叫 API 的函式
 * @param {string} url 請求的 Url
 * @param {string} method 請求方法(GET, POST, DELETE, PATCH)
 * @param {string} query 查詢參數
 * @param {object} postData POST 的資料
 * @param {boolean} isAuthProcessing 是否執行身分驗證(當回傳的 status 為 401 時，導回首頁)，預設值為 true
 * @returns Fetch Promise
 */
export const commonFetch = async ({ url, method, query, postData, isAuthProcessing = true }) => {
  try {
    const result = postData ? await bodyFetch(url, method, postData, query) : await easyFetch(url, method, query);
    return await Promise.resolve({ ...result, success: true });
  } catch (error) {
    return isAuthProcessing ? auth.errorProcessing(error) : errorProcessing(error);
  }
};

/**
 * 提供一個共用帶身分認證呼叫 API 的函式
 * @param {string} url 請求的 Url
 * @param {string} method 請求方法(GET, POST, DELETE, PATCH)
 * @param {string} query 查詢參數
 * @param {object} postData POST 的資料
 * @param {boolean} isAuthProcessing 是否執行身分驗證(當回傳的 status 為 401 時，導回首頁)，預設值為 true
 * @returns Fetch Promise
 */
export const commonAuthFetch = async ({ url, method, query, postData, isAuthProcessing = true }) => {
  try {
    const result = postData ?
      await authBodyFetch(url, method, postData, query) : await authEasyFetch(url, method, query);
    return await Promise.resolve({ ...result, success: true });
  } catch (error) {
    return isAuthProcessing ? auth.errorProcessing(error) : errorProcessing(error);
  }
};
