/**
 * FeaturePath: 會員系統-其它-廣告-公告訊息
 * Accountable: AlexCH Cheng, Landy Chu
 */

// ----- import local modules -----
import { easyFetch, errorProcessing } from './endpoint';

// ----- constants -----
/**
 * 根目錄名稱
 */
const ROOT_NAME = 'home';

const homeRequest = {
  /**
   * 取得公告訊息
   */
  async fetchPopup(): Promise<{ success: boolean; list?: any[] }> {
    return easyFetch(`/api/${ROOT_NAME}/popup`, 'GET')
      .then(resp => Promise.resolve({ ...resp, success: true }))
      .catch(errorProcessing);
  },
};

export default homeRequest;
