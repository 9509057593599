/**
 * FeaturePath: 會員系統-其它-工具
 * Accountable: AlexCH Cheng, Landy Chu
 */

export const ProgramType = {
  NONE: 'NONE',
  DISCOUNT: 'DISCOUNT',
  BUY_N_GET_N: 'BUY_ONE_GET_N',
  PERCENT_OFF: 'PERCENT_OFF',
};

export const ProgramTypeName = {
  NONE: '無',
  DISCOUNT: '原價特價',
  BUY_N_GET_N: '買N送N',
  PERCENT_OFF: '百分比折數',
};

export const WeekdaysShort = {
  1: '周一',
  2: '周二',
  3: '周三',
  4: '周四',
  5: '周五',
  6: '周六',
  7: '周日',
};

export const GenderType = {
  0: '女',
  1: '男',
};

export const CHAT_STATUS = {
  Disconnected: 0,
  Processing: 1,
  Connected: 2,
  Closed: 3,
};

export const SIGN_STATUS = {
  NotSignedOut: -2,
  NotSignedIn: -1,
  Normal: 0,
  BeginSignIn: 1,
  SignedIn: 2,
  SignInExceeded: 3,
  BeginSignOut: 4,
  SignedOut: 5,
};

export const LIVE_SCRIPT_TYPE = {
  Advertise: 1,
  Marquee: 2,
  Announcement: 3,
};

export const COURSE_STAGE = {
  Normal: 0,
  BeforeSignIn: 1,
  SignIn: 2,
  Playing: 5,
  SignOut: 3,
  End: 4,
};

export const COURSE_TYPE = {
  Link: 'LINK',
  InClass: 'INCLASS',
  OnLine: 'ONLINE',
  Live: 'LIVE',
  Package: 'PACKAGE',
};

export const QUIZ_STATUS = {
  Not: 'NOT',
  Done: 'DONE',
  Pass: 'PASS',
  Applying: 'APPLYING',
  Verifying: 'VERIFYING',
  Approved: 'APPROVED',
  Fail: 'FAIL',
};

export const QUIZ_STATUS_NAME = {
  [QUIZ_STATUS.Not]: '',
  [QUIZ_STATUS.Done]: '測驗未通過',
  [QUIZ_STATUS.Pass]: '測驗通過',
  [QUIZ_STATUS.Applying]: '教育積分申請中',
  [QUIZ_STATUS.Verifying]: '教育積分送審中',
  [QUIZ_STATUS.Approved]: '教育積分申請通過',
  [QUIZ_STATUS.Fail]: '教育積分申請未通過',
};

export const QUIZ_STATUS_NAME_FOR_LEARNING_TABLE = {
  [QUIZ_STATUS.Not]: '',
  [QUIZ_STATUS.Done]: '測驗未過',
  [QUIZ_STATUS.Pass]: '測驗通過',
  [QUIZ_STATUS.Applying]: '尚未提交至審查單位',
  [QUIZ_STATUS.Verifying]: '審查單位審核中',
  [QUIZ_STATUS.Approved]: '通過審核',
  [QUIZ_STATUS.Fail]: '申請未通過',
};

export const DRAW_STATUS = {
  New: 1,
  Not_comp: 2,
  Comp: 3,
  Receive: 4,
  Expired: 6,
};

export const DRAW_STATUS_NAME = {
  [DRAW_STATUS.New]: '',
  [DRAW_STATUS.Not_comp]: '未兌換',
  [DRAW_STATUS.Comp]: '未兌換',
  [DRAW_STATUS.Receive]: '已兌換',
  [DRAW_STATUS.Expired]: '已過期',
};

export const PRIZE_TYPE = {
  TicketXpress: 1,
  Coupon: 2,
  Thanks: 3,
};

export const CHECK_WORK_CERT_VALIDITY_SCENARIO = {
  Buy: 1, // 購課
  Quiz: 2, // 測驗
  Class: 3, // 上課
  ApplyPoint: 4, // 申請積分
};

export const LICENSE_STATUS = {
  Verifying: 1,
  Approved: 2,
  Failed: 3,
};

export const LICENSE_STATUS_NAME = {
  [LICENSE_STATUS.Verifying]: '審核中',
  [LICENSE_STATUS.Approved]: '審核通過',
  [LICENSE_STATUS.Failed]: '審核未通過',
};

export const PURCHASE_STATUS = {
  Buy: 1,
  NotBuy: 2,
  CanNotBuy: 3,
};

// Note: 2022/10/1 後將會被移除
export const ERP_LOGIN = 'erp-login';
export const ERP_LOGIN_SUCCESS = 'erp-login-success';

export const TRANSITIONS_LOGIN = 'login';

export const PAY_TYPE = {
  CREDIT: 'Credit',
  ATM: 'ATM',
  CVS: 'CVS',
  FREE: 'FREE',
};

export const PAY_TYPE_NAME = {
  [PAY_TYPE.CREDIT]: '信用卡',
  [PAY_TYPE.ATM]: 'ATM轉帳',
  [PAY_TYPE.CVS]: '超商付款',
  [PAY_TYPE.FREE]: '免付費',
};

export const PAY_STATUS = {
  Paid: 1,
  NotPaid: 2,
  Cancel: 4,
};

export const PAY_STATUS_NAME = {
  [PAY_STATUS.Paid]: '已付款',
  [PAY_STATUS.NotPaid]: '未付款',
  [PAY_STATUS.Cancel]: '已取消',
};

export const BANK_NAME = {
  806: '元大銀行',
  808: '玉山銀行',
  118: '板信銀行',
  813: '台北富邦銀行',
  822: '中國信託銀行',
  '007': '第一銀行',
  '005': '土地銀行',
  827: '國泰世華銀行',
  814: '大眾銀行',
  '004': '台灣銀行',
};

export const PRODUCT_TYPE = {
  // 單堂課程
  SingleCourse: 'AA',
  // 組合包課程
  PackageCourse: 'AB',
  // 講義
  Handout: 'BA',
  // 訓練證明
  CompCertificate: 'BB',
  // 積分證明
  ApplicationCredit: 'BC',
};

export const COUPON_STATUS = {
  Unused: 1,
  Used: 2,
  Expired: 3,
};

export const COUPON_STATUS_NAME = {
  [COUPON_STATUS.Unused]: '未使用',
  [COUPON_STATUS.Used]: '已使用',
  [COUPON_STATUS.Expired]: '已逾期',
};

export const tabRouterIndex = {
  cartList: 0,
  wishList: 1,
  orderList: 2,
};

export const MEDIA_TYPE = {
  Desktop: 'desktop',
  Tablet: 'tablet',
  Phone: 'phone',
};

export const CERT_TYPE = {
  IdCard: '0',
  CaregiverLicense: '1',
};

export const QUIZ_TYPE = {
  YesOrNo: 'YESORNO',
  Multiple: 'MULTIPLE',
};

export const CATEGORY_TYPE = {
  Training: 'TRAINING',
  Compal: 'COMPAL',
  Credit: 'CREDIT',
};

export const CATEGORY_TYPE_NAME = {
  [CATEGORY_TYPE.Training]: '培訓課程',
  [CATEGORY_TYPE.Compal]: '仁寶 i 系統課程',
  [CATEGORY_TYPE.Credit]: '繼續教育積分課程',
};

// 檢查小卡片是否有效後的動作
export const CHECK_WORK_CERT_VALIDITY_ACTION = {
  NearExpirationNotice: 'NEAR_EXPIRATION_NOTICE', // 即將過期警告
  ShowMsg: 'SHOW_MSG', // 顯示訊息
};
