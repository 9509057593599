/* eslint-disable max-len */
/**
 * FeaturePath: 會員系統-其它-工具
 * Accountable: AlexCH Cheng, Landy Chu
 */

import React, { useCallback, useRef, useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import '@compal-swhq/ilearn_login/dist/ilearn_login.css';

import  { LoginPage } from '@compal-swhq/ilearn_login';

import {
  closeLoginDialog,
  onTransitionsPage,
} from 'react/actions/utils';

import {
  login,
  loginErrorAnimation,
  register,
  registerErrorAnimation,
  customRegister,
  sendVerify,
  reset,
  sendResetVerify,
  resetErrorAnimation,
  erpTransLogout,
} from 'react/actions/auth';

import IconClose from 'images/icon/close.svg';
import IconLogo from '../../../../../images/logo_icon.svg';

import { ERP_LOGIN, ERP_LOGIN_SUCCESS, TRANSITIONS_LOGIN } from '../../../../constants/enum';
import routePath from '../../../../constants/path';

import PopupModal from '../../Modal/PopupModal';
import Tab from '../../Tab';
import RegisterForm from './RegisterForm';
import ResetPasswordForm from './ResetPasswordForm';
import TransferAccountForm from './TransferAccountForm';
import TransferRegisterForm from './TransferRegisterForm';
import RegisterSuccess from './RegisterSuccess';

import styles from './styles.css';

const TABS_TYPE = {
  Login: 0,
  Register: 1,
  ResetPassword: 2,
  TransferAccountConfirm: 3,
  TransferRegister: 4,
  TransferRegisterNew: 5,
};

const ACCOUNT_TRANSFER_STATUS = [
  TABS_TYPE.TransferAccountConfirm,
  TABS_TYPE.TransferRegister,
  TABS_TYPE.TransferRegisterNew,
];

const ERP_LOGIN_LIST = [
  ERP_LOGIN,
  ERP_LOGIN_SUCCESS,
];

const LoginDialog = ({
  loginError,
  registerError,
  resetPasswordError,
  isShowLoginDialog,
  onCloseLoginDialog,
  onLogin,
  onRegister,
  onRegisterErrorAnimation,
  onCustomRegister,
  onSendVerify,
  onReset,
  onResetErrorAnimation,
  onSendResetVerify,
  isTransitions,
  transitionPath,
  onCloseTransitionsPage,
  isShowRegisterDialog,
  isActivitiesRegister,
  onErpTransLogout,
}) => {
  const refLoginForm = useRef(null);
  const refRegisterForm = useRef(null);
  const [tabIndex, setTabIndex] = useState(TABS_TYPE.Login);
  const [isShowRegisterSuccess, setIsShowRegisterSuccess] = useState(false);
  const [isShowCustomRegisterSuccess, setIsShowCustomRegisterSuccess] = useState(false);
  const [loginAccount, setLoginAccount] = useState('');
  const [isNeedReload, setIsNeedReload] = useState(false);
  const [transferAccountData, setTransferAccountData] = useState(null);
  const [account, setAccount] = useState('');
  const [password, setPassword] = useState('');

  const TABS = useMemo(() => {
    if (isActivitiesRegister) {
      return ['新朋友註冊'];
    }

    if (isTransitions) {
      return ['會員登入', '新朋友註冊'];
    }

    return ['會員登入', '新朋友註冊'];
  }, [isActivitiesRegister, isTransitions]);

  const isInAccountTrans = useMemo(() => ACCOUNT_TRANSFER_STATUS.includes(tabIndex), [tabIndex]);
  const isTransitionsERPLogin = useMemo(() => ERP_LOGIN_LIST.includes(transitionPath), [transitionPath]);
  const isTransitionsLogin = useMemo(() => transitionPath === TRANSITIONS_LOGIN, [transitionPath]);

  const transitionsUrl = useMemo(() => {
    const isCourseId = /^[a-zA-Z0-9]{24}$/.test(transitionPath);
    return isCourseId ? `${routePath.courseRoot}/${transitionPath}` : routePath.root;
  }, [transitionPath]);

  const handleCloseClick = useCallback(evt => {
    evt.preventDefault();

    if (isNeedReload) {
      window.location.reload();
      return;
    }

    onCloseLoginDialog();
    if (isTransitions) {
      onCloseTransitionsPage({});
      window.location.href = transitionsUrl;
    }
  }, [isTransitions, isNeedReload, transitionsUrl]);

  const handleTabClick = useCallback(index => {
    setTabIndex(index);
  }, []);

  const handleLoginSubmit = async () => {
    if (!account || !password) {
      return;
    }

    const {
      success,
      user,
      showCustomRegisterPage,
    } = await onLogin(account.toLocaleLowerCase(), password, isTransitionsERPLogin);

    if (success) {
      if (!showCustomRegisterPage) {
        window.location.reload();
        return;
      }

      const {
        account: userAccount = '',
        companies: userCompanies,
        name: userName = '',
        phone: userPhone = '',
        email: userEmail = '',
        accountId = '',
      } = user || {};
      let companyName = '';
      if (Array.isArray(userCompanies) && userCompanies.length > 0) {
        companyName = userCompanies[0]?.companyName || '';
      }

      setTransferAccountData({
        account: userAccount.replace(/^luna_/g, ''),
        name: userName,
        companyName,
        phone: userPhone,
        email: userEmail,
        accountId,
      });

      setIsNeedReload(true);
      setTabIndex(TABS_TYPE.TransferAccountConfirm);
    }
  };

  const handleForgotPasswordClick = useCallback(() => {
    setTabIndex(TABS_TYPE.ResetPassword);
  }, []);

  const handleGetVerifyCodeClick = useCallback(async mobileNumber => {
    const result = await onSendVerify(mobileNumber);
    return result;
  }, []);

  const handleRegisterSubmit = useCallback(async data => {
    const { success } = await onRegister(data);
    if (success) {
      setLoginAccount(data?.account);
      setIsShowRegisterSuccess(true);
    }
  }, []);

  const handleRegisterError = useCallback(() => {
    onRegisterErrorAnimation();
  }, []);

  const handleGetResetVerifyCodeClick = useCallback(async mobileNumber => {
    const result = await onSendResetVerify(mobileNumber);
    return result;
  }, []);

  const handleResetSubmit = useCallback(async data => {
    const result = await onReset(data);
    if (result) {
      alert('恭喜您重設密碼成功，請重新登入會員');
      setTabIndex(TABS_TYPE.Login);
    }
  }, []);

  const handleResetError = useCallback(() => {
    onResetErrorAnimation();
  }, []);

  const handleTransferClick = useCallback(() => {
    setTabIndex(TABS_TYPE.TransferRegister);
  }, []);

  const handleRegisterClick = useCallback(() => {
    setTabIndex(TABS_TYPE.TransferRegisterNew);
  }, []);

  const handleCustomRegisterSubmit = useCallback(async data => {
    if (await onCustomRegister(data)) {
      setLoginAccount(data?.account);
      setIsShowCustomRegisterSuccess(true);
    }
  }, []);

  // FIXME: 十月後移除，ERP轉移後登出
  const handleConfirmClick = useCallback(() => {
    if (isShowCustomRegisterSuccess) {
      if (onErpTransLogout) {
        onErpTransLogout();
      }
      return;
    }

    window.location.reload();
  }, [onErpTransLogout, isShowCustomRegisterSuccess]);

  useEffect(() => {
    if (isShowLoginDialog) {
      setTabIndex(TABS_TYPE.Login);
      if (refLoginForm.current) {
        refLoginForm.current.reset();
      }
    }

    if (isShowRegisterDialog) {
      setTabIndex(TABS_TYPE.Register);
      if (refRegisterForm.current) {
        refRegisterForm.current.reset();
      }
    }
  }, [isShowLoginDialog, isShowRegisterDialog]);


  const handleChangeAccount = event => {
    setAccount(event.target.value);
  };

  const handleChangePassword = event => {
    setPassword(event.target.value);
  };


  return (
    <React.Fragment>
      <PopupModal className={styles.modal} modalVisible={isShowLoginDialog || isShowRegisterDialog}>
        <div />
        <div
          className={classnames(styles.wrapper,
            {
              [styles.wrapperWidthNormal]: !isInAccountTrans,
              [styles.wrapperWidthTrans]: isInAccountTrans,
            })}
          style={{
            paddingTop: (tabIndex === TABS_TYPE.Login) ? '0' : '20px' }}
        >
          {
            tabIndex !== TABS_TYPE.Login &&
            <div
              className={styles.imgWrapper}
              onClick={handleCloseClick}
            >
              <img src={IconClose} title="關閉" alt="Close" />
            </div>
          }
          {
            tabIndex === TABS_TYPE.Login &&
              <LoginPage
                tabs={TABS}
                isShowHint={tabIndex === TABS_TYPE.Login && isTransitions && !isTransitionsLogin}
                hintText={isTransitionsERPLogin ? '請登入仁寶 i 學習™' : '學員上課請先登入'}
                errorText={loginError}
                onCloseClick={handleCloseClick}
                onTabClick={handleTabClick}
                onAccountChange={handleChangeAccount}
                onPasswordChange={handleChangePassword}
                onForgetPasswordClick={handleForgotPasswordClick}
                onLoginClick={handleLoginSubmit}
              />
          }
          {
            tabIndex === TABS_TYPE.Register &&
            <React.Fragment>
              <Tab
                className={isTransitions ? styles.tabTransitions : styles.tabs}
                tabs={TABS}
                selected={isActivitiesRegister ? 0 : tabIndex}
                onClick={handleTabClick}
              />
              <div className={styles.mainContent}>
                <RegisterForm
                  ref={refRegisterForm}
                  errorMessage={registerError}
                  onGetVerifyCodeClick={handleGetVerifyCodeClick}
                  onSubmit={handleRegisterSubmit}
                  onError={handleRegisterError}
                />
                <div className={styles.logoRow}>
                  <img src={IconLogo} alt="Logo" />
                </div>
              </div>
            </React.Fragment>
          }
          {
            tabIndex === TABS_TYPE.ResetPassword &&
            <div className={styles.forgotPasswordContent}>
              <h2>忘記密碼</h2>
              <ResetPasswordForm
                errorMessage={resetPasswordError}
                onGetVerifyCodeClick={handleGetResetVerifyCodeClick}
                onSubmit={handleResetSubmit}
                onError={handleResetError}
              />
              <div className={styles.logoRow}>
                <img src={IconLogo} alt="Logo" />
              </div>
            </div>
          }
          {
            tabIndex === TABS_TYPE.TransferAccountConfirm &&
            <div className={styles.transferAccountConfirmContent}>
              <h1>【公告】</h1>
              <div className={styles.transTitle}>
                111年10月3日起將使用<span className={styles.word}>仁寶 i 照護</span>帳號
                <span className={styles.emphasize}>
                  <u>無法</u>
                </span>
                登入<span className={styles.word}>仁寶 i 學習</span>
              </div>
              <div>
                <p>
                  使用<span className={styles.word}>仁寶 i 照護</span>帳號登入<span className={styles.word}>仁寶 i 學習</span>的學員，為提供學員更安全、
                  迅速又方便的學習環境需進行帳號移轉，統一以手機號碼作為<span className={styles.word}>仁寶 i 學習</span>的登入帳號。
                </p>
                <p>
                  <span className={styles.emphasize}>即日起至111年10月2日止</span>
                  ，使用<span className={styles.word}>仁寶 i 照護</span>帳號登入<span className={styles.word}>仁寶 i 學習</span>，需進行帳號移轉或註冊新會員作業。
                </p>
                <ol>
                  <li>帳號移轉：<strong>將原帳號之學習履歷資料完整移轉至新帳號中。</strong></li>
                  <li>註冊新會員：<strong>成為全新的<span className={styles.word}>仁寶 i 學習</span>會員，以前的課程資料不會進行留存。</strong></li>
                </ol>
                <p>
                  <span className={styles.emphasize}>
                    於111年10月3日起，將無法使用<span className={styles.word}>仁寶 i 照護</span>帳號登入<span className={styles.word}>仁寶 i 學習</span>
                  </span>，謝謝。
                </p>
              </div>
              <TransferAccountForm
                data={transferAccountData}
                onTransferClick={handleTransferClick}
                onRegisterClick={handleRegisterClick}
              />
            </div>
          }
          {
            tabIndex === TABS_TYPE.TransferRegister &&
            <div className={styles.transferAccountConfirmContent}>
              <h1 title="2323" >帳號移轉</h1>
              <TransferRegisterForm
                errorMessage={registerError}
                defaultData={transferAccountData}
                onGetVerifyCodeClick={handleGetVerifyCodeClick}
                onSubmit={handleCustomRegisterSubmit}
                onRegisterClick={handleRegisterClick}
                onError={handleRegisterError}
              />
            </div>
          }
          {
            tabIndex === TABS_TYPE.TransferRegisterNew &&
            <div className={styles.transferAccountConfirmContent}>
              <h1>註冊新會員</h1>
              <RegisterForm
                errorMessage={registerError}
                onGetVerifyCodeClick={handleGetVerifyCodeClick}
                onSubmit={handleRegisterSubmit}
                onTransferClick={handleTransferClick}
                onError={handleRegisterError}
                isTransferRegisterNew
              />
            </div>
          }
        </div>
      </PopupModal>
      <RegisterSuccess
        account={loginAccount}
        isShow={isShowRegisterSuccess || isShowCustomRegisterSuccess}
        isCustomRegister={isShowCustomRegisterSuccess}
        onConfirmClick={handleConfirmClick}
      />
    </React.Fragment>
  );
};

const mapStateToProps = ({
  utils: {
    isShowLoginDialog,
    isShowRegisterDialog,
    isActivitiesRegister,
    transitions: {
      isTransitions,
      transitionPath,
    },
  },
  auth: {
    loginError,
    registerError,
    resetPasswordError,
  },
}) => ({
  isShowLoginDialog,
  loginError,
  registerError,
  resetPasswordError,
  isTransitions,
  transitionPath,
  isShowRegisterDialog,
  isActivitiesRegister,
});

const mapDispatchToProps = dispatch => ({
  onCloseLoginDialog: () => dispatch(closeLoginDialog()),
  onLogin: (account, password, isERPLogin) => dispatch(login(account, password, isERPLogin)),
  onLoginErrorAnimation: () => dispatch(loginErrorAnimation()),
  onRegister: (data, isActivity) => dispatch(register(data, isActivity)),
  onRegisterErrorAnimation: () => dispatch(registerErrorAnimation()),
  onCustomRegister: data => dispatch(customRegister(data)),
  onSendVerify: mobile => dispatch(sendVerify(mobile)),
  onReset: data => dispatch(reset(data)),
  onResetErrorAnimation: () => dispatch(resetErrorAnimation()),
  onSendResetVerify: mobile => dispatch(sendResetVerify(mobile)),
  onCloseTransitionsPage: data => dispatch(onTransitionsPage(data)),
  onErpTransLogout: () => dispatch(erpTransLogout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginDialog);
