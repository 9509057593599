/**
 * FeaturePath: 會員系統-其它-工具
 * Accountable: AlexCH Cheng, Landy Chu
 */

import auth from 'react/utils/auth';
import { authEasyFetch } from './endpoint';

const ROOT_NAME = 'handouts';

const handoutsRequest = {
  async fetchHandouts(serial) {
    try {
      const result = await authEasyFetch(`/api/${ROOT_NAME}`, 'GET', `courseSerial=${serial}`);
      return await Promise.resolve({ success: true, data: result });
    } catch (error) {
      return auth.errorProcessing(error);
    }
  },
};

export default handoutsRequest;
