/**
 * FeaturePath: 會員系統-其它-工具
 * Accountable: AlexCH Cheng, Landy Chu
 */
const CMS_PATH_ROOT = {
  development: '',
  production: '',
};

export default {
  root: `${CMS_PATH_ROOT[process.env.NODE_ENV]}/`,
  courseRoot: `${CMS_PATH_ROOT[process.env.NODE_ENV]}/course`,
  informationRoot: `${CMS_PATH_ROOT[process.env.NODE_ENV]}/information`,
  licenseRoot: `${CMS_PATH_ROOT[process.env.NODE_ENV]}/license`,
  myCoursesRoot: `${CMS_PATH_ROOT[process.env.NODE_ENV]}/mycourses`,
  searchRoot: `${CMS_PATH_ROOT[process.env.NODE_ENV]}/search`,
  checkoutRoot: `${CMS_PATH_ROOT[process.env.NODE_ENV]}/checkout`,
  couponRoot: `${CMS_PATH_ROOT[process.env.NODE_ENV]}/coupon`,
  packageRoot: `${CMS_PATH_ROOT[process.env.NODE_ENV]}/package`,
  calendar: `${CMS_PATH_ROOT[process.env.NODE_ENV]}/calendar`,
  learningHistory: `${CMS_PATH_ROOT[process.env.NODE_ENV]}/learningHistory`,
  myPrizesRoot: `${CMS_PATH_ROOT[process.env.NODE_ENV]}/myprizes`,
  myProductsRoot: `${CMS_PATH_ROOT[process.env.NODE_ENV]}/myproducts`,
  faq: `${CMS_PATH_ROOT[process.env.NODE_ENV]}/faq`,
  transitions: `${CMS_PATH_ROOT[process.env.NODE_ENV]}/transitions`,
  privacyPolicy: `${CMS_PATH_ROOT[process.env.NODE_ENV]}/privacy-policy`,
  serviceTerms: `${CMS_PATH_ROOT[process.env.NODE_ENV]}/service-terms`,
  orderCompleted: `${CMS_PATH_ROOT[process.env.NODE_ENV]}/order-completed`,
  orderDetail: `${CMS_PATH_ROOT[process.env.NODE_ENV]}/orderDetail`,
  orderRecord: `${CMS_PATH_ROOT[process.env.NODE_ENV]}/orderRecord`,
  licenseEdit: `${CMS_PATH_ROOT[process.env.NODE_ENV]}/license/edit`,
  cartRoot: `${CMS_PATH_ROOT[process.env.NODE_ENV]}/cart`,
  cartList: `${CMS_PATH_ROOT[process.env.NODE_ENV]}/cart/cartList`,
  wishList: `${CMS_PATH_ROOT[process.env.NODE_ENV]}/cart/wishList`,
  orderList: `${CMS_PATH_ROOT[process.env.NODE_ENV]}/cart/orderList`,
  activities: `${CMS_PATH_ROOT[process.env.NODE_ENV]}/activities`,
};
