/**
 * FeaturePath: 學員管理-課程管理-課程瀏覽-課程搜尋
 * Accountable: Tom Jhuang, Landy Chu
 */

import React, { useState } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';

import { forwardTo } from 'react/actions/utils';

import searchImg from 'images/icon/search.svg';

import styles from './styles.css';

const SearchInput = ({
  className,
  onSearch,
  ...others
}) => {
  const [search, setSearch] = useState('');

  const handleChange = evt => {
    setSearch(evt.target.value);
  };

  const handleSearch = () => {
    if (search.trim() !== '') {
      onSearch(search);
      setSearch('');
    }
  };

  const handleKeyPress = evt => {
    if (evt.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <div {...others} className={classnames(styles.searchInput, className)}>
      <input
        type="text"
        name="search"
        placeholder="課程搜尋..."
        value={search}
        onChange={handleChange}
        onKeyPress={handleKeyPress}
      />
      <div
        className={styles.imgWrap}
        role="presentation"
        onClick={handleSearch}
      >
        <img src={searchImg} title="搜尋" alt="" />
      </div>
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    onSearch: search => dispatch(forwardTo(`/search?keyword=${search}`)),
  };
}

export default connect(null, mapDispatchToProps)(SearchInput);
