/**
 * FeaturePath: 會員系統-其它-工具
 * Accountable: AlexCH Cheng, Landy Chu
 */

import React, { Component } from 'react';
import classnames from 'classnames';

class FormOuter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
    };
  }

  render() {
    const {
      className,
      children,
      onSubmit = () => {},
      error = false,
    } = this.props;

    return (
      <form
        className={classnames('form', className, {
          valid: this.state.value && !error,
          error,
        })}
        onSubmit={onSubmit}
      >
        <div className="form__note-wrapper">
          <p className="form__note form__note--not-update">
            There is no new update.
          </p>
          <p className="form__note-error form__note--field-missing">Field missing</p>
          <p className="form__note-error form__note--name-exist">
            The same name has existed.
          </p>
          <p className="form__note-error form__note--failed">
            Something went wrong, please try again!
          </p>
        </div>
        {children}
      </form>
    );
  }
}

export default FormOuter;
