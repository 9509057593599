/**
 * FeaturePath: 會員系統-其它-工具
 * Accountable: Landy Chu, AlexCH Cheng
 */

import React from 'react';
import classnames from 'classnames';

import styles from './styles.css';

// Component's default color: 'dark'
const ModalButton = ({
  className,
  children,
  onClick,
  disabled = false,
  light = false,
  ...props
}) => (
  <button
    className={classnames(styles.button, className, {
      [styles.light]: light,
      [styles.disabled]: disabled,
    })}
    onClick={e => {
      if (!disabled) {
        e.preventDefault();
        onClick(e);
      }
    }}
    {...props}
  >
    {children}
  </button>
);

export default ModalButton;
