/**
 * FeaturePath: 會員系統-其它-工具
 * Accountable: Landy Chu, AlexCH Cheng
 */

import React from 'react';
import LogoLoadingImg from 'images/logo_loading.png';

import styles from './styles.css';

const LoadingDimmer = () => (
  <div className="loading-wrapper">
    <img
      className={styles.loadingDimmer}
      src={LogoLoadingImg}
      alt=""
    />
  </div>
);

export default LoadingDimmer;
