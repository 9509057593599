/**
 * FeaturePath: 不需處理
 * Accountable: Tom Jhuang, Landy Chu
 */

import React from 'react';
import { Provider } from 'react-redux';
import { Router, Route } from 'react-router-dom';
import routePath from 'react/constants/path';

import App from './components';
import Home from './components/Pages/Home';

// Import the components used as pages
import { CoursePage } from './routes/course';
import { InformationPage } from './routes/information';
import { LicensePage } from './routes/license';
import { MyCoursesPage } from './routes/mycourses';
import { CourseListPage } from './routes/courseList';
import { CourseBuyPage } from './routes/courseBuy';
import { SearchPage } from './routes/search';
import { CartPage } from './routes/cart';
import { CheckoutPage } from './routes/checkout';
import { CouponPage } from './routes/coupon';
import { PackagePage } from './routes/package';
import { CalendarPage } from './routes/calendar';
import { TransitionsPage } from './routes/transitions';
import { LearningHistoryPage } from './routes/learningHistory';
import { MyPrizesPage } from './routes/myprizes';
import { MyProductsPage } from './routes/myproducts';
import { FAQPage } from './routes/faq';
import { PrivacyPolicyPage } from './routes/privacyPolicy';
import { ServiceTermsPage } from './routes/serviceTerms';
import { OrderDetailPage } from './routes/orderDetail';
import { OrderRecordListPage } from './routes/orderRecordList';
import { LicenseEditPage } from './routes/licenseEdit';

const Routes = ({ store, history }) => (
  <Provider store={store}>
    <Router history={history}>
      <App>
        <Route
          exact
          path={routePath.root}
          component={props => <Home {...props} />}
        />
        <Route
          exact
          path={`${routePath.courseRoot}/:id`}
          component={props => <CoursePage {...props} />}
        />
        <Route
          exact
          path={routePath.informationRoot}
          component={props => <InformationPage {...props} />}
        />
        <Route
          exact
          path={routePath.licenseRoot}
          component={props => <LicensePage {...props} />}
        />
        <Route
          exact
          path={routePath.myCoursesRoot}
          component={props => <MyCoursesPage {...props} />}
        />
        <Route
          exact
          path={routePath.courseRoot}
          component={props => <CourseListPage {...props} />}
        />
        <Route
          exact
          path={`${routePath.courseRoot}/:id/buy`}
          component={props => <CourseBuyPage {...props} />}
        />
        <Route
          exact
          path={routePath.searchRoot}
          component={props => <SearchPage {...props} />}
        />
        <Route
          exact
          path={`${routePath.cartRoot}/:tab`}
          component={props => <CartPage {...props} />}
        />
        <Route
          exact
          path={routePath.checkoutRoot}
          component={props => <CheckoutPage {...props} />}
        />
        <Route
          exact
          path={routePath.couponRoot}
          component={props => <CouponPage {...props} />}
        />
        <Route
          exact
          path={`${routePath.packageRoot}/:id`}
          component={props => <PackagePage {...props} />}
        />
        <Route
          exact
          path={`${routePath.calendar}`}
          component={props => <CalendarPage {...props} />}
        />
        <Route
          exact
          path={`${routePath.learningHistory}`}
          component={props => <LearningHistoryPage {...props} />}
        />
        <Route
          exact
          path={routePath.myPrizesRoot}
          component={props => <MyPrizesPage {...props} />}
        />
        <Route
          exact
          path={routePath.myProductsRoot}
          component={props => <MyProductsPage {...props} />}
        />
        <Route
          exact
          path={`${routePath.faq}`}
          component={props => <FAQPage {...props} />}
        />
        <Route
          exact
          path={`${routePath.transitions}/:id`}
          component={props => <TransitionsPage {...props} />}
        />
        <Route
          exact
          path={`${routePath.privacyPolicy}`}
          component={props => <PrivacyPolicyPage {...props} />}
        />
        <Route
          exact
          path={`${routePath.serviceTerms}`}
          component={props => <ServiceTermsPage {...props} />}
        />
        <Route
          exact
          path={`${routePath.orderCompleted}/:id`}
          component={props => <OrderDetailPage {...props} />}
        />
        <Route
          exact
          path={`${routePath.orderDetail}/:id`}
          component={props => <OrderDetailPage {...props} />}
        />
        <Route
          exact
          path={`${routePath.orderRecord}`}
          component={props => <OrderRecordListPage {...props} />}
        />
        <Route
          exact
          path={`${routePath.licenseEdit}/:id`}
          component={props => <LicenseEditPage {...props} />}
        />
        {/* NOTE:活動頁面，活動時間到要關閉 */}
        {/* <Route
          exact
          path={`${routePath.activities}`}
          component={props => <ActivitiesPage {...props} />}
        /> */}
      </App>
    </Router>
  </Provider>
);

export default Routes;
