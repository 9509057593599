/**
 * FeaturePath: 學員管理-履歷管理-個人證照-新增證照
 * FeaturePath: 學員管理-履歷管理-個人證照-上傳證照圖片
 * FeaturePath: 學員管理-履歷管理-學分管理-申請積分
 * Accountable: Landy Chu, AlexCH Cheng
 */
import objectAssign from 'object.assign';
import {
  SET_TARGET,
  SET_LIST,
  SET_LICENSE_UNIT,
  SET_LICENSE_IDCLASS,
  SET_IMAGE_LINK,
  SET_LICENSE_CONFIG,
} from '../constants/license';

import imageRequest from '../request/image';
import licenseRequest from '../request/license';

// Object.assign is not yet fully supported in all browsers, so we fallback to
// a polyfill
const assign = Object.assign || objectAssign;

export function setLicenseList(type, licenses) {
  return { type, licenses };
}

export function setTargetLicense(license) {
  return { type: SET_TARGET, license };
}

export function setLicenseUnit(licenseUnit) {
  return { type: SET_LICENSE_UNIT, licenseUnit };
}

export function setLicenseIdClass(licenseIdClass) {
  return { type: SET_LICENSE_IDCLASS, licenseIdClass };
}

export function setLicenseConfigList(data) {
  return { type: SET_LICENSE_CONFIG, data };
}

export function fetchLicenseList(query) {
  return async dispatch => {
    const { success, list } = await licenseRequest.fetchLicenseList(query);
    if (success) {
      let newList = [];
      if (Array.isArray(list)) {
        newList = list.map(item => {
          if (item.copy) {
            return { ...item };
          }

          return {
            ...item,
            copy: Array.isArray(item.photo) ? item.photo[0] : item.photo,
          };
        });
      }
      dispatch(setLicenseList(SET_LIST, newList));
    }
  };
}

export function createLicense(license) {
  return async dispatch => {
    const { success } = await licenseRequest.createLicense(license);
    if (success) {
      dispatch(fetchLicenseList());
      return Promise.resolve({ success });
    }

    return false;
  };
}

// Note: 原本的證照上傳，視後續是否需要移除
export function uploadImage(file) {
  return async (dispatch, getState) => {
    const { success, url } = await imageRequest.uploadImage(file);
    const { license: { target } } = getState();
    const updateData = assign(target, {
      copy: url,
    });
    if (success) {
      dispatch(setTargetLicense(updateData));
      dispatch({ type: SET_IMAGE_LINK, link: url });
    }

    return Promise.resolve({ success });
  };
}
// Note: 新的證照上傳
export function recognizeImage(file) {
  return async () => {
    const data = await licenseRequest.recognizeLicense(file);
    const { success, err } = data;
    if (success) {
      return data;
    }
    return err;
  };
}

export function updateLicense(license) {
  return async dispatch => {
    const updateData = {
      name: license.name,
      idclass: license.idclass,
      obtainDate: license.obtainDate,
      validStart: license.validStart,
      validEnd: license.validEnd,
      number: license.number,
      organization: license.organization,
      note: license.note,
      city: license.city,
      copy: license.copy,
      isNoLimit: !!license.isNoLimit,
    };
    const { success } = await licenseRequest.updateLicense(license._id, updateData);
    if (success) {
      dispatch(fetchLicenseList());
      return Promise.resolve({ success });
    }

    return false;
  };
}

export function fetchLicenseUnit() {
  return async dispatch => {
    const { success, list } = await licenseRequest.fetchLicenseUnit();
    if (success) {
      dispatch(setLicenseUnit(list));
    }
  };
}

export function fetchLicenseIdClass() {
  return async dispatch => {
    const { success, list } = await licenseRequest.fetchLicenseIdClass();
    if (success) {
      dispatch(setLicenseIdClass(list));
    }
  };
}

export function deleteLicense(id) {
  return async dispatch => {
    const { success } = await licenseRequest.deleteLicense(id);
    if (success) {
      dispatch(fetchLicenseList());
    }
  };
}


export function getLicenseConfig() {
  return async dispatch => {
    const { success, data } = await licenseRequest.fetchLicenseConfig();
    if (success) {
      dispatch(setLicenseConfigList(data));
    }
  };
}

