/**
 * FeaturePath: 會員系統-其它-工具
 * Accountable: AlexCH Cheng, Landy Chu
 */

const ERROR_MAP: { [key: number]: string } = {
  310104: '帳號已存在',
  302: '已購買',
  403: '已在購物車',
  412: '已超過購買期限',
  501: '查無此折價券',
  502: '折價券已逾期',
  504: '您已使用過該折價券，無法重複使用',
  506: '折價券使用期限不在時間範圍內',
  507: '折價券數量不足',
  508: '折價券已使用',
  509: '兌換數量已達上限',
};

export function getErrorMessage(errorCode: number) {
  return ERROR_MAP[errorCode] || '';
}
