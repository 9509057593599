exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".styles__modal___2n3y0{position:relative;top:50%;margin:auto;transform:translateY(-50%);width:100vw;padding:0 15px}.styles__modal___2n3y0>.styles__wrapper___1L9mh{max-width:500px;width:100%;max-height:90vh;margin:auto;padding-top:20px;background:#fff;border-radius:10px;overflow-x:hidden;overflow-y:auto;display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column}.styles__modal___2n3y0>.styles__wrapper___1L9mh>.styles__prizeText___2QGWq{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;text-align:center;font-size:24px;line-height:30px}.styles__button___Hs2QP{width:30%;min-width:200px;margin:20px auto}.styles__thankYou___eS-rW{font-size:60px;color:aqua;margin:45px 0}", ""]);

// exports
exports.locals = {
	"modal": "styles__modal___2n3y0",
	"wrapper": "styles__wrapper___1L9mh",
	"prizeText": "styles__prizeText___2QGWq",
	"button": "styles__button___Hs2QP",
	"thankYou": "styles__thankYou___eS-rW"
};