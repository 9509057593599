/**
 * FeaturePath: 學員管理-交易管理-折扣碼-新增折價券
 * FeaturePath: 學員管理-交易管理-折扣碼-使用折價券
 * FeaturePath: 學員管理-交易管理-折扣碼-查看個人折價券使用狀況
 * Accountable: Tom Jhuang, Landy Chu
 */
export const SET_COUPON_LIST = 'SET_COUPON_LIST';
export const SET_COURSE_COUPON_LIST = 'SET_COURSE_COUPON_LIST';
export const SET_ADD_COUPON_MSG = 'SET_ADD_COUPON_MSG';
export const SET_PRODUCT_COUPON_LIST = 'SET_PRODUCT_COUPON_LIST';
export const SET_COUPON_DETAIL = 'SET_COUPON_DETAIL';
