/**
 * FeaturePath: 學員管理-履歷管理-個人證照-新增證照
 * FeaturePath: 學員管理-履歷管理-個人證照-上傳證照圖片
 * FeaturePath: 學員管理-履歷管理-學分管理-申請積分
 * Accountable: AlexCH Cheng, Landy Chu
 */
export const SET_TARGET = 'SET_TARGET_LICENSE';
export const SET_LIST = 'SET_LICENSE_LIST';
export const SET_LICENSE_UNIT = 'SET_LICENSE_UNIT';
export const SET_LICENSE_IDCLASS = 'SET_LICENSE_IDCLASS';
export const SET_IMAGE_LINK = 'SET_IMAGE_LINK';
export const SET_LICENSE_CONFIG = 'SET_LICENSE_CONFIG';

/** 證照到期期限(年) */
export const LICENSE_EXPIRING_YEAR = 1;
