/**
 * FeaturePath: 會員系統-其它-工具
 * Accountable: AlexCH Cheng, Landy Chu
 */

import React, { useCallback, useState, forwardRef, useImperativeHandle, useRef } from 'react';

import { validateFunction } from 'react/utils/utils';

import Button from '../../../Button/Button';
import InputContent from '../InputContent';

const ResetPasswordForm = ({
  errorMessage,
  onGetVerifyCodeClick,
  onSubmit,
  onError,
}, ref) => {
  const [data, setData] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const inputContentRef = useRef(null);

  const {
    account,
    verifyCode,
    password,
  } = data;

  const handleChange = useCallback(newData => {
    setData(newData);
  }, []);

  const handleSubmit = useCallback(event => {
    event.preventDefault();
  }, []);

  const handleSubmitClick = useCallback(event => {
    event.preventDefault();

    setIsSubmitted(true);
    if (inputContentRef.current && inputContentRef.current.isHaveErrorMessage) {
      if (validateFunction(onError)) {
        onError();
      }
      return;
    }

    if (validateFunction(onSubmit)) {
      onSubmit({
        account,
        password,
        verifyCode,
      });
    }
  }, [data, onError, onSubmit]);

  const reset = useCallback(() => {
    setIsSubmitted(false);
    setData({});
  }, []);

  useImperativeHandle(ref, () => ({
    reset,
  }));

  return (
    <form className="resetPasswordForm" onSubmit={handleSubmit}>
      <div className="form__note-wrapper">
        <p className="form__note form__note--failed">{errorMessage}</p>
      </div>
      <InputContent
        ref={inputContentRef}
        isShowError={isSubmitted}
        data={data}
        isResetPassword
        onGetVerifyCodeClick={onGetVerifyCodeClick}
        onChange={handleChange}
      />
      <div className="ui center aligned basic segment">
        <Button style={{ width: '150px' }} onClick={handleSubmitClick}>
          重設密碼
        </Button>
      </div>
    </form>
  );
};

export default forwardRef(ResetPasswordForm);
