/**
 * FeaturePath: 會員系統-其它-常見問題
 * Accountable: Landy Chu, AlexCH Cheng
 */

import React, { lazy, Suspense } from 'react';

import LoadingDimmer from '../components/Utils/LoadingDimmer';

const FAQ = lazy(() => import('../components/Pages/FAQ'));

export const FAQPage = props => (
  <Suspense fallback={LoadingDimmer()}>
    <FAQ {...props} />
  </Suspense>
);
