/**
 * FeaturePath: 會員系統-其它-首頁
 * Accountable: AlexCH Cheng, Landy Chu
 */

import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

import routePath from 'react/constants/path';

import QuestionImg from 'images/icon/question.svg';
import calendar from 'images/icon/calendar.svg';
import bookImg from 'images/icon/book.svg';
import personImg from 'images/icon/person.svg';

import Icon from './Icon';

import styles from './styles.css';

const IconMenu = ({
  className,
  authStatus,
  count,
  onOpenLoginDialog,
}) => {
  const handleClick = useCallback(evt => {
    if (!authStatus) {
      evt.preventDefault();
      onOpenLoginDialog();
    }
  }, [onOpenLoginDialog, authStatus]);

  return (
    <nav className={classnames(styles.iconMenu, className)}>
      <ul className={styles.menu}>
        <li>
          <Link to="/faq">
            <Icon img={QuestionImg} />
          </Link>
          <p>FAQ</p>
        </li>
        <li>
          <Link to={'/Calendar'}>
            <Icon img={calendar} />
          </Link>
          <p>課程行事曆</p>
        </li>
        <li>
          <Link
            to={routePath.myCoursesRoot}
            onClick={handleClick}
          >
            <Icon img={bookImg} count={authStatus && count} />
          </Link>
          <p>已報名課程</p>
        </li>
        <li>
          <Link
            to={routePath.informationRoot}
            onClick={handleClick}
          >
            <Icon img={personImg} />
          </Link>
          <p>個人中心</p>
        </li>
      </ul>
    </nav>
  );
};

export default IconMenu;
