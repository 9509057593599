/**
 * FeaturePath: 不需處理
 * Accountable: Tom Jhuang, Landy Chu
 */

import { createStore, compose, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk-fsa';

import { ENV } from '../config';

import reducer from './reducers';

// Creates the Redux reducer with the redux-thunk middleware, which allows us
// to do asynchronous things in the actions
const middlewares = [thunkMiddleware];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const createStoreWithMiddleware = ENV === 'production' ?
  compose(applyMiddleware(...middlewares))(createStore) :
  composeEnhancers(applyMiddleware(...middlewares))(createStore);

export default function configureStore() {
  const store = createStoreWithMiddleware(reducer);

  if (module.hot) {
    module.hot.accept('./reducers', () => {
      const nextReducers = require('./reducers'); // eslint-disable-line global-require
      store.replaceReducer(nextReducers);
    });
  }
  return store;
}
