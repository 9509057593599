/**
 * FeaturePath: 會員系統-其它-工具
 * Accountable: AlexCH Cheng, Landy Chu
 */

import queryString from 'query-string-for-all';

import { authEasyFetch, uploadFetch } from './endpoint';
import auth from '../utils/auth';

const imageRequest = {
  /**
  * Upload an image
  * @param  {string} file The image file
  * @return {object} success or error message
  */
  async uploadImage(file) {
    const formData = new FormData();
    if (file) {
      formData.append('uploadImage', file);
      // Post a request
      return uploadFetch('/api/image/upload', formData)
        .then(({ url }) => Promise.resolve({ success: true, url }))
        .catch(auth.errorProcessing)
        .then(response => {
          if (!response) {
            return undefined;
          }

          if (response.success) {
            return Promise.resolve(response);
          }

          const resType = { type: 'failed' };
          return Promise.resolve({
            ...response,
            errType: resType,
          });
        });
    }
    return Promise.resolve({
      success: false,
      status: null,
    });
  },

  /**
  * Distory an image on the remoated server
  * @param  {string} url The image url
  * @return {object} success or error message
  */
  async destoryImage(url) {
    return authEasyFetch('/api/image/destoryImage', 'GET', queryString.stringify({ url }))
      .then(() => Promise.resolve({ success: true }))
      .catch(auth.errorProcessing)
      .then(response => {
        if (!response) {
          return undefined;
        }

        if (response.success) {
          return Promise.resolve(response);
        }

        const resType = { type: 'failed' };
        return Promise.resolve({
          ...response,
          errType: resType,
        });
      });
  },
};

export default imageRequest;
