/**
 * FeaturePath: 學員管理-資料管理-登入-登出
 * Accountable: AlexCH Cheng, Landy Chu
 */

import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { connect } from 'react-redux';

import * as R from 'ramda';
import moment from 'moment';

import { toggleNavi, openLoginDialog } from 'react/actions/utils';
import { logout } from 'react/actions/auth';

import bookImg from 'images/icon/book.svg';
import personImg from 'images/icon/person.svg';
import cartImg from 'images/icon/cart.svg';
import calendar from 'images/icon/calendar.svg';
import QuestionImg from 'images/icon/question.svg';

import { TELEPHONE, EMAIL } from '../../../constants/website';

import NaviIcon from './NaviIcon';

import styles from './styles.css';
import path from '../../../constants/path';

const Navigator = ({
  className,
  authStatus,
  navilist,
  cart,
  onToggle,
  onOpenLoginDialog,
  onLogout,
}) => {
  const handleLoginClick = useCallback(evt => {
    if (!authStatus) {
      evt.preventDefault();
      onOpenLoginDialog();
    }
  }, [onOpenLoginDialog, authStatus]);

  const handleLogoutClick = useCallback(evt => {
    evt.preventDefault();
    onLogout();
  }, [onLogout]);

  const handleMobileClick = useCallback(evt => {
    onToggle(false);
    if (!authStatus) {
      evt.preventDefault();
      onOpenLoginDialog();
    }
  }, [onToggle, onOpenLoginDialog, authStatus]);

  const today = moment().format('YYYY-MM-DD');

  // 今日課程
  const liveCourses = navilist.filter(study =>
    study.course != null
    && study.course.liveInfo != null
    && moment(study.course.liveInfo.startAt).format('YYYY-MM-DD') === today);

  // 已報名課程
  const registerCourses = navilist
    .filter(study => !liveCourses.find(course => course._id === study._id))
    .sort((prev, curr) => new Date(curr.createdAt).getTime() - new Date(prev.createdAt).getTime());

  return (
    <nav className={classnames(styles.nav, className)}>
      <div style={{ display: 'flex' }}>
        <ul className={styles.menu}>
          <li>
            <Link to="/faq">
              <NaviIcon title="FAQ" cart img={QuestionImg} />
            </Link>
          </li>
          <li>
            <Link to="/calendar">
              <NaviIcon img={calendar} title="課程行事曆" />
            </Link>
          </li>
          <li>
            <Link to={path.cartList}>
              <NaviIcon title="購物車" cart img={cartImg} dotShow={cart.length !== 0} />
            </Link>
          </li>
          {
            !authStatus &&
            <li>
              <div
                className={styles.login}
                onClick={handleLoginClick}
              >
                <p title="登入/註冊">登入/註冊</p>
              </div>
            </li>
          }
          {
            authStatus &&
            <>
              <li>
                {
                  navilist.length === 0 &&
                  <Link to="/mycourses">
                    <NaviIcon img={bookImg} title="已報名課程" />
                  </Link>
                }
                {
                  navilist.length > 0 &&
                  <React.Fragment>
                    <NaviIcon title="已報名課程" img={bookImg} count={navilist.length} dotShow />
                    <ul className={styles.dropdown}>
                      {
                        liveCourses.length > 0 &&
                        <React.Fragment>
                          <li><span>今日播放課程</span></li>
                          {
                            liveCourses.map(study => (
                              <li key={study.course._id}>
                                <Link to={`/course/${study.course._id}`}>
                                  <p>{study.course.name}</p>
                                </Link>
                              </li>
                            ))
                          }
                        </React.Fragment>
                      }
                      {
                        registerCourses.length > 0 &&
                        <React.Fragment>
                          <li><span>已報名課程</span></li>
                          {
                            registerCourses
                              .filter((list, index) => index <= 2)
                              .map(list => {
                                const courseId = R.pathOr(null, ['course', '_id'], list);
                                if (courseId) {
                                  return (
                                    <li key={courseId}>
                                      <Link to={`/course/${courseId}`}>
                                        <p>{list.course.name}</p>
                                        <span>
                                          {
                                            list.totalProgress === 0
                                              ? '尚未閱讀'
                                              : `已完成 ${Math.floor(list.totalProgress / list.course.duration * 100)} % 的課程`
                                          }
                                        </span>
                                      </Link>
                                    </li>
                                  );
                                }
                                return null;
                              },
                              )
                          }
                        </React.Fragment>
                      }
                      <li style={{ textAlign: 'center' }}>
                        <Link to="/mycourses">
                          <span className={styles.more__course}>
                            查看更多
                          </span>
                        </Link>
                      </li>
                    </ul>
                  </React.Fragment>
                }
              </li>
              <li>
                <NaviIcon
                  title="個人資訊"
                  img={personImg}
                  onClick={handleLoginClick}
                />
                <ul className={classnames(styles.dropdown, styles.person)}>
                  <li>
                    <Link to="/information">
                      個人資訊
                    </Link>
                  </li>
                  <li>
                    <Link to="/mycourses">
                      學習履歷
                    </Link>
                  </li>
                  <li>
                    <Link to="/license">
                      證照登錄
                    </Link>
                  </li>
                  <li>
                    <Link to="/myproducts">
                      我的商品
                    </Link>
                  </li>
                  <li>
                    <Link to="/myprizes">
                      我的獎品
                    </Link>
                  </li>
                  <li>
                    <Link to="/coupon">
                      折價券
                    </Link>
                  </li>
                  <li>
                    <a
                      className={styles.icon_button}
                      onClick={handleLogoutClick}
                    >
                      登出
                    </a>
                  </li>
                </ul>
              </li>
            </>
          }
        </ul>
      </div>
      <ul className={styles.menuMobile}>
        {
          authStatus &&
          <li>
            <Link to="/information" onClick={handleMobileClick}>
              個人資訊
            </Link>
          </li>
        }
        <li>
          <Link to="/calendar" onClick={handleMobileClick}>
            課程行事曆
          </Link>
        </li>
        {
          authStatus &&
          <>
            <li>
              <Link to="/mycourses" onClick={handleMobileClick}>
                學習履歷
              </Link>
            </li>
            <li>
              <Link to="/license" onClick={handleMobileClick}>
                證照登錄
              </Link>
            </li>
            <li>
              <Link to="/myproducts" onClick={handleMobileClick}>
                我的商品
              </Link>
            </li>
            <li>
              <Link to="/myprizes" onClick={handleMobileClick}>
                我的獎品
              </Link>
            </li>
            <li>
              <Link to="/coupon" onClick={handleMobileClick}>
                折價券
              </Link>
            </li>
          </>
        }
        <li>
          <Link to="/faq" onClick={() => { onToggle(false); }}>
            FAQ
          </Link>
        </li>
        {
          !authStatus &&
          <li>
            <a
              title="登入/註冊"
              className={styles.icon_button}
              onClick={handleMobileClick}
            >
              登入/註冊
            </a>
          </li>
        }
        {
          authStatus &&
          <li>
            <a
              className={styles.icon_button}
              onClick={handleLogoutClick}
            >
              登出
            </a>
          </li>
        }
      </ul>
      <div className={styles.contact}>
        客服中心 (週一至週五 10:00-17:30)<br />
        <span>{TELEPHONE}</span><br />
        <a href={`mailto:${EMAIL}`}>
          <span>{EMAIL}</span>
        </a>
      </div>
    </nav>
  );
};

function mapStateToProps({
  course: { navilist },
  cart: { cart },
}) {
  return {
    navilist,
    cart,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onToggle: toggle => dispatch(toggleNavi(toggle)),
    onOpenLoginDialog: () => dispatch(openLoginDialog()),
    onLogout: () => dispatch(logout()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Navigator);
