/**
 * FeaturePath: 會員系統-其它-過場導頁
 * Accountable: Landy Chu, AlexCH Cheng
 */

import React, { lazy, Suspense } from 'react';

import LoadingDimmer from '../components/Utils/LoadingDimmer';

const Transitions = lazy(() => import('../components/Pages/Transitions'));

export const TransitionsPage = props => (
  <Suspense fallback={LoadingDimmer()}>
    <Transitions {...props} />
  </Suspense>
);
