exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "", ""]);

// exports
exports.locals = {
	"popup-modal": "styles__popup-modal___w-kza",
	"popupModal": "styles__popup-modal___w-kza"
};