/**
 * FeaturePath: 會員系統-其它-條款-隱私權政策
 * FeaturePath: 會員系統-其它-條款-服務條款
 * Accountable: AlexCH Cheng, Landy Chu
 */

// ----- import npm modules -----
import objectAssign from 'object.assign';

// ----- import local modules -----
import {
  SET_PRIVACY_POLICY,
  SET_SERVICE_TERMS,
  SET_PRIVACY_POLICY_HTML,
  SET_SERVICE_TERMS_HTML,
} from '../constants/article';

// Object.assign is not yet fully supported in all browsers, so we fallback to
// a polyfill
const assign = Object.assign || objectAssign;

// The initial application state
const initialState = {
  privacyPolicyContent: '', // 隱私權政策內容
  serviceTermsContent: '', // 服務條款內容
  privacyPolicyHtml: '', // 隱私權政策 HTML
  serviceTermsHtml: '', // 服務條款 HTML
};

// Takes care of changing the application state
export function article(state = initialState, action: { type: string; [key: string]: any; }) {
  switch (action.type) {
    case SET_PRIVACY_POLICY:
      return assign({}, state, {
        privacyPolicyContent: action.content,
      });
    case SET_SERVICE_TERMS:
      return assign({}, state, {
        serviceTermsContent: action.content,
      });
    case SET_PRIVACY_POLICY_HTML:
      return assign({}, state, {
        privacyPolicyHtml: action.content,
      });
    case SET_SERVICE_TERMS_HTML:
      return assign({}, state, {
        serviceTermsHtml: action.content,
      });
    default:
      return state;
  }
}
