/**
 * FeaturePath: 會員系統-其它-條款-隱私權政策
 * FeaturePath: 會員系統-其它-條款-服務條款
 * Accountable: AlexCH Cheng, Landy Chu
 */

/**
 * 設定隱私權政策
 */
export const SET_PRIVACY_POLICY = 'SET_PRIVACY_POLICY';
/**
 * 設定服務條款
 */
export const SET_SERVICE_TERMS = 'SET_SERVICE_TERMS';
/**
 * 設定隱私權政策 HTML
 */
export const SET_PRIVACY_POLICY_HTML = 'SET_PRIVACY_POLICY_HTML';
/**
 * 設定服務條款 HTML
 */
export const SET_SERVICE_TERMS_HTML = 'SET_SERVICE_TERMS_HTML';
/**
 * 服務條款
 */
export const SERVICE_TERMS_TYPE = 1;
/**
 * 隱私權政策
 */
export const PRIVACY_POLICY_TYPE = 2;
