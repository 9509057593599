/**
 * FeaturePath: 會員系統-其它-工具
 * Accountable: AlexCH Cheng, Landy Chu
 */

import { authEasyFetch, authBodyFetch, errorProcessing } from './endpoint';

const ROOT_NAME = 'utils';

const utilsRequest = {
  async qualifications(type, sid) {
    return authEasyFetch(`/api/${ROOT_NAME}/qualifications`, 'GET', `type=${type}&sid=${sid}`)
      .then(data => Promise.resolve({ success: true, data }))
      .catch(errorProcessing);
  },
  async checkWorkCertValidity(products, scenario) {
    const productsValue = Array.isArray(products) ? products : [products];
    return authBodyFetch(`/api/${ROOT_NAME}/qualifications/checkWorkCertValidity`, 'POST', { products: productsValue, scenario })
      .then(data => Promise.resolve({ success: true, data }))
      .catch(errorProcessing);
  },
  async getPostInfo() {
    return authEasyFetch('/api/areas', 'GET')
      .then(data => Promise.resolve({ success: true, data }))
      .catch(errorProcessing);
  },
  async getUserAddress() {
    return authEasyFetch('/api/user_addresses', 'GET')
      .then(data => Promise.resolve({ success: true, data }))
      .catch(errorProcessing);
  },
  async createUsuallyAddress(addressData) {
    return authBodyFetch('/api/user_addresses', 'POST', addressData)
      .then(data => Promise.resolve({ success: true, data }))
      .catch(errorProcessing);
  },
  async updateUsuallyAddress(id, addressData) {
    return authBodyFetch(`/api/user_addresses/${id}`, 'PATCH', addressData)
      .then(data => Promise.resolve({ success: true, data }))
      .catch(errorProcessing);
  },
  async deleteUsuallyAddress(id) {
    return authBodyFetch(`/api/user_addresses/${id}`, 'DELETE')
      .then(data => Promise.resolve({ success: true, data }))
      .catch(errorProcessing);
  },
};

export default utilsRequest;
