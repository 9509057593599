/**
 * FeaturePath: 會員系統-其它-工具
 * Accountable: Landy Chu, AlexCH Cheng
 */

import objectAssign from 'object.assign';

import {
  SENDING_REQUEST,
  LOADING_REQUEST,
  OPEN_ALERT,
  CLOSE_ALERT,
  TOGGLE_NAVI,
  TOGGLE_SEARCH,
  TOGGLE_ADVANCED_SEARCH,
  LOADING_DIALOG_REQUEST,
  LOGIN_DIALOG_REQUEST,
  SET_IS_TRANSITIONS,
  REGISTER_DIALOG_REQUEST,
  SET_WORK_CERT_VALIDITY_STATUS,
  SET_USER_ADDRESS,
} from '../constants/utils';
// Object.assign is not yet fully supported in all browsers, so we fallback to
// a polyfill
const assign = Object.assign || objectAssign;

// The initial application state
const initialState = {
  alert: {
    active: false,
    type: '',
    message: '',
  },
  currentlySending: false,
  currentlyLoading: false,
  naviToggle: false,
  searchToggle: false,
  advancedSearchToggle: false,
  loadingDialog: {
    isShow: false,
    description: '',
  },
  isShowLoginDialog: false,
  transitions: {},
  isShowRegisterDialog: false,
  workCertValidityAlert: {},
  userAddress: [],
};

// Takes care of changing the application state
export function utils(state = initialState, action) {
  switch (action.type) {
    case SENDING_REQUEST:
      return assign({}, state, {
        currentlySending: action.sending,
      });
    case LOADING_REQUEST:
      return assign({}, state, {
        currentlyLoading: action.loading,
      });
    case OPEN_ALERT:
      return assign({}, state, {
        alert: {
          active: true,
          type: action.alert.type,
          message: action.alert.message,
        },
      });
    case CLOSE_ALERT:
      return assign({}, state, {
        alert: {
          active: false,
          type: state.alert.type,
          message: state.alert.message,
        },
      });
    case TOGGLE_NAVI:
      return assign({}, state, {
        naviToggle: action.naviToggle,
      });
    case TOGGLE_SEARCH:
      return assign({}, state, {
        searchToggle: action.searchToggle,
      });
    case TOGGLE_ADVANCED_SEARCH:
      return assign({}, state, {
        advancedSearchToggle: action.advancedSearchToggle,
      });
    case LOADING_DIALOG_REQUEST:
      return assign({}, state, {
        loadingDialog: action.loadingDialog,
      });
    case LOGIN_DIALOG_REQUEST:
      return assign({}, state, {
        isShowLoginDialog: action.isShow,
      });
    case SET_IS_TRANSITIONS:
      return assign({}, state, {
        transitions: action.transitions,
      });
    case REGISTER_DIALOG_REQUEST:
      return assign({}, state, {
        isShowRegisterDialog: action.isShow,
        isActivitiesRegister: action.isActivitiesRegister,
      });
    case SET_WORK_CERT_VALIDITY_STATUS:
      return assign({}, state, {
        workCertValidityAlert: action.data,
      });
    case SET_USER_ADDRESS:
      return assign({}, state, {
        userAddress: action.data,
      });
    default:
      return state;
  }
}
