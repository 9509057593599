/**
 * FeaturePath: 會員系統-購物車-購物車
 * Accountable: AlexCH Cheng, Landy Chu
 */

import auth from 'react/utils/auth';

import { easyFetch, bodyFetch, authBodyFetchRedirect, errorProcessing } from './endpoint';

const ROOT_NAME = 'carts';

function getAPIUrl() {
  return `/api/${ROOT_NAME}`;
}

const cartsRequest = {
  async addProductsToCart(list) {
    return bodyFetch(getAPIUrl(), 'POST', { items: list })
      .then(({ message, isContinued }) => Promise.resolve({ success: true, message, isContinued }))
      .catch(auth.errorProcessing);
  },
  async removeProductsFromCart(productId, courseId) {
    return bodyFetch(getAPIUrl(), 'PATCH', { productId, course: courseId })
      .then(({ msg }) => Promise.resolve({ success: true, msg }))
      .catch(auth.errorProcessing);
  },
  async getCartList() {
    return easyFetch(getAPIUrl(), 'GET')
      .then(({ list }) => Promise.resolve({ success: true, list }))
      .catch(auth.errorProcessing);
  },
  async applyCoupon(productId, couponCode) {
    return bodyFetch(`${getAPIUrl()}/apply_coupon/${productId}`, 'PATCH', { code: couponCode })
      .then(({ list }) => Promise.resolve({ success: true, list }))
      .catch(errorProcessing);
  },
  async cancelCoupon(productId) {
    return bodyFetch(`${getAPIUrl()}/cancel_coupon/${productId}`, 'PATCH')
      .then(({ list }) => Promise.resolve({ success: true, list }))
      .catch(auth.errorProcessing);
  },
  async cartCheckout(cart) {
    return authBodyFetchRedirect('/api/v2/orders', 'POST', { products: JSON.stringify(cart) })
      .then(() => Promise.resolve({ success: true }))
      .catch(errorProcessing);
  },
};

export default cartsRequest;
