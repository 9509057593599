/**
 * FeaturePath: 會員系統-其它-聊天室
 * Accountable: Landy Chu, AlexCH Cheng
 */

import objectAssign from 'object.assign';

import {
  SET_CHAT_MESSAGE_LIST,
  SET_CHAT_TOKEN,
} from 'react/constants/chat';
// Object.assign is not yet fully supported in all browsers, so we fallback to
// a polyfill
const assign = Object.assign || objectAssign;

// The initial application state
const initialState = {
  chatMessageList: {
    list: [],
    count: 0,
  },
  chatToken: '',
};

// Takes care of changing the application state
export function chat(state = initialState, action) {
  switch (action.type) {
    case SET_CHAT_MESSAGE_LIST:
      return assign({}, state, {
        chatMessageList: {
          list: action.list.data,
          count: action.list.count,
        },
      });
    case SET_CHAT_TOKEN:
      return assign({}, state, {
        chatToken: action.token,
      });
    default:
      return state;
  }
}
