exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".styles__icon-menu___5zra4{padding:30px 15px 0}.styles__icon-menu___5zra4 ul.styles__menu___3sQkj{margin:0;padding:0 10px;list-style-type:none;overflow:hidden;width:100%;padding-bottom:50px;border-bottom:3px solid #f0f0f0;display:-ms-flexbox;display:flex;-ms-flex-pack:space-evenly;justify-content:space-evenly}.styles__icon-menu___5zra4 ul.styles__menu___3sQkj>li{position:relative;float:left}.styles__icon-menu___5zra4 ul.styles__menu___3sQkj>li p{position:absolute;right:0;left:50%;bottom:-24px;transform:translateX(-50%);margin:auto;text-align:center;font-size:12px;width:110%;font-weight:700}", ""]);

// exports
exports.locals = {
	"icon-menu": "styles__icon-menu___5zra4",
	"iconMenu": "styles__icon-menu___5zra4",
	"menu": "styles__menu___3sQkj"
};