/**
 * FeaturePath: 會員系統-其它-工具
 * Accountable: AlexCH Cheng, Landy Chu
 */

/* eslint-disable max-len */
import React, { useCallback, useState, forwardRef, useImperativeHandle, useRef } from 'react';

import { validateFunction } from 'react/utils/utils';

import Button from '../../../Button/Button';
import InputContent from '../InputContent';

import { removeChineseNameSpaces } from '../hooks/useFormValidation';

import styles from './styles.css';

const RegisterForm = ({
  errorMessage,
  isTransferRegisterNew,
  onGetVerifyCodeClick,
  onSubmit,
  onError,
}, ref) => {
  const [data, setData] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const inputContentRef = useRef(null);

  const {
    account,
    verifyCode,
    name,
    email,
    password,
  } = data;

  const handleChange = useCallback(newData => {
    setData(newData);
  }, []);

  const handleSubmit = useCallback(evt => {
    evt.preventDefault();
  }, []);

  const handleSubmitClick = useCallback(evt => {
    evt.preventDefault();

    setIsSubmitted(true);
    if (inputContentRef.current && inputContentRef.current.isHaveErrorMessage) {
      if (validateFunction(onError)) {
        onError();
      }
      return;
    }

    if (validateFunction(onSubmit)) {
      onSubmit({
        account,
        verifyCode,
        name: removeChineseNameSpaces(name),
        email,
        password,
        mobile: account,
      });
    }
  }, [data, onError, onSubmit]);

  const reset = useCallback(() => {
    setIsSubmitted(false);
    setData({});
  }, []);

  useImperativeHandle(ref, () => ({
    reset,
  }));

  return (
    <form className="registerForm" onSubmit={handleSubmit}>
      <div className="form__note-wrapper">
        <p className="form__note form__note--failed">{errorMessage}</p>
      </div>
      <InputContent
        ref={inputContentRef}
        isShowError={isSubmitted}
        data={data}
        isRegister
        onGetVerifyCodeClick={onGetVerifyCodeClick}
        onChange={handleChange}
      />
      <div className="ui center aligned basic segment">
        {
          !isTransferRegisterNew &&
          <Button
            className={styles.button}
            onClick={handleSubmitClick}
          >
            註冊
          </Button>
        }
        {
          isTransferRegisterNew &&
          <Button
            className={styles.button}
            onClick={handleSubmitClick}
          >
            註冊新會員
          </Button>
        }
      </div>
    </form>
  );
};

export default forwardRef(RegisterForm);
