exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".styles__dialog___1kkhX{position:relative;width:auto;height:auto;max-width:800px;max-height:500px;top:30%;bottom:0;left:0;right:0;margin:auto;border:1px solid #eee;border-radius:10px;box-shadow:0 0 5px 0 rgba(0,0,0,.07);background-color:#fff;padding:1em 2em}.styles__dialog___1kkhX>.styles__title___1c3-2{font-size:28px;font-weight:700;color:#3caa8c;margin:12px 5px 24px;text-align:center}.styles__dialog___1kkhX>.styles__body___7m4Lp{font-size:16px}.styles__dialog___1kkhX>.styles__action___2B_Rz{left:0;right:0;margin:24px auto 0;display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center}.styles__dialog___1kkhX>.styles__action___2B_Rz>button{width:150px;margin:5px 0}@media (max-width:719px){.styles__dialog___1kkhX{max-width:90vw}}", ""]);

// exports
exports.locals = {
	"dialog": "styles__dialog___1kkhX",
	"title": "styles__title___1c3-2",
	"body": "styles__body___7m4Lp",
	"action": "styles__action___2B_Rz"
};