exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".styles__button___24X9y{width:160px}.styles__second-button___faey8{width:160px;margin:0 8px;background-color:#6c757d}.styles__second-button___faey8:hover{background-color:#5a6268}@media (max-width:719px){.styles__button___24X9y,.styles__second-button___faey8{width:100px;margin:0 2px}}", ""]);

// exports
exports.locals = {
	"button": "styles__button___24X9y",
	"second-button": "styles__second-button___faey8",
	"secondButton": "styles__second-button___faey8"
};