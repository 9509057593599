/**
 * FeaturePath: 不需處理
 * Accountable: Tom Jhuang, AlexCH Cheng
 */

import React from 'react';
import classnames from 'classnames';

import styles from './styles.css';

const NaviIcon = ({
  cart,
  img,
  dotShow,
  title,
  onClick,
  className,
}) => (
  <div
    className={classnames(styles.naviIcon, className)}
    title={title}
    onClick={onClick}
  >
    <img className={classnames({ [styles.cart]: cart })} src={img} alt="icon" />
    <div className={classnames(styles.dot, {
      [styles.show]: dotShow,
    })}
    />
  </div>
);

export default NaviIcon;
