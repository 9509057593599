/**
 * FeaturePath: 會員系統-課程-課程清單
 * Accountable: Tom Jhuang, AlexCH Cheng
 */

import React, { lazy, Suspense } from 'react';

import LoadingDimmer from '../components/Utils/LoadingDimmer';

const Package = lazy(() => import('../components/Pages/Package'));

export const PackagePage = props => (
  <Suspense fallback={LoadingDimmer()}>
    <Package {...props} />
  </Suspense>
);
