/**
 * FeaturePath: 學員管理-資料管理-註冊-O2O登入
 * FeaturePath: 學員管理-資料管理-註冊-O2O註冊
 * Accountable: Landy Chu, AlexCH Cheng
 */
export const SET_AUTH_STATUS = 'SET_AUTH_STATUS';
export const SET_AUTH_WISHLIST = 'SET_AUTH_WISHLIST';
export const SET_BANNERS = 'SET_BANNERS';
export const SET_PWD_ERROR = 'SET_PWD_ERROR';
export const SET_LOGIN_ERROR = 'SET_LOGIN_ERROR';
export const SET_REGISTER_ERROR = 'SET_REGISTER_ERROR';
export const SET_RESET_PASSWORD_ERROR = 'SET_RESET_PASSWORD_ERROR';
export const SET_IS_GET_AUTH_STATUS = 'SET_IS_GET_AUTH_STATUS';

export const LOGIN_FORM_SELECTOR = '.loginForm';
export const REGISTER_FORM_SELECTOR = '.registerForm';
export const RESET_PASSWORD_FORM_SELECTOR = '.resetPasswordForm';
