/**
 * FeaturePath: 不需處理
 * Accountable: Tom Jhuang, AlexCH Cheng
 */

// ----- import local modules -----
import React, { Suspense, useEffect, useState, useCallback, useMemo } from 'react';
import * as R from 'ramda';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useUrlSearchParams } from 'use-url-search-params';

// ----- import local modules -----
import routePath from 'react/constants/path';
import { META_TITLE } from 'react/constants/website';
import { openRegisterDialog, forwardTo } from 'react/actions/utils';
import { getDrawActivities, getUnDrawList, closeUnDrawList } from 'react/actions/activities';
import { getAuthStatus } from 'react/actions/auth';
import Header from 'react/components/Header';
import Footer from 'react/components/Footer';
import LoadingDimmer from 'react/components/Utils/LoadingDimmer';
import AlertModal from 'react/components/Utils/Modal/AlertModal';
import AdvancedSearchDialog from 'react/components/Utils/Dialog/AdvancedSearchDialog';
import LoadingDialog from 'react/components/Utils/Dialog/LoadingDialog';
import LoginDialog from 'react/components/Utils/Dialog/LoginDialog';
import faviconImg from 'images/favicon.svg';

import { getPopups } from '../actions/home';
import LuckyDraw from '../components/Activities/LuckyDraw';
import UnDrawListComponent from '../components/Activities/LuckyDraw/components/UnDrawListComponent';
import PopupModal from '../components/Utils/Modal/PopupModal';
import InformDialog from '../components/Utils/Dialog/InformDialog';
import popupUtil from '../utils/popup';

import { ENV } from '../../config';

import packageJson from '../../../package.json';

// ----- constants -----
/**
 * 需要登入的頁面列表
 */
const AUTH_PAGES = [
  routePath.informationRoot,
  routePath.myCoursesRoot,
  routePath.licenseRoot,
  routePath.couponRoot,
  routePath.checkoutRoot,
];

/**
 * 不需要顯示導覽列的頁面列表
 */
const NO_NAVI_PAGES = [routePath.login];

/**
 * 公告訊息類型：文字
 */
const CONTENT_TYPE_TEXT = 'text';

// ----- App component -----
const App = ({
  location: { pathname },
  courseName, // 課程名稱
  authStatus, // 登入狀態
  loading, // 是否正在載入
  children, // 子元件
  onGetAuthStatus, // 取得登入狀態
  onForwardTo, // 跳轉頁面
  fetchDrawActivities, // 取得抽獎活動資訊
  fetchUnDrawList, // 取得未抽獎名單
  undrawList, // 未抽獎名單
  onCloseUnDrawList, // 關閉未抽獎名單
  isClosedUndrawList, // 是否關閉未抽獎名單
  onGetPopups, // 取得公告訊息資訊
  popups, // 公告訊息
}) => {
  const [isGetAuthStatus, setIsGetAuthStatus] = useState(false); // 是否已取得登入狀態過
  const [metaTitle, setMetaTitle] = useState(META_TITLE); // 網頁標題
  const windowsLocalStorage = window.localStorage; // local storage
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const [params, setParams] = useUrlSearchParams();
  const {
    activityCode, // 活動代碼
    account, // 帳號
    name, // 姓名
    mobile, // 手機
  } = params;
  const bannerCode = R.pathOr('', ['utm_content'], params); // 橫幅代碼
  const [drawRecordId, setDrawRecordId] = useState(''); // 抽獎紀錄代碼
  const [isShowLuckyDraw, setIsShowLuckyDraw] = useState(false); // 是否顯示抽獎視窗
  const [isShowInfoModal, setIsShowInfoModal] = useState(false); // 是否顯示公告訊息視窗

  if (activityCode) {
    // 如果有活動代碼，則將活動資料存入 local storage
    windowsLocalStorage.setItem('LUCKY_DRAW', JSON.stringify(params));
  }

  /**
   * 公告訊息
   */
  const announcementMessage = useMemo(() => {
    if (Array.isArray(popups) && popups.length) {
      return popups.find(item => item.contentType === CONTENT_TYPE_TEXT);
    }

    return null;
  }, [popups]);

  /**
   * 處理按下確認按鈕事件
   */
  const handleApprove = useCallback(() => {
    // 關閉公告訊息視窗
    setIsShowInfoModal(false);
    // 將公告訊息 ID 加入已讀清單
    popupUtil.addReadAnnouncement(announcementMessage?._id);
  }, [announcementMessage]);

  useEffect(() => {
    if (ENV !== 'production') {
      // 開發環境時，將版本號加入網頁標題
      setMetaTitle(`v${packageJson.version}｜${META_TITLE}`);
    }
    if (activityCode) {
      // 如果有活動代碼，則取得抽獎活動資訊
      fetchDrawActivities(activityCode);
    }

    // 取得登入狀態
    onGetAuthStatus()
      .then(() => {
        setIsGetAuthStatus(true);
      });
  }, []);

  useEffect(() => {
    if (isGetAuthStatus) {
      if (!isClosedUndrawList) {
        // 如果未抽獎名單視窗未關閉，則取得未抽獎名單
        fetchUnDrawList();
      }

      if (!authStatus && AUTH_PAGES.includes(pathname)) {
        // 如果使用者未登入，而且目前頁面需要登入，則導向登入頁
        onForwardTo(routePath.root);
      }
    }
  }, [isGetAuthStatus, authStatus, pathname]);

  useEffect(() => {
    // 當使用者登入，則取得公告訊息
    if (authStatus) {
      onGetPopups();
    }
  }, [authStatus]);

  useEffect(() => {
    // 當使用者登入，而且有公告訊息，且尚末讀取，則顯示公告訊息
    if (authStatus && announcementMessage && !popupUtil.hasReadAnnouncement(announcementMessage?._id)) {
      setIsShowInfoModal(true);
    }
  }, [authStatus, announcementMessage]);

  useEffect(() => {
    if (isGetAuthStatus && !authStatus) {
      // 當使用者登出，清空已讀公告訊息清單
      popupUtil.clearReadAnnouncement();
    }
  }, [isGetAuthStatus, authStatus]);

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {
            pathname.match(/^\/course\/.*/i)
              ? `${courseName}｜${metaTitle}`
              : `${metaTitle}`
          }
        </title>
        {
          ENV !== 'production' && <meta name="robots" content="noindex" />
        }
        {
          ENV !== 'production' && <meta name="googlebot" content="noindex" />
        }
        <link rel="Shortcut Icon" type="image/x-icon" href={faviconImg} />
      </Helmet>
      {
        !NO_NAVI_PAGES.some(p => p === pathname) && <Header />
      }
      <div
        className={`page${NO_NAVI_PAGES.some(p => p === pathname) ? ' nonavi' : ''}`}
        style={{ minHeight: 'calc(100vh - 331px)' }}
      >
        {
          loading && <LoadingDimmer />
        }
        {children}
      </div>
      <AdvancedSearchDialog />
      <LoginDialog
        activityCode={activityCode}
        sourceAccount={account}
        bannerCode={bannerCode}
        name={name}
        mobile={mobile}
        setDrawRecordId={setDrawRecordId}
        setIsShowLuckyDraw={setIsShowLuckyDraw}
      />
      <LoadingDialog />
      <UnDrawListComponent
        list={undrawList}
        onClose={onCloseUnDrawList}
        isClosedUndrawList={isClosedUndrawList}
        setIsShowLuckyDraw={setIsShowLuckyDraw}
        setDrawRecordId={setDrawRecordId}
      />
      <LuckyDraw
        isShowModal={isShowLuckyDraw}
        activityCode={activityCode}
        drawRecordId={drawRecordId}
        onClose={() => { setIsShowLuckyDraw(false); }}
      />
      <AlertModal />
      <PopupModal
        modalVisible={isShowInfoModal}
      >
        <InformDialog
          header={announcementMessage?.title}
          description={announcementMessage?.content}
          approve={handleApprove}
        />
      </PopupModal>
      <Footer />
    </Suspense>
  );
};

// Which props do we want to inject, given the global state?
function mapStateToProps({
  utils: { currentlyLoading },
  course: { target },
  auth: { status: authStatus },
  activities: {
    drawActivities,
    undrawList,
    isClosedUndrawList,
  },
  home: {
    popups,
  },
}) {
  return {
    loading: currentlyLoading,
    courseName: target.name,
    authStatus,
    drawActivities,
    undrawList,
    isClosedUndrawList,
    popups,
  };
}

function mapDispatchToProps(dispatch) {
  return ({
    onGetAuthStatus: () => dispatch(getAuthStatus()),
    onForwardTo: path => dispatch(forwardTo(path)),
    onOpenRegisterDialog: () => dispatch(openRegisterDialog()),
    fetchDrawActivities: id => dispatch(getDrawActivities(id)),
    fetchUnDrawList: () => dispatch(getUnDrawList()),
    onCloseUnDrawList: () => dispatch(closeUnDrawList()),
    onGetPopups: () => dispatch(getPopups()),
  });
}

// Wrap the component to inject dispatch and state into it
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
