/**
 * FeaturePath: 會員系統-其它-工具
 * Accountable: Landy Chu, AlexCH Cheng
 */

import moment from 'moment';

const WEEK = ['日', '一', '二', '三', '四', '五', '六'];

export function dateChange(dateTime) {
  const myDateTime = moment(dateTime);
  return `${myDateTime.format('YYYY-MM-DD')}(${WEEK[myDateTime.day()]})${myDateTime.format('HH:mm')}`;
}

export function timeChange(time) {
  const tempTime = Math.trunc(time);
  let hours = 0;
  let minute = 0;
  let second = 0;
  if (tempTime >= 60 && tempTime < 3600) {
    minute = Math.floor(tempTime / 60);
    second = tempTime - (minute * 60);
  } else if (tempTime >= 3600) {
    hours = Math.floor(tempTime / 3600);
    minute = Math.floor((tempTime - (hours * 3600)) / 60);
    second = tempTime - (hours * 3600 + minute * 60);
  } else {
    second = tempTime;
  }
  const result = `${hours !== 0 ? `${hours}時` : ''}${minute !== 0 ? `${minute}分` : ''}${second !== 0 ? `${second}秒` : ''}`;
  return result;
}

export function timeChangeDigit(time) {
  const tempTime = Math.trunc(time);
  let hours = 0;
  let minute = 0;
  let second = 0;
  if (tempTime >= 60 && tempTime < 3600) {
    minute = Math.floor(tempTime / 60);
    second = tempTime - (minute * 60);
  } else if (tempTime >= 3600) {
    hours = Math.floor(tempTime / 3600);
    minute = Math.floor((tempTime - (hours * 3600)) / 60);
    second = tempTime - (hours * 3600 + minute * 60);
  } else {
    second = tempTime;
  }
  const result = `${hours !== 0 ? `${hours}:` : ''}${minute !== 0 ? `${minute}:` : ''}${second !== 0 ? `${second}` : ''}`;
  return result;
}

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function isOverDate(purchasedAt, day = 0) {
  if (!purchasedAt) return false;
  return moment().add(day, 'days').isAfter(moment(purchasedAt));
}
