/**
 * FeaturePath: 會員系統-其它-抽獎
 * Accountable: AlexCH Cheng, Landy Chu
 */

import React from 'react';
import * as R from 'ramda';
import Lottie from 'lottie-react';

import PopupModal from 'react/components/Utils/Modal/PopupModal';
import Button from '../../../../Utils/Button/Button';

import giftAnimation from '../../../../../../images/animations/gift.json';
import { luckyDrawPrizeType } from '../../../../../constants/activities';

import styles from './styles.css';

const DrawResultComponent = ({
  prize,
  onClickConfirmResult,
  isShowModal,
}) => {
  const prizeType = R.pathOr(1, ['prize', 'type'], prize);
  return (
    <PopupModal className={styles.modal} modalVisible={isShowModal}>
      <div className={styles.wrapper}>
        <div className={styles.prizeText}>
          {
            prizeType === luckyDrawPrizeType.thankYou ? (
              <React.Fragment>
                <div>好可惜未中獎，下次運氣會更好 </div>
                <div className={styles.thankYou}>
                  {R.pathOr('', ['prize', 'name'], prize)}
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div>
                  恭喜您獲得:
                </div>
                <div>
                  『{R.pathOr('', ['prize', 'name'], prize)}』
                </div>
                <Lottie animationData={giftAnimation} loop />
              </React.Fragment>)
          }
        </div>

        <Button
          onClick={onClickConfirmResult}
          className={styles.button}
        >
          {prizeType === luckyDrawPrizeType.thankYou ? '點我回首頁' : '前往查看'}
        </Button>
      </div>
    </PopupModal>
  );
};


export default DrawResultComponent;
