/**
 * FeaturePath: 不需處理
 * Accountable: Tom Jhuang, Landy Chu
 */

import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'whatwg-fetch';

import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

import history from 'react/utils/history';

// Import the CSS file, which webpack transfers to the build folder
import 'react-dates/lib/css/_datepicker.css';
import 'styles/semantic/semantic.min.css';
import 'styles/main.css';

import configureStore from './store';
import Routes from './Routes.js';

const store = configureStore({});

// These are the pages you can go to,
// which are all wrapped in the AdminDashboard component, which contains the navigation etc
const container = document.getElementById('app');
const root = createRoot(container); // createRoot(container!) if you use TypeScript


root.render(
  <StrictMode>
    <React.Fragment >
      <Routes store={store} history={history} />
    </React.Fragment>
  </StrictMode>);


const isIE = document.documentMode;
if (isIE) {
  window.alert('請改用Chrome瀏覽器繼續使用仁寶 i 學習');
}
