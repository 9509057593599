/**
 * FeaturePath: 學員管理-資料管理-註冊-O2O登入
 * FeaturePath: 學員管理-資料管理-註冊-O2O註冊
 * Accountable: Landy Chu, AlexCH Cheng
 */
import CryptoJS from 'crypto-js';

import {
  SET_AUTH_STATUS,
  SET_AUTH_WISHLIST,
  SET_BANNERS, SET_PWD_ERROR,
  SET_LOGIN_ERROR,
  SET_REGISTER_ERROR,
  SET_RESET_PASSWORD_ERROR,
  LOGIN_FORM_SELECTOR,
  REGISTER_FORM_SELECTOR,
  RESET_PASSWORD_FORM_SELECTOR,
  SET_IS_GET_AUTH_STATUS,
} from 'react/constants/auth';
import { SET_NAVI_LIST } from 'react/constants/course';
import routePath from 'react/constants/path';

import { ERP_LOGIN_SUCCESS } from 'react/constants/enum';
import { anyElementsEmpty, requestNoted } from 'react/actions/utils';
import { setCourseList } from 'react/actions/course';

import authRequest from 'react/request/auth';
import accountReq from 'react/request/account';

import { log } from '../utils/utils';
import { getErrorMessage } from '../utils/error';
import { isPhoneNumber } from '../utils/validator';

import { forwardTo } from './utils';

export function setAuthStatus(auth) {
  return { type: SET_AUTH_STATUS, auth };
}

export function setIsGetAuthStatus(value) {
  return { type: SET_IS_GET_AUTH_STATUS, value };
}

export function setAuthWishList(wishList) {
  return { type: SET_AUTH_WISHLIST, wishList };
}

export function setBanners(banner) {
  return { type: SET_BANNERS, banner };
}

export function setPwdError(message) {
  return { type: SET_PWD_ERROR, message };
}

export function setPwdErrorInitial() {
  return { type: SET_PWD_ERROR, message: '' };
}

export function setLoginError(message) {
  return { type: SET_LOGIN_ERROR, message };
}

export function setRegisterError(message) {
  return { type: SET_REGISTER_ERROR, message };
}

export function setResetPasswordError(message) {
  return { type: SET_RESET_PASSWORD_ERROR, message };
}

export function getAuthStatus() {
  return async dispatch => {
    const { success, user } = await authRequest.fetchAuthStatus();
    if (success === true) {
      const {
        _id,
        accountId,
        account,
        personalId,
        name,
        nickname,
        gender,
        birthday,
        phone,
        address,
        email,
        isLuna,
        eLearnIdentityType,
        wishList,
        studies,
        isForeign,
        passport,
        headshot,
      } = user;

      dispatch(setCourseList(SET_NAVI_LIST, studies));
      dispatch(setAuthStatus(
        {
          status: true,
          id: _id,
          accountId,
          account,
          personalId,
          name,
          nickname,
          gender,
          birthday,
          phone,
          address,
          email,
          isLuna,
          eLearnIdentityType,
          wishList,
          isForeign,
          passport,
          headshot,
        },
      ));
    }

    dispatch(setIsGetAuthStatus(true));
    return !!success;
  };
}

export function toggleWish(id) {
  return async dispatch => {
    const { success, wishList, err } = await authRequest.toggleWish({ courseId: id });
    if (success) {
      dispatch(setAuthWishList(wishList));
    } else {
      const message = err?.errorCode ? getErrorMessage(err?.errorCode) : '';
      alert((message || `加入願望清單失敗${err?.errorCode ? `(${err?.errorCode})` : ''}`));
    }

    return success;
  };
}

export function fetchBanner() {
  return async dispatch => {
    const { success, banners } = await authRequest.fetchBanner();
    if (success) {
      dispatch(setBanners(banners));
    }
  };
}

export function clickBanner(id) {
  return async () => {
    await authRequest.clickBanner(id);
  };
}

export function modifyPwd(postData) {
  return async dispatch => {
    const { success, result } = await authRequest.modifyPwd(postData);
    await dispatch(setPwdErrorInitial());
    if (success) {
      dispatch(setPwdError(null));
      dispatch(getAuthStatus());
      return;
    }

    const { code } = result || {};
    switch (code) {
      case 10010:
        dispatch(setPwdError('原密碼錯誤'));
        return;
      default:
        break;
    }

    alert('錯誤');
  };
}

export function modifyInfo(postData) {
  return async dispatch => {
    // eslint-disable-next-line no-unused-vars
    const { success } = await authRequest.modifyInfo(postData);
    if (success) {
      dispatch(getAuthStatus());
    }
  };
}

export function login(account, password, isERPLogin) {
  return async dispatch => {
    const result = { success: false, user: null, showCustomRegisterPage: false };
    if (anyElementsEmpty({ account, password })) {
      dispatch(setLoginError('有未填欄位'));
      requestNoted(LOGIN_FORM_SELECTOR, { type: 'failed' });
      return result;
    }

    const hashPassword = CryptoJS.enc.Hex.stringify(CryptoJS.SHA256(password));
    const response = isERPLogin ?
      await accountReq.erpLogin(account, hashPassword) :
      await accountReq.ecLogin(account, hashPassword);
    const {
      success,
      err,
      user,
      showCustomRegisterPage,
    } = response;
    if (!success) {
      dispatch(setLoginError(err?.messagetoUser || ''));
      requestNoted(LOGIN_FORM_SELECTOR, { type: 'failed' });
      return result;
    }

    result.user = user;
    result.showCustomRegisterPage = !!showCustomRegisterPage;
    result.success = true;
    return result;
  };
}

export function ecLogin(account, password) {
  return async () => {
    let success = true;
    let message = '';

    if (success && !account) {
      success = false;
      message = '帳號不可空白';
    }

    if (success && !password) {
      success = false;
      message = '密碼不可空白';
    }

    if (success) {
      const hashPassword = CryptoJS.enc.Hex.stringify(CryptoJS.SHA256(password));
      const response = await accountReq.ecLogin(account, hashPassword);
      success = !!response?.success;
      message = response?.err?.messagetoUser || '';
    }

    if (message) {
      log('error :>> ', message);
    }

    return { success, message };
  };
}

export function loginErrorAnimation() {
  return () => {
    requestNoted(LOGIN_FORM_SELECTOR, { type: 'error-animation' });
  };
}

export function logout() {
  return async dispatch => {
    const { success, err } = (process.env.API === 'dev') ?
      await accountReq.mockLogout() : await accountReq.logout();
    if (!success) {
      alert(err?.messagetoUser || '登出時發生未知的錯誤');
      return;
    }

    dispatch(forwardTo(routePath.root));
    window.location.reload();
  };
}

// Note: 十月後會移除
export function erpTransLogout() {
  return async dispatch => {
    const { success, err } = (process.env.API === 'dev') ?
      await accountReq.mockLogout() : await accountReq.logout();
    if (!success) {
      alert(err?.messagetoUser || '登出時發生未知的錯誤');
      return;
    }

    dispatch(forwardTo(`${routePath.transitions}/${ERP_LOGIN_SUCCESS}`));
    window.location.reload();
  };
}

export function register(data, isActivity) {
  return async dispatch => {
    const {
      account,
      verifyCode,
      name,
      email,
      password,
      mobile,
      activityCode,
      sourceAccount,
      bannerCode,
    } = data || {};

    const mustFillFields = { account, name, email, password };

    if (isPhoneNumber(account)) {
      mustFillFields.verifyCode = verifyCode;
    }

    if (anyElementsEmpty(mustFillFields)) {
      dispatch(setRegisterError('有未填欄位'));
      requestNoted(REGISTER_FORM_SELECTOR, { type: 'failed' });
      return Promise.resolve({ success: false });
    }

    const hashPassword = CryptoJS.enc.Hex.stringify(CryptoJS.SHA256(password));
    const response = isActivity ?
      await accountReq.registerActivity({
        account,
        verifyCode,
        name,
        email,
        password: hashPassword,
        mobile,
        activityCode,
        sourceAccount,
        bannerCode,
      }) :
      await accountReq.register({
        account,
        verifyCode,
        name,
        email,
        password: hashPassword,
      });
    const { success, result, err } = response;
    if (!success) {
      dispatch(setRegisterError(err?.messagetoUser || ''));
      requestNoted(REGISTER_FORM_SELECTOR, { type: 'failed' });
      return Promise.resolve({
        success,
        luckyDrawData: result,
      });
    }

    dispatch(setRegisterError(''));
    if (isActivity) {
      // 改用 ecLogin
      await accountReq.ecLogin(account, hashPassword);
    } else {
      await accountReq.login(account, hashPassword);
    }

    return Promise.resolve({
      success,
      luckyDrawData: result,
    });
  };
}

export function customRegister(data) {
  return async dispatch => {
    const {
      account,
      verifyCode,
      name,
      email,
      sourceAccount,
    } = data || {};
    if (anyElementsEmpty({ account, name, email })) {
      dispatch(setRegisterError('有未填欄位'));
      requestNoted(REGISTER_FORM_SELECTOR, { type: 'failed' });
      return Promise.resolve(false);
    }

    const response = await accountReq.customRegister({
      account,
      verifyCode,
      name,
      email,
      sourceAccount,
    });

    const { success, err } = response;
    if (!success) {
      dispatch(setRegisterError(err?.messagetoUser || ''));
      requestNoted(REGISTER_FORM_SELECTOR, { type: 'failed' });
      return Promise.resolve(false);
    }

    dispatch(setRegisterError(''));
    return Promise.resolve(true);
  };
}

export function registerErrorAnimation() {
  return () => {
    requestNoted(REGISTER_FORM_SELECTOR, { type: 'error-animation' });
  };
}

export function sendVerify(mobile) {
  return async dispatch => {
    if (!mobile) {
      dispatch(setRegisterError('有未填欄位'));
      requestNoted(REGISTER_FORM_SELECTOR, { type: 'failed' });
      return Promise.resolve(false);
    }

    let result = await accountReq.checkAccount(mobile);
    if (!result.success) {
      const { err } = result;
      dispatch(setRegisterError(getErrorMessage(err?.errorCode) || `未知的錯誤${err?.errorCode ? `(${err?.errorCode})` : ''}`));
      requestNoted(REGISTER_FORM_SELECTOR, { type: 'failed' });
      return Promise.resolve(false);
    }

    result = await accountReq.verifyCode(mobile);
    if (!result.success) {
      const { err } = result;
      dispatch(setRegisterError(err?.messagetoUser || ''));
      requestNoted(REGISTER_FORM_SELECTOR, { type: 'failed' });
      return Promise.resolve(false);
    }

    dispatch(setRegisterError(''));
    return Promise.resolve(true);
  };
}

export function reset(data) {
  return async dispatch => {
    const { account, verifyCode, password } = data || {};
    if (anyElementsEmpty({ account, verifyCode, password })) {
      dispatch(setResetPasswordError('有未填欄位'));
      requestNoted(RESET_PASSWORD_FORM_SELECTOR, { type: 'failed' });
      return Promise.resolve(false);
    }

    const hashPassword = CryptoJS.enc.Hex.stringify(CryptoJS.SHA256(password));
    const { success, err } = await accountReq.reset({
      account,
      verifyCode,
      password: hashPassword,
    });
    if (!success) {
      dispatch(setResetPasswordError(err?.messagetoUser || ''));
      requestNoted(RESET_PASSWORD_FORM_SELECTOR, { type: 'failed' });
      return Promise.resolve(false);
    }

    dispatch(setResetPasswordError(''));
    return Promise.resolve(true);
  };
}

export function resetErrorAnimation() {
  return () => {
    requestNoted(RESET_PASSWORD_FORM_SELECTOR, { type: 'error-animation' });
  };
}

export function sendResetVerify(mobile) {
  return async dispatch => {
    if (!mobile) {
      dispatch(setResetPasswordError('有未填欄位'));
      requestNoted(RESET_PASSWORD_FORM_SELECTOR, { type: 'failed' });
      return Promise.resolve(false);
    }

    let result = await accountReq.checkAccount(mobile);
    if (result.success) {
      dispatch(setResetPasswordError('帳號不存在'));
      requestNoted(RESET_PASSWORD_FORM_SELECTOR, { type: 'failed' });
      return Promise.resolve(false);
    }

    result = await accountReq.verifyCode(mobile);
    if (!result.success) {
      dispatch(setResetPasswordError(result.message));
      requestNoted(RESET_PASSWORD_FORM_SELECTOR, { type: 'failed' });
      return Promise.resolve(false);
    }

    dispatch(setResetPasswordError(''));
    return Promise.resolve(true);
  };
}

export function updateHeadshot(accountId, file) {
  return async dispatch => {
    const { success } = await accountReq.headshot(accountId, file);
    if (success) {
      await dispatch(getAuthStatus());
    }

    return Promise.resolve(success);
  };
}
