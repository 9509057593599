/**
 * FeaturePath: 不需處理
 * Accountable: Tom Jhuang, Landy Chu
 */

import React, { useCallback, useEffect } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Element } from 'react-scroll';

import { HEADER_COLOR_LEFT, HEADER_COLOR_RIGHT } from 'config.js';

import { EMAIL, LINE_URL } from 'react/constants/website';

import logoImg from 'images/logoiLearn.svg';
import lineIcon from 'images/icon/line2.svg';
import emailIcon from 'images/icon/email.svg';
import menuIcon from 'images/icon/menu.svg';
import naviBackIcon from 'images/icon/naviBack.png';
import searchIcon from 'images/icon/search.svg';
import cartIcon from 'images/icon/cart.svg';

import compalImg from 'images/icon/compal-logo.svg';
import { getCartList } from 'react/actions/cart';

import {
  toggleNavi,
  toggleSearch,
  toggleAdvancedSearch,
  openLoginDialog,
} from 'react/actions/utils';
import { getMyCourseList, fetchPurchased } from 'react/actions/course';
import { fetchLicenseList } from 'react/actions/license';

import path from '../../constants/path';

import Navigation from './Navigation';
import SearchInput from './SearchInput';
import SearchMobile from './SearchMobile';

import styles from './styles.css';

const Header = ({
  naviToggle,
  searchToggle,
  authStatus,
  cart,
  fetchCartList,
  fetchMyCourseList,
  getPurchased,
  onToggle,
  onToggleSearch,
  onToggleAdvancedSearch,
  onOpenLoginDialog,
  onFetchLicenseList, // 取得證照列表
}) => {
  const handleMenuClick = useCallback(evt => {
    evt.preventDefault();
    onToggle(!naviToggle);
  }, [onToggle, naviToggle]);

  const handleSearchClick = useCallback(evt => {
    evt.preventDefault();
    onToggleSearch(!searchToggle);
  }, [onToggleSearch, searchToggle]);

  const handleLogoClick = useCallback(() => {
    onToggle(false);
  }, [onToggle]);

  const handleLoginClick = useCallback(event => {
    event.preventDefault();
    if (onOpenLoginDialog) {
      onOpenLoginDialog();
    }
  }, [onOpenLoginDialog]);

  const handleAdvancedSearchClick = useCallback(evt => {
    evt.preventDefault();
    onToggleAdvancedSearch(true);
  }, [onToggleAdvancedSearch]);

  useEffect(() => {
    if (authStatus) {
      fetchMyCourseList();
      getPurchased();
      onFetchLicenseList();
    }
    fetchCartList(authStatus);
  }, [fetchMyCourseList, getPurchased, onFetchLicenseList, fetchCartList, authStatus]);

  return (
    <header className={styles.header}>
      <Element className={styles.scrollElement} name="top" />
      <div className={styles.headerTop}>
        <div className={styles.container}>
          <img className={styles.compalImg} src={compalImg} alt="" />
          <p>
            <a href={`mailto:${EMAIL}`}>
              <img src={emailIcon} title="聯繫Email" alt="" />
              {EMAIL}
            </a>
          </p>
          <p>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={LINE_URL}
            >
              <img src={lineIcon} title="Line 線上客服" alt="" />
              Line 線上客服
            </a>
          </p>
          <p>
            客服中心 (週一至週五 10:00-17:30)：
          </p>
        </div>
      </div>
      <div
        className={styles.headerMain}
        style={{ background: `linear-gradient(90deg, ${HEADER_COLOR_LEFT}, ${HEADER_COLOR_RIGHT})` }}
      >
        <span
          className={styles.menu}
          onClick={handleMenuClick}
        >
          <img src={naviToggle ? naviBackIcon : menuIcon} alt="" />
        </span>
        <span
          className={styles.searchIcon}
          onClick={handleSearchClick}
        >
          <img src={searchIcon} alt="" />
        </span>
        <div className={styles.mainRight}>
          <span className={styles.cartIcon}>
            {
              cart.length > 0 && <span />
            }
            <Link to={path.cartList}>
              <img style={{ width: '26px' }} src={cartIcon} alt="" />
            </Link>
          </span>
          {
            !authStatus &&
            <span
              onClick={handleLoginClick}
              className={styles.login}
              title="登入/註冊"
            >
              登入/註冊
            </span>
          }
        </div>
        <div className={styles.container}>
          <Link
            className={styles.linkpc}
            to="/"
          >
            <img className={styles.logo} src={logoImg} alt="logo" />
          </Link>
          <Link
            className={styles.linkmobile}
            to="/"
            onClick={handleLogoClick}
          >
            <img className={styles.logo} src={logoImg} alt="logo" />
          </Link>
          <Navigation
            className={classnames(styles.navi, { [styles.show]: naviToggle })}
            authStatus={authStatus}
          />
          <SearchInput
            style={{ right: '360px' }}
            className={styles.search}
          />
          <div
            style={{ right: '260px' }}
            className={styles.advancedSearch}
            onClick={handleAdvancedSearchClick}
          >
            <p className={styles.title} title="進階搜尋">進階搜尋</p>
          </div>
          <SearchMobile
            className={classnames(styles.searchMobile, { [styles.show]: searchToggle })}
          />
        </div>
      </div>
    </header>
  );
};

const mapStateToProps = ({
  utils: { naviToggle, searchToggle },
  auth: { status },
  cart: { cart },
}) => ({
  naviToggle,
  searchToggle,
  authStatus: status,
  cart,
});

const mapDispatchToProps = dispatch => ({
  onToggle: toggle => {
    dispatch(toggleNavi(toggle));
    dispatch(toggleSearch(false));
  },
  onToggleSearch: toggle => {
    dispatch(toggleSearch(toggle));
    dispatch(toggleNavi(false));
  },
  onToggleAdvancedSearch: toggle => dispatch(toggleAdvancedSearch(toggle)),
  fetchCartList: authStatus => dispatch(getCartList(authStatus)),
  fetchMyCourseList: () => dispatch(getMyCourseList()),
  getPurchased: () => dispatch(fetchPurchased()),
  onOpenLoginDialog: () => dispatch(openLoginDialog()),
  onFetchLicenseList: () => dispatch(fetchLicenseList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
