/**
 * FeaturePath: 會員系統-個人資訊-證照登錄
 * Accountable: Landy Chu, AlexCH Cheng
 */

import React, { lazy, Suspense } from 'react';

import LoadingDimmer from '../components/Utils/LoadingDimmer';

const License = lazy(() => import('../components/Pages/License'));

export const LicensePage = props => (
  <Suspense fallback={LoadingDimmer()}>
    <License {...props} />
  </Suspense>
);
