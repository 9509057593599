/**
 * FeaturePath: 會員系統-其它-工具
 * Accountable: AlexCH Cheng, Landy Chu
 */

/**
 * PopupModal.react.js
 *
 * A component of alert dialog
 */

import React, { Component } from 'react';
import classnames from 'classnames';

import styles from './styles.css';

class PopupModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      shown: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    const { modalVisible: nextVisible } = nextProps;
    if (!this.state.shown && nextVisible) {
      this.setState({ shown: true });
    }
  }

  render() {
    const { modalVisible, close = () => {}, children, className } = this.props;
    let modalClasses = '';
    let wrapperClasses = '';
    if (this.state.shown) {
      modalClasses = modalVisible ? 'visible' : 'hidden';
      wrapperClasses = modalVisible ? 'wrapperVisible' : 'wrapperHidden';
    }

    return (
      <div className={classnames('wrapper', wrapperClasses)}>
        <div className={classnames('modal', className, styles.popupModal, modalClasses)} onClick={close}>
          {children}
        </div>
      </div>
    );
  }
}

export default PopupModal;
