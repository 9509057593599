/**
 * FeaturePath: 會員系統-購物車-購物車
 * Accountable: Landy Chu, AlexCH Cheng
 */

import React, { lazy, Suspense } from 'react';

import LoadingDimmer from '../components/Utils/LoadingDimmer';

const CourseBuy = lazy(() => import('../components/Pages/CourseBuy'));

export const CourseBuyPage = props => (
  <Suspense fallback={LoadingDimmer()}>
    <CourseBuy {...props} />
  </Suspense>
);
