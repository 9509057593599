/**
 * FeaturePath: 會員系統-個人資訊-註冊
 * Accountable: AlexCH Cheng, Landy Chu
 */

import { fetchMainSite, bodyFetch, uploadFetch, easyFetch, errorProcessing } from './endpoint';

const ROOT_NAME = 'account';

const accountRequest = {
  async mockLogout() {
    try {
      await bodyFetch(`/api/${ROOT_NAME}/logoff`, 'GET');
      return await Promise.resolve({ success: true });
    } catch (error) {
      return errorProcessing(error);
    }
  },
  async login(account, password) {
    try {
      const { user, showCustomRegisterPage } = await fetchMainSite(`/api/${ROOT_NAME}/login`, 'POST', { account, password, domain: 'ilearn' });
      return await Promise.resolve({ success: true, user, showCustomRegisterPage });
    } catch (error) {
      return errorProcessing(error);
    }
  },
  async ecLogin(account, password) {
    try {
      const { user } = await fetchMainSite(`/api/${ROOT_NAME}/ecLogin`, 'POST', { account, password });
      return await Promise.resolve({ success: true, user });
    } catch (error) {
      return errorProcessing(error);
    }
  },
  async erpLogin(account, password) {
    try {
      const { user, showCustomRegisterPage } = await fetchMainSite(`/api/${ROOT_NAME}/erpLogin`, 'POST', { account, password });
      return await Promise.resolve({ success: true, user, showCustomRegisterPage });
    } catch (error) {
      return errorProcessing(error);
    }
  },
  async logout() {
    try {
      await fetchMainSite(`/api/${ROOT_NAME}/logout`, 'POST');
      return await Promise.resolve({ success: true });
    } catch (error) {
      return errorProcessing(error);
    }
  },
  async register(data) {
    try {
      const result = await fetchMainSite(`/api/${ROOT_NAME}/quickRegister`, 'POST', data);
      return await Promise.resolve({ success: true, result });
    } catch (error) {
      return errorProcessing(error);
    }
  },
  async registerActivity(data) {
    try {
      const result = await bodyFetch(`/api/${ROOT_NAME}s/icare-register`, 'POST', data);
      return await Promise.resolve({ success: true, result });
    } catch (error) {
      return errorProcessing(error);
    }
  },
  async customRegister(data) {
    try {
      const result = await fetchMainSite(`/api/${ROOT_NAME}/customRegister`, 'POST', data);
      return await Promise.resolve({ success: true, result });
    } catch (error) {
      return errorProcessing(error);
    }
  },
  async verifyCode(mobile) {
    try {
      await bodyFetch(`/api/verify-codes/${mobile}`, 'GET');
      return await Promise.resolve({ success: true });
    } catch (error) {
      return errorProcessing(error);
    }
  },
  async reset(data) {
    try {
      const result = await fetchMainSite(`/api/${ROOT_NAME}/reset`, 'POST', data);
      return await Promise.resolve({ success: true, result });
    } catch (error) {
      return errorProcessing(error);
    }
  },
  async headshot(accountId, file) {
    if (file) {
      try {
        const formData = new FormData();
        formData.append('file', file);
        const result = await uploadFetch(`/api/${ROOT_NAME}s/${accountId}/headshot`, formData);
        return await Promise.resolve({ success: true, result });
      } catch (error) {
        return errorProcessing(error);
      }
    }

    return Promise.resolve({ success: false });
  },
  async checkAccount(account) {
    try {
      await easyFetch(`/api/${ROOT_NAME}/check`, 'GET', `account=${account}`);
      return await Promise.resolve({ success: true });
    } catch (error) {
      return errorProcessing(error);
    }
  },
};

export default accountRequest;
