exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".styles__dialog___dnay-{position:relative;width:auto;height:auto;max-width:340px;max-height:500px;top:30%;bottom:0;left:0;right:0;margin:auto;border:1px solid #eee;border-radius:10px;box-shadow:0 0 5px 0 rgba(0,0,0,.07);background-color:#fff;padding:1em 2em}.styles__dialog___dnay->.styles__body___35P6w{display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center;margin:12px 0}.styles__dialog___dnay->.styles__body___35P6w>img{width:70px;margin-right:2px}.styles__dialog___dnay->.styles__body___35P6w>p{font-size:20px}", ""]);

// exports
exports.locals = {
	"dialog": "styles__dialog___dnay-",
	"body": "styles__body___35P6w"
};