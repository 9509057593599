/**
 * FeaturePath: 會員系統-其它-工具
 * Accountable: AlexCH Cheng, Landy Chu
 */

export const META_TITLE = '仁寶 i 學習™';
export const TELEPHONE = '(02)8797-8588#17365';
export const EMAIL = 'iLearning_Service@compal.com';
export const LINE_URL = 'https://lin.ee/R94Zl9i';
export const PRIVACY_POLICY_URL = '/privacy-policy';
export const TERMS_OF_SERVICE_URL = '/service-terms';
export const FB_URL = 'https://www.facebook.com/ilearningicare';
export const COMPANY_NAME = '仁寶電腦工業股份有限公司';
export const UNIFIED_ID = '統一編號21222725';
export const COMPANY_HEALTHY_NAME = '仁寶健康科技股份有限公司';
export const UNIFIED_HEALTHY_ID = '統一編號93521716';
export const ADDRESS = '台北市內湖區瑞光路581及581之1號';
