/**
 * FeaturePath: 學員管理-課程管理-課程瀏覽-熱門課程
 * FeaturePath: 學員管理-課程管理-課程瀏覽-繼續教育積分課程
 * FeaturePath: 學員管理-課程管理-課程瀏覽-培訓課程
 * FeaturePath: 學員管理-課程管理-課程瀏覽-課程搜尋
 * FeaturePath: 學員管理-課程管理-課程瀏覽-進階搜尋
 * FeaturePath: 學員管理-課程管理-課程瀏覽-課程快搜
 * Accountable: Tom Jhuang, Landy Chu
 */

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import queryString from 'query-string-for-all';

import { openLoginDialog } from 'react/actions/utils';
import {
  getCourseCompalList,
  getCourseCreditList,
  getCourseTrainingList,
  getCourseHotList,
  getCourseFilterOption,
} from 'react/actions/course';

import CourseFilter from 'react/components/Utils/CourseFilter';
import BannerSlider from 'react/components/Utils/BannerSlider';

import CardSlider from './CardSlider';
import IconMenu from './IconMenu';

import styles from './styles.css';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: queryString.parse(props.location.search),
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.onGetList();
  }

  render() {
    const {
      hotList,
      campalList,
      creditList,
      trainingList,
      naviList,
      authStatus,
      isLuna,
      onOpenLoginDialog,
    } = this.props;

    return (
      <div className={styles.home}>
        <BannerSlider />
        <IconMenu
          className={styles.iconMenu}
          authStatus={authStatus}
          count={naviList.filter(list => list.course !== null).length}
          onOpenLoginDialog={onOpenLoginDialog}
        />
        <CourseFilter query={this.state.query} />
        <div className={styles.container}>
          <div className={styles.section}>
            <p className={styles.title}>
              熱門課程
              <Link to="/course?type=hot">+更多課程</Link>
            </p>
            <div className={styles.cardGroup}>
              <CardSlider
                className={styles.pcslider}
                type="pc"
                cardList={hotList}
              />
            </div>
            <CardSlider
              className={styles.slider}
              type="mobile"
              cardList={hotList}
            />
          </div>
          {isLuna && <div className={styles.line} />}
          {
            isLuna &&
            <div className={styles.section}>
              <p className={styles.title}>
                仁寶 i 照護系統課程
                <Link to="/course?category=COMPAL">+更多課程</Link>
              </p>
              <div className={styles.cardGroup}>
                <CardSlider
                  className={styles.pcslider}
                  type="pc"
                  cardList={campalList}
                />
              </div>
              <CardSlider
                className={styles.slider}
                type="mobile"
                cardList={campalList}
              />
            </div>
          }
          <div className={styles.line} />
          <div className={styles.section}>
            <p className={styles.title}>
              繼續教育積分課程
              <Link to="/course?category=CREDIT">+更多課程</Link>
            </p>
            <div className={styles.cardGroup}>
              <CardSlider
                className={styles.pcslider}
                type="pc"
                cardList={creditList}
              />
            </div>
            <CardSlider
              className={styles.slider}
              type="mobile"
              cardList={creditList}
            />
          </div>
          <div className={styles.line} />
          <div className={styles.section}>
            <p className={styles.title}>
              培訓課程
              <Link to="/course?category=TRAINING">+更多課程</Link>
            </p>
            <div className={styles.cardGroup}>
              <CardSlider
                className={styles.pcslider}
                type="pc"
                cardList={trainingList}
              />
            </div>
            <CardSlider
              className={styles.slider}
              type="mobile"
              cardList={trainingList}
            />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps({
  course: {
    list: { hot, campal, credit, training },
    navilist,
  },
  auth: { status, isLuna },
}) {
  return {
    hotList: hot,
    campalList: campal,
    creditList: credit,
    trainingList: training,
    authStatus: status,
    isLuna,
    naviList: navilist,
  };
}

const mapDispatchToProps = dispatch => ({
  onOpenLoginDialog: () => dispatch(openLoginDialog()),
  onGetList: () => {
    dispatch(getCourseHotList(true));
    dispatch(getCourseCompalList());
    dispatch(getCourseCreditList());
    dispatch(getCourseTrainingList());
    dispatch(getCourseFilterOption());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
