/**
 * FeaturePath: 會員系統-其它-工具
 * Accountable: Landy Chu, AlexCH Cheng
 */

import React, { useState } from 'react';
import { connect } from 'react-redux';

import { toggleAdvancedSearch, forwardTo } from 'react/actions/utils';

import ModalButton from 'react/components/Utils/Button/ModalButton';
import FormOuter from 'react/components/Utils/Form/FormOuter.react';
import PopupModal from 'react/components/Utils/Modal/PopupModal';
import IconClose from 'images/icon/close.svg';

import Dropdown from '../../../../components/Utils/Input/Dropdown';
import FormInput from '../../../../components/Utils/Input/FormInput';

import styles from './styles.css';

const CLASS_TYPE_OPTIONS = ['直播課程', '線上課程', '實體課程'];
const CREDIT_OPTIONS = ['是', '否'];

const Form = ({
  classTypeOptions,
  creditOptions,
  onClassTypeChange,
  onCreditChange,
  onChange,
  target,
}) => (
  <FormOuter className={styles.formOuter}>
    <div>
      <p>課程分類</p>
      <Dropdown
        name="classType"
        className={styles.dropdown}
        options={classTypeOptions}
        onChange={onClassTypeChange}
        label={target.classType || '請選擇課程分類'}
      />
    </div>
    <div>
      <p>繼續教育</p>
      <Dropdown
        name="credit"
        className={styles.dropdown}
        options={creditOptions}
        onChange={onCreditChange}
        label={target.credit || '請選擇繼續教育'}
      />
    </div>
    <div>
      <p>舉辦單位</p>
      <FormInput
        name="host"
        type="text"
        className={styles.formInput}
        value={target.host}
        onChange={onChange}
      />
    </div>
    <div>
      <p>活動地點</p>
      <FormInput
        name="location"
        type="text"
        className={styles.formInput}
        value={target.location}
        onChange={onChange}
      />
    </div>
    <div>
      <p>活動時間</p>
      <div className={styles.formInput}>
        <FormInput
          name="startDate"
          type="date"
          className={styles.formInputShort}
          value={target.startDate}
          onChange={onChange}
        />
        <span className={styles.textCenter}> ~ </span>
        <FormInput
          name="endDate"
          type="date"
          className={styles.formInputShort}
          value={target.endDate}
          onChange={onChange}
        />
      </div>
    </div>
  </FormOuter>
);

function getDefaultTarget() {
  return {
    classType: '',
    credit: '',
    host: '',
    location: '',
    startDate: '',
    endDate: '',
  };
}

const AdvancedSearchDialog = ({
  advancedSearchToggle,
  onAdvancedSearch,
  onToggleAdvancedSearch,
}) => {
  const [target, setTarget] = useState(getDefaultTarget());

  const handleClassTypeChange = value => {
    setTarget(prev => ({ ...prev, classType: value }));
  };

  const handleCreditChange = value => {
    setTarget(prev => ({ ...prev, credit: value }));
  };

  const handleChange = evt => {
    setTarget(prev => ({
      ...prev,
      [evt.target.name]: evt.target.value.trim(),
    }));
  };

  const handleCloseClick = () => {
    onToggleAdvancedSearch(false);
    setTarget(getDefaultTarget());
  };

  const handleSearchClick = () => {
    const { classType, credit, host, location, startDate, endDate } = target;

    const queryList = [];
    if (classType) {
      queryList.push(`type=${classType}`);
    }
    if (credit) {
      queryList.push(`credit=${credit === '是' ? 'true' : 'false'}`);
    }
    if (host) {
      queryList.push(`host=${encodeURIComponent(host)}`);
    }
    if (location) {
      queryList.push(`location=${encodeURIComponent(location)}`);
    }
    if (startDate) {
      queryList.push(`startDate=${startDate}`);
    }
    if (endDate) {
      queryList.push(`endDate=${endDate}`);
    }

    if (queryList.length > 0) {
      onAdvancedSearch(queryList.join('&'));
      handleCloseClick();
    }
  };

  return (
    <PopupModal className={styles.modal} modalVisible={advancedSearchToggle}>
      <div className={styles.wrapper} onClick={evt => evt.stopPropagation()}>
        <div>
          <div className={styles.imgWrapper} onClick={handleCloseClick}>
            <img src={IconClose} alt="" />
          </div>
          <Form
            classTypeOptions={CLASS_TYPE_OPTIONS}
            creditOptions={CREDIT_OPTIONS}
            onClassTypeChange={handleClassTypeChange}
            onCreditChange={handleCreditChange}
            onChange={handleChange}
            target={target}
          />
          <div className={styles.actionBox}>
            <ModalButton
              className={styles.actionBtn}
              light
              onClick={handleCloseClick}
            >
              取消
            </ModalButton>
            <ModalButton
              className={styles.actionBtn}
              disabled={
                !(
                  target.classType ||
                  target.credit ||
                  target.host ||
                  target.location ||
                  target.startDate ||
                  target.endDate
                )
              }
              onClick={handleSearchClick}
            >
              搜尋
            </ModalButton>
          </div>
        </div>
      </div>
    </PopupModal>
  );
};

function mapStateToProps({ utils: { advancedSearchToggle } }) {
  return {
    advancedSearchToggle,
  };
}

const mapDispatchToProps = dispatch => ({
  onToggleAdvancedSearch: toggle => dispatch(toggleAdvancedSearch(toggle)),
  onAdvancedSearch: query => dispatch(forwardTo(`/search?${query}`)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdvancedSearchDialog);
