/**
 * FeaturePath: 會員系統-其它-工具
 * Accountable: AlexCH Cheng, Landy Chu
 */

export const ZERO = 0; // 零

export const DATE_FORMAT = 'YYYY/MM/DD'; // 日期格式
export const DATE_FORMAT_DASH = 'YYYY-MM-DD'; // 日期格式
export const MONTH = 'month'; // 月
export const YEAR_DATE_FORMAT = 'YYYY年MM月'; // 年月格式
export const HOUR_MINUTE_SECOND = 'HHmmss'; // 時分秒格式
