/**
 * FeaturePath: 學員管理-課程管理-課程瀏覽-課程搜尋
 * Accountable: Tom Jhuang, AlexCH Cheng
 */

import React, { useState } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';

import closeImg from 'images/icon/close.svg';
import MainButton from 'react/components/Utils/Button/MainButton';

import { toggleSearch, forwardTo } from 'react/actions/utils';

import styles from './styles.css';

const SearchMobile = ({
  className,
  onToggleSearch,
  onSearch,
}) => {
  const [search, setSearch] = useState('');

  const handleChange = evt => {
    setSearch(evt.target.value);
  };

  const handleCloseClick = () => {
    onToggleSearch(false);
    setSearch('');
  };

  const handleSearch = () => {
    if (search.trim() !== '') {
      onSearch(search);
      handleCloseClick();
    }
  };

  const handleKeyPress = evt => {
    if (evt.key === 'Enter') {
      handleSearch();
    }
  };

  const handleClearInput = () => {
    setSearch('');
  };

  return (
    <div className={classnames(styles.searchMobile, className)}>
      <div
        className={styles.frame}
        role="presentation"
        onClick={handleCloseClick}
      />
      <div className={styles.searchWrap}>
        <p>請輸入你想查詢的關鍵字！</p>
        <div className={styles.searchInput}>
          <input
            type="text"
            name="search"
            value={search}
            onChange={handleChange}
            onKeyPress={handleKeyPress}
          />
          <div
            className={styles.closeWrap}
            role="presentation"
            onClick={handleClearInput}
          >
            <img src={closeImg} alt="" />
          </div>
        </div>
        <div className={styles.btnWrap}>
          <MainButton color="green" height="40px" onClick={handleSearch}>馬上搜尋</MainButton>
        </div>
      </div>
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    onToggleSearch: toggle => dispatch(toggleSearch(toggle)),
    onSearch: search => dispatch(forwardTo(`/search?keyword=${search}`)),
  };
}

export default connect(null, mapDispatchToProps)(SearchMobile);
