/**
 * FeaturePath: 會員系統-購物車-購物車
 * Accountable: AlexCH Cheng, Landy Chu
 */

import { easyFetch, errorProcessing } from './endpoint';

const cartRequest = {
  async fetchValidCoupon(code) {
    return easyFetch(`/api/coupon/valid/${code}`, 'GET')
      .then(({ valid, discount }) => Promise.resolve({ success: true, valid, discount }))
      .catch(errorProcessing);
  },
  async validCartCourse() {
    return easyFetch('/api/user/cart/check', 'GET')
      .then(({ invalid }) => Promise.resolve({ success: true, invalid }))
      .catch(errorProcessing);
  },
  async fetchOrderList() {
    return easyFetch('/api/user/order', 'GET')
      .then(({ list }) => Promise.resolve({ success: true, list }))
      .catch(errorProcessing);
  },
  async fetchBranchBankList(bankCode) {
    return easyFetch('/api/utils/branch-bank', 'GET', `bankCode=${bankCode}`)
      .then(({ list }) => Promise.resolve({ success: true, list }))
      .catch(errorProcessing);
  },
  async getOrderDetail(tradeNo) {
    return easyFetch(`/api/v2/orders/${tradeNo}`, 'GET')
      .then(({ data }) => Promise.resolve({ success: true, data }))
      .catch(errorProcessing);
  },
  async fetchOrderRecordList() {
    return easyFetch('/api/v2/orders', 'GET')
      .then(({ list }) => Promise.resolve({ success: true, list }))
      .catch(errorProcessing);
  },
  async cancelOrder(tradeNo) {
    return easyFetch(`/api/v2/orders/${tradeNo}`, 'PATCH')
      .then(({ data }) => Promise.resolve({ success: true, data }))
      .catch(errorProcessing);
  },
};

export default cartRequest;
