exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".styles__modal___SKfDx{position:relative;top:50%;margin:auto;transform:translateY(-50%);width:100vw;padding:0 15px}.styles__modal___SKfDx>.styles__wrapper___28m6u{max-width:500px;width:100%;max-height:90vh;margin:auto;background:#fff;border-radius:10px;overflow-x:hidden;overflow-y:auto;display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;text-align:center;font-size:28px;line-height:38px;padding:25px 20px}.styles__modal___SKfDx>.styles__wrapper___28m6u>.styles__prizeText___3bf6e{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;text-align:center;font-size:24px;line-height:30px}.styles__activityItem___1FrN-{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;width:100%}.styles__buttonContainer___28zJZ{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-pack:center;justify-content:center}.styles__button___MoeLt{width:45%;margin:20px auto}.styles__buttonGiveUp___3dmZw{width:45%;margin:20px auto;background-color:#716e6e}", ""]);

// exports
exports.locals = {
	"modal": "styles__modal___SKfDx",
	"wrapper": "styles__wrapper___28m6u",
	"prizeText": "styles__prizeText___3bf6e",
	"activityItem": "styles__activityItem___1FrN-",
	"buttonContainer": "styles__buttonContainer___28zJZ",
	"button": "styles__button___MoeLt",
	"buttonGiveUp": "styles__buttonGiveUp___3dmZw"
};