/**
 * FeaturePath: 會員系統-其它-工具
 * Accountable: Landy Chu, AlexCH Cheng
 */

import React, { useCallback, useState, forwardRef, useImperativeHandle, useEffect, useRef } from 'react';
import classnames from 'classnames';

import { LINE_URL } from 'react/constants/website';

import { validateFunction, validateMail } from 'react/utils/utils';

import Button from '../../../Button/Button';
import FormInput from '../../../Input/FormInput';
import styles from './styles.css';

const DEFAULT_COUNTDOWN = 60;

const TransferRegisterForm = ({
  errorMessage,
  defaultData,
  onGetVerifyCodeClick,
  onSubmit,
  onError,
}, ref) => {
  const [account, setAccount] = useState('');
  const [verifyCode, setVerifyCode] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [accountError, setAccountError] = useState('');
  const [verifyCodeError, setVerifyCodeError] = useState('');
  const [nameError, setNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [verifyCodeDisabled, setVerifyCodeDisabled] = useState(false);
  const [countdown, setCountdown] = useState(-1);
  const [isModify, setIsModify] = useState(false);

  const refCountdownTimeoutId = useRef(null);
  const refCountdown = useRef(-1);

  const startCountdown = useCallback(() => {
    refCountdownTimeoutId.current = window.setTimeout(() => {
      if (refCountdown.current <= 0) {
        return;
      }

      setCountdown(prev => prev - 1);
      startCountdown();
    }, 1000);
  }, []);

  const handleChange = useCallback(evt => {
    evt.preventDefault();
    switch (evt.target.name) {
      case 'account':
        setAccount(evt.target.value.trim().replace(/\D/g, ''));
        break;
      case 'verifyCode':
        setVerifyCode(evt.target.value.trim().replace(/\D/g, ''));
        break;
      case 'name':
        setName(evt.target.value);
        break;
      case 'email':
        setEmail(evt.target.value);
        break;
      default:
        break;
    }
  }, []);

  const handleModifyClick = useCallback(evt => {
    evt.preventDefault();
    setIsModify(true);
  }, []);

  const handleGetVerifyCodeClick = useCallback(evt => {
    evt.preventDefault();

    if (validateFunction(onGetVerifyCodeClick)) {
      onGetVerifyCodeClick(account);
    }

    setCountdown(DEFAULT_COUNTDOWN);
    startCountdown();
  }, [account, onGetVerifyCodeClick]);

  const handleSubmit = useCallback(evt => {
    evt.preventDefault();
  }, []);

  const handleSubmitClick = useCallback(evt => {
    evt.preventDefault();

    setIsSubmitted(true);
    if (accountError || nameError || emailError || (isModify && verifyCodeError)) {
      if (validateFunction(onError)) {
        onError();
      }
      return;
    }

    if (validateFunction(onSubmit)) {
      onSubmit({
        account,
        name,
        verifyCode,
        email,
        sourceAccount: defaultData?.account,
      });
    }
  }, [
    defaultData,
    account,
    verifyCode,
    name,
    email,
    accountError,
    verifyCodeError,
    nameError,
    emailError,
    onError,
    onSubmit,
  ]);

  const reset = useCallback(() => {
    if (refCountdownTimeoutId.current) {
      window.clearTimeout(refCountdownTimeoutId.current);
      refCountdownTimeoutId.current = null;
    }

    setAccount('');
    setVerifyCode('');
    setName('');
    setEmail('');
    setIsSubmitted(false);
    setAccountError('');
    setVerifyCodeError('');
    setNameError('');
    setEmailError('');
    setVerifyCodeDisabled(false);
    setCountdown(-1);
  }, []);

  useEffect(() => () => {
    if (refCountdownTimeoutId.current) {
      window.clearTimeout(refCountdownTimeoutId.current);
      refCountdownTimeoutId.current = null;
    }
  }, []);

  useEffect(() => {
    if (defaultData) {
      const {
        name: defaultName,
        phone: defaultPhone,
        email: defaultEmail,
      } = defaultData;

      if (!defaultPhone || !defaultName) {
        setIsModify(true);
      }

      setAccount(defaultPhone);
      setName(defaultName);
      setEmail(defaultEmail);
    }
  }, [defaultData]);

  useEffect(() => {
    let error = '';
    let disabled = false;
    if (account === '') {
      error = '請輸入手機號碼來取得認證碼';
      disabled = true;
    } else if (!/^09\d{8}$/i.test(account)) {
      error = '格式錯誤';
      disabled = true;
    }

    setAccountError(error);
    setVerifyCodeDisabled(disabled);
  }, [account]);

  useEffect(() => {
    if (verifyCode === '') {
      setVerifyCodeError('請輸入認證碼');
      return;
    }

    if (!/^\d{4}$/i.test(verifyCode)) {
      setVerifyCodeError('格式錯誤');
      return;
    }

    setVerifyCodeError('');
  }, [verifyCode]);

  useEffect(() => {
    if (name === '') {
      setNameError('請輸入真實姓名');
      return;
    }

    setNameError('');
  }, [name]);

  useEffect(() => {
    if (email === '') {
      setEmailError('請輸入電子信箱');
      return;
    }

    if (!validateMail(email)) {
      setEmailError('格式錯誤');
      return;
    }

    setEmailError('');
  }, [email]);

  useEffect(() => {
    refCountdown.current = countdown;
  }, [countdown]);

  useImperativeHandle(ref, () => ({
    reset,
  }));

  return (
    <form className="registerForm" onSubmit={handleSubmit}>
      <div className="form__note-wrapper">
        <p className="form__note form__note--failed">{errorMessage}</p>
      </div>
      <p className={styles.directions}>如變更姓名或手機號碼需重新進行手機認證</p>
      <div className={styles.accountRow}>
        <FormInput
          type="text"
          icon="user"
          name="account"
          htmlFor="registerAccount"
          placeholder="請輸入手機號碼來取得認證碼"
          label="手機號碼(帳號)"
          maxLength={10}
          pattern="^09\d{8}$"
          value={account}
          disabled={!isModify}
          onChange={handleChange}
        />
        <Button
          disabled={isModify}
          onClick={handleModifyClick}
        >
          變更資料
        </Button>
      </div>
      {
        isSubmitted && accountError &&
        <p className={classnames(styles.error)}>{accountError}</p>
      }
      <FormInput
        type="text"
        icon="paper plane"
        name="verifyCode"
        htmlFor="verifyCode"
        placeholder="請輸入簡訊的 4 位數字"
        label="認證碼"
        maxLength={4}
        pattern="^\d{4}$"
        value={verifyCode}
        disabled={!isModify}
        onChange={handleChange}
      />
      {
        isSubmitted && isModify && verifyCodeError &&
        <p className={classnames(styles.error)}>{verifyCodeError}</p>
      }
      <div className={styles.verifyCodeRow}>
        <div>
          <p>收不到認證碼? </p>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={LINE_URL}
          >
            Line 線上客服
          </a>
        </div>
        <Button
          style={{ width: '120px' }}
          disabled={!isModify || verifyCodeDisabled || countdown > 0}
          onClick={handleGetVerifyCodeClick}
        >
          {
            countdown < 0
              ? '取得認證碼'
              : `重新發送${countdown === 0 ? '' : `(${countdown})`}`
          }
        </Button>
      </div>
      <FormInput
        type="text"
        icon="pencil alternate"
        name="name"
        htmlFor="name"
        placeholder="請勿使用注音、表情符號"
        label="真實姓名"
        maxLength={24}
        value={name}
        disabled={!isModify}
        onChange={handleChange}
      />
      {
        isSubmitted && nameError &&
        <p className={classnames(styles.error)}>{nameError}</p>
      }
      <FormInput
        type="text"
        icon="pencil alternate"
        name="email"
        htmlFor="email"
        placeholder="請輸入電子信箱"
        label="電子信箱"
        maxLength={40}
        value={email}
        onChange={handleChange}
      />
      {
        isSubmitted && emailError &&
        <p className={classnames(styles.error)}>{emailError}</p>
      }
      <div className="ui center aligned basic segment">
        <Button
          className={styles.buttonTrans}
          onClick={handleSubmitClick}
        >
          確定，進行帳號轉移
        </Button>
      </div>
    </form>
  );
};

export default forwardRef(TransferRegisterForm);
