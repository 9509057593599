/**
 * FeaturePath: 學員管理-資料管理-購物車-加入購物車
 * FeaturePath: 學員管理-資料管理-購物車-移出購物車
 * FeaturePath: 學員管理-資料管理-購物車-查看購物車
 * FeaturePath: 學員管理-資料管理-購物車-未登入購物車
 * Accountable: Tom Jhuang, Landy Chu
 */
import {
  SET_CART_LIST,
  SET_VALID_COUPON,
  SET_COUPON_INFO,
  SET_ORDER_LIST,
  SET_BRANCH_BANK_LIST,
  SET_ORDER_DETAIL,
  SET_ORDER_RECORD_LIST,
} from 'react/constants/cart';

import { COURSE_TYPE } from 'react/constants/enum';

import { openGlobalModal } from 'react/actions/modal';
import { sendingRequest } from 'react/actions/utils';

import cartRequest from '../request/cart';
import cartsRequest from '../request/carts';
import { SET_CONFIRM_CART_LIST } from '../constants/cart';

import { log } from '../utils/utils';
import { getErrorMessage } from '../utils/error';

const API_VERSION = '0.3';
const CART_NAME = `CART_${API_VERSION}`;

const windowsLocalStorage = window.localStorage;
let localCartList = [];

function verifyCart({ course }) {
  let success = true;
  let message = '';
  const courseNames = [];
  for (let index = 0; index < localCartList.length; index += 1) {
    const item = localCartList[index].course;
    if (item._id === course._id) {
      success = false;
      message = `學員您好，您購物車已經有該${item.type === COURSE_TYPE.Package ? '組合包' : ''}課程，無法再加入到購物車。`;
      break;
    }

    switch (course.type) {
      case COURSE_TYPE.Package:
        if (item.type !== COURSE_TYPE.Package) {
          if (course.packages.includes(item._id)) {
            courseNames.push(item.name);
            if (course.packages.length === courseNames.length) {
              success = false;
            }
          }
        }
        break;
      default:
        if (item.type === COURSE_TYPE.Package) {
          if (item.packages.includes(course._id)) {
            success = false;
            message = `學員您好，您購物車中${item.name}組合包已涵蓋此課程，無法再購買該課程。`;
          }
        }
        break;
    }

    if (!success) {
      break;
    }
  }

  if (courseNames.length > 0) {
    success = false;
    message = `學員您好，您已在購物車中加入過${courseNames.join('、')}課程，無法再購買該組合包課程。`;
  }

  return { success, message };
}

export function setCartList(list) {
  return { type: SET_CART_LIST, list };
}

export function setCouponState(type, coupon) {
  return { type, coupon };
}

export function setOrderList(list) {
  return { type: SET_ORDER_LIST, list };
}

export function setOrderRecordList(list) {
  return { type: SET_ORDER_RECORD_LIST, list };
}

export function setBranchBankList(list) {
  return { type: SET_BRANCH_BANK_LIST, list };
}

export function setOrderDetail(data) {
  return { type: SET_ORDER_DETAIL, data };
}

export function setConfirmCart(list) {
  return { type: SET_CONFIRM_CART_LIST, list };
}

export function getCartList(authStatus = true) {
  return async dispatch => {
    if (authStatus) {
      const cartJson = windowsLocalStorage.getItem(CART_NAME);
      if (cartJson) {
        windowsLocalStorage.removeItem(CART_NAME);

        try {
          localCartList = JSON.parse(cartJson);
          const productList = localCartList.map(item => ({
            from: item.from || '',
            course: item.courseId,
            productId: item.productId,
          }));
          await cartsRequest.addProductsToCart(productList);
          alert('購物車已更新，請至購物車確認');
        } catch (error) {
          log('error :>> ', error);
        }
      }

      if (localCartList.length > 0) {
        localCartList.length = 0;
      }

      const { success, list } = await cartsRequest.getCartList();
      if (success === true) {
        dispatch(setCartList(list));
      }

      return;
    }

    const cartJson = windowsLocalStorage.getItem(CART_NAME);
    if (cartJson) {
      try {
        localCartList = JSON.parse(cartJson);
      } catch (error) {
        log('error :>> ', error);
      }
    }

    if (!Array.isArray(localCartList)) {
      localCartList = [];
    }

    dispatch(setCartList([...localCartList]));
  };
}

export function addProductListToCart(productList) {
  return async dispatch => {
    if (Array.isArray(productList)) {
      dispatch(sendingRequest(true));
      const { success, message, isContinued, msg } = await cartsRequest.addProductsToCart(productList);
      if (!success) {
        dispatch(sendingRequest(false));
        switch (msg) {
          case 315:
            alert('此課程已額滿');
            break;
          case 316:
            alert('此課程不在報名期間');
            break;
          default:
            alert('此課程發生未知的錯誤，無法加入購物車');
            break;
        }
        return false;
      }

      if (isContinued) {
        dispatch(getCartList());
      }

      if (message) {
        alert(message);
      }
      dispatch(sendingRequest(false));
      return isContinued;
    }

    return true;
  };
}

export function addProductToCart(productId, courseId, from) {
  return async dispatch => {
    const productList = [{
      from,
      course: courseId,
      productId,
    }];

    const result = await dispatch(addProductListToCart(productList));
    return result;
  };
}

export function addCourseToCart(course, from, authStatus = true) {
  return async dispatch => {
    if (!course) {
      return true;
    }

    if (authStatus) {
      const { _id, productId } = course;
      const result = await dispatch(addProductToCart(productId, _id, from));
      return result;
    }

    const {
      _id,
      productId,
      category,
      companyPrice,
      discount,
      duration,
      level,
      name,
      online,
      price,
      teacher,
      type,
      packages,
      banner,
      mbanner,
    } = course;

    const newCourse = {
      coupon: '',
      couponDiscount: 0,
      course: {
        _id,
        category,
        companyPrice,
        discount,
        duration,
        level,
        name,
        online,
        price,
        teacher,
        type,
      },
      image: banner || mbanner,
      name,
      price,
      specialOffer: discount,
      productId,
      courseId: _id,
      from,
    };

    if (Array.isArray(packages)) {
      newCourse.course.packages = packages.map(item => item._id);
    }

    const { success, message } = verifyCart(newCourse);
    if (!success) {
      alert(message);
      return false;
    }

    localCartList.push(newCourse);
    windowsLocalStorage.setItem(CART_NAME, JSON.stringify(localCartList));
    dispatch(setCartList([...localCartList]));
    return true;
  };
}

export function removeProductFromCart(productId, courseId, authStatus = true) {
  return async dispatch => {
    if (authStatus) {
      const { success } = await cartsRequest.removeProductsFromCart(productId, courseId);
      if (success) {
        dispatch(getCartList());
      }

      return;
    }

    const index = localCartList.findIndex(
      item => item.productId === productId && item.courseId === courseId);
    if (index >= 0) {
      localCartList.splice(index, 1);
      windowsLocalStorage.setItem(CART_NAME, JSON.stringify(localCartList));
      dispatch(setCartList([...localCartList]));
    }
  };
}

export function applyCoupon(productId, couponCode) {
  return async dispatch => {
    const { success, list, err } = await cartsRequest.applyCoupon(productId, couponCode);
    if (success === true) {
      dispatch(setCartList(list));
    } else {
      const message = err?.errorCode ? getErrorMessage(err?.errorCode) : '';
      alert((message || `使用折價券失敗${err?.errorCode ? `(${err?.errorCode})` : ''}`));
      return false;
    }

    return true;
  };
}

export function cancelCoupon(productId) {
  return async dispatch => {
    const { success, list } = await cartsRequest.cancelCoupon(productId);
    if (success === true) {
      dispatch(setCartList(list));
    }
  };
}

export function validCoupon(code) {
  return async dispatch => {
    const { success, valid } = await cartRequest.fetchValidCoupon(code);
    if (success === true) {
      dispatch(setCouponState(SET_VALID_COUPON,
        {
          code,
          valid,
        },
      ));
    }
  };
}

export function clearCartList() {
  return async dispatch => {
    dispatch(setCartList([]));
  };
}

export function lastValidCoupon(code) {
  return async dispatch => {
    if (code !== '') {
      const { success, valid } = await cartRequest.fetchValidCoupon(code);
      if (success === true) {
        if (valid === true) {
          document.getElementById('couponForm').submit();
        } else if (valid === false) {
          dispatch(openGlobalModal('cartCouponValidError'));
          dispatch(setCouponState(SET_COUPON_INFO,
            {
              code: '',
              valid: false,
              discount: 0,
              using: false,
            },
          ));
        }
      }
    } else {
      document.getElementById('couponForm').submit();
    }
  };
}

export function validCartCourse() {
  return async dispatch => {
    const { success, invalid } = await cartRequest.validCartCourse();
    if (success) {
      if (invalid.length > 0) {
        alert(`您所選購的課程無法購買(如已下架/已額滿/課程字號到期等)，系統將自動移除下列課程﹕\n${invalid.map((c, i) => `${i + 1}.${c.name}`).join('\n')}`);
        dispatch(getCartList());
        return Promise.resolve({ success: false });
      }
    }

    return Promise.resolve({ success });
  };
}

export function getOrderList() {
  return async dispatch => {
    const { success, list } = await cartRequest.fetchOrderList();
    if (success === true) {
      dispatch(setOrderList(list));
    }
  };
}

export function getBankBranchList(bankCode) {
  return async dispatch => {
    const { success, list } = await cartRequest.fetchBranchBankList(bankCode);
    if (success === true) {
      dispatch(setBranchBankList(list));
    }
  };
}

export function initialBankBranchList() {
  return dispatch => {
    dispatch(setBranchBankList([]));
  };
}

export function getOrderDetail(tardeNo) {
  return async dispatch => {
    const { success, data } = await cartRequest.getOrderDetail(tardeNo);
    if (success === true) {
      dispatch(setOrderDetail(data));
    }
  };
}

export function checkoutCart(cart) {
  return async () => {
    const { success, err } = await cartsRequest.cartCheckout(cart);
    if (success === true) {
      return success;
    }
    return err;
  };
}

export function getOrderRecordList() {
  return async dispatch => {
    const { success, list } = await cartRequest.fetchOrderRecordList();
    if (success === true) {
      dispatch(setOrderRecordList(list));
    }
  };
}

export function cancelOrder(tardeNo) {
  return async () => {
    const { success, err } = await cartRequest.cancelOrder(tardeNo);
    if (success === true) {
      return success;
    }
    return (err);
  };
}
