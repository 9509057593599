/**
 * FeaturePath: 會員系統-其它-工具
 * Accountable: Landy Chu, AlexCH Cheng
 */

import React, { Component, FC, FormEvent, ReactElement } from 'react';
import classnames from 'classnames';

import styles from './styles.module.scss';

interface StaticIconProps {
  icon: string;
  error: boolean;
}

const StaticIcon: FC<StaticIconProps> = ({ icon, error }) => {
  if (icon) {
    return (
      <a className={classnames('ui', styles['static-i'], { error })}>
        <i className={`${icon} icon`} />
      </a>
    );
  }
  return null;
};
export type FormInputType = 'password' | 'text' | 'number' | 'email' | 'date' | 'checkbox' | 'tel';
interface FormInputProps {
  /** 元件的 class name */
  className?: string;
  /** 輸入欄位的錯誤狀態 */
  error?: boolean;
  /** 輸入是否為必填 */
  must?: boolean;
  /** 輸入欄位的圖標名稱 */
  icon?: string;
  /** 輸入欄位的提示文字 */
  placeholder?: string;
  /** 標籤應該關聯的表單元素的 ID */
  htmlFor?: string;
  /** 輸入欄位的名稱 */
  name?: string;
  /** 輸入欄位的標籤文字 */
  label?: string;
  /** 輸入欄位的類型 */
  type: FormInputType;
  /** 輸入欄位可輸入的最大字元數 */
  maxLength?: number;
  /** 輸入欄位是否已禁用 */
  disabled?: boolean;
  /** 輸入欄位是否為唯讀 */
  readonly?: boolean;
  /** 當使用者按下 Enter 鍵時應該被呼叫的函式 */
  onEnter?: (value: string) => void;
  /** 當使用者點擊輸入欄位時應該被呼叫的函式 */
  onClick?: () => void;
  /** 數字輸入欄位的最小值 */
  min?: string;
  /** 數字輸入欄位的最大值 */
  max?: string;
  /** 正規表達式輸入欄位應該匹配的模式 */
  pattern?: string;
  /** 核取方塊或單選按鈕輸入欄位的預設勾選狀態 */
  defaultChecked?: boolean;
  /** 輸入欄位的值 */
  value?: string;
  /** 當輸入欄位獲得焦點時應該被呼叫的函式 */
  onFocus?: (focus: { focus: boolean }) => void;
  /** 當輸入欄位失去焦點時應該被呼叫的函式 */
  onBlur?: (event: FormEvent<HTMLInputElement>) => void;
  /** 當輸入欄位的值變更時應該被呼叫的函式 */
  onChange?: (
    arg0?: FormEvent<HTMLInputElement> | any) => void;
  /** 輸入欄位是否應該獲得焦點 */
  focus?: boolean;
  /** 其它 */
  others?: any;
  [key: string]: any;
}


interface FormInputState {
  /** FormInputProps 的值 */
  propsValue?: string;
  /** 輸入欄位的值 */
  value?: string;
  /** 輸入欄位是否獲得焦點 */
  focus: boolean;
  /** 密碼是否被顯示 */
  displayPassword: boolean;
}

class FormInput extends Component<FormInputProps, FormInputState> {
  constructor(props: FormInputProps) {
    super(props);
    this.state = {
      propsValue: props.value,
      value: props.value,
      focus: false,
      displayPassword: false,
    };
  }

  componentDidMount() {
    if (this.props.focus) {
      this.refInput?.focus();
    }
  }

  static getDerivedStateFromProps(props: FormInputProps, state: FormInputState) {
    if (props.value !== state.propsValue) {
      return {
        propsValue: props.value ?? '',
        value: props.value ?? '',
      };
    }
    return null;
  }

  componentDidUpdate() {
    if (this.props.focus) {
      this.refInput?.focus();
    }
  }

  private refInput: HTMLInputElement | null = null;

  clearValue() {
    this.setState({ value: '' });
  }

  _onFocus() {
    this.setState({ focus: true });
    if (this.props.onFocus) {
      this.props.onFocus({ focus: true });
    }
  }

  _onBlur(event: FormEvent<HTMLInputElement>) {
    this.setState({ focus: false });

    if (this.props.onBlur) {
      this.props.onBlur(event);
    }
  }

  _onChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (!('value' in this.props)) {
      this.setState({ value: event.target.value ?? '' });
    }

    // Transmit to parent
    if (this.props.onChange) {
      this.props.onChange(event);
    }
  }

  _renderIcon(): ReactElement | null {
    const { error, type } = this.props;
    if (error) {
      return (
        <a data-testid="errorIcon" className={classnames('ui', styles['generated-i'], { error })}>
          <i className="warning circle icon" />
        </a>
      );
    }

    if (type === 'password') {
      return (
        <a
          className={classnames('ui', styles['generated-i'], {
            [styles.displayPassword]: this.state.displayPassword,
          })}
          onClick={() => this.setState({ displayPassword: !this.state.displayPassword })}
          data-testid="change-password"
        >
          {this.state.displayPassword ? <i className="eye icon" /> : <i data-testid="eyeIcon" className="hide icon" />}
        </a>
      );
    }

    return null;
  }

  render() {
    const {
      className,
      error = false,
      must = false,
      icon,
      placeholder,
      htmlFor,
      name,
      label,
      type = 'text',
      maxLength,
      disabled,
      readonly,
      onEnter = null,
      onClick = () => { },
      min,
      max,
      pattern,
      defaultChecked,
      others,
    } = this.props;

    const { value, focus } = this.state;

    return (
      <div
        className={classnames(styles['form-input'], className, {
          [styles.valid]: (value || (type === 'number' && value === '0')) && !error,
          [styles.error]: error,
          [styles.icon]: icon,
          [styles.focus]: focus && !error,
        })}
      >
        <StaticIcon icon={icon ?? ''} error={error} />
        {this._renderIcon()}
        <input
          data-testid={this.props['data-testid'] ?? 'formInput'}
          className={classnames({
            [styles.icon]: icon,
            [styles.focus]: focus && !error,
            [styles.error]: error,
            [styles.disabled]: disabled,
            [styles.holder]: placeholder && !label,
          })}
          ref={input => {
            this.refInput = input;
          }}
          placeholder={placeholder}
          id={htmlFor}
          name={name}
          type={this.state.displayPassword ? 'text' : type}
          value={value}
          maxLength={maxLength}
          onChange={evt => this._onChange(evt)}
          onBlur={evt => this._onBlur(evt)}
          onFocus={() => this._onFocus()}
          onClick={onClick}
          onKeyPress={evt => {
            if (evt.key === 'Enter' && onEnter) {
              evt.preventDefault();
              onEnter(value ?? '');
              this.clearValue();
            }
          }}
          disabled={disabled}
          readOnly={readonly}
          min={min}
          max={max}
          pattern={pattern}
          {...others}
          checked={defaultChecked}
        />
        <label htmlFor={htmlFor}>
          {must && (
            <span style={{ color: 'red' }}>
              <i className="asterisk icon" />
            </span>
          )}
          {label}
        </label>
      </div>
    );
  }
}

export default FormInput;
