/**
 * FeaturePath: 會員系統-個人資訊-折價券
 * Accountable: Landy Chu, AlexCH Cheng
 */

import React, { lazy, Suspense } from 'react';

import LoadingDimmer from '../components/Utils/LoadingDimmer';

const Coupon = lazy(() => import('../components/Pages/Coupon'));

export const CouponPage = props => (
  <Suspense fallback={LoadingDimmer()}>
    <Coupon {...props} />
  </Suspense>
);
