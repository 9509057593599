/**
 * FeaturePath: 會員系統-其它-工具
 * Accountable: Landy Chu, AlexCH Cheng
 */

import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

// Component has four state: normal, focus, selected, disabled
const MainButton = ({
  className,
  color,
  height,
  children,
  state,
  onClick,
  square,
  ...props
}) => (
  <button
    className={classnames(
      styles[color],
      styles[`height_${height}`],
      className,
      {
        [styles.disabled]: state === 'disabled',
        [styles.selected]: state === 'selected',
        [styles.focus]: state === 'focus',
        [styles.square]: square,
      },
    )}
    disabled={state === 'disabled'}
    onClick={e => {
      if (state === 'normal' || state === 'focus') {
        onClick(e);
      }
    }}
    {...props}
  >
    {children}
  </button>
);

MainButton.propTypes = {
  /**
   * 按鈕內容
   */
  children: PropTypes.string,
  /**
   * 自訂樣式
   */
  className: PropTypes.string,
  /**
   * 按鈕顏色
   */
  color: PropTypes.oneOf(['basic', 'orange', 'green']),
  /**
   * 按鈕高度
   */
  height: PropTypes.oneOf(['40px', '50px', '32px']),
  /**
   * 傳入點擊函數
   */
  onClick: PropTypes.func,
  /**
   * 按鈕狀態
   */
  state: PropTypes.oneOf(['normal', 'disabled', 'selected', 'focus']),
  /**
   * 方形按鈕
   */
  square: PropTypes.bool,

};

MainButton.defaultProps = {
  className: '',
  color: 'basic',
  height: '40px',
  state: 'normal',
  onClick: () => {},
  square: false,
  children: '我是按鈕',
};

export default MainButton;
