/**
 * FeaturePath: 會員系統-購物車-購物車
 * Accountable: Landy Chu, AlexCH Cheng
 */

import objectAssign from 'object.assign';

import {
  SET_CART_LIST,
  SET_VALID_COUPON,
  SET_COUPON_INFO,
  SET_ORDER_LIST,
  SET_BRANCH_BANK_LIST,
  SET_ORDER_DETAIL,
  SET_CONFIRM_CART_LIST,
  SET_ORDER_RECORD_LIST,
} from 'react/constants/cart';
// Object.assign is not yet fully supported in all browsers, so we fallback to
// a polyfill
const assign = Object.assign || objectAssign;

// The initial application state
const initialState = {
  cart: [],
  confirmCart: [],
  coupon: {
    code: '',
    valid: false,
    discount: 0,
    using: false,
  },
  couponValid: {
    code: '',
    valid: false,
  },
  order: [],
  branchBankList: [],
  orderDetail: {},
  orderRecord: [],
};

// Takes care of changing the application state
export function cart(state = initialState, action) {
  switch (action.type) {
    case SET_CART_LIST:
      return assign({}, state, {
        cart: action.list,
      });
    case SET_VALID_COUPON:
      return assign({}, state, {
        couponValid: {
          code: action.coupon.code,
          valid: action.coupon.valid,
        },
      });
    case SET_COUPON_INFO:
      return assign({}, state, {
        coupon: {
          code: action.coupon.code,
          valid: action.coupon.valid,
          discount: action.coupon.discount,
          using: action.coupon.using,
        },
      });
    case SET_ORDER_LIST:
      return assign({}, state, {
        order: action.list,
      });
    case SET_BRANCH_BANK_LIST:
      return assign({}, state, {
        branchBankList: action.list,
      });
    case SET_ORDER_DETAIL:
      return assign({}, state, {
        orderDetail: action.data,
      });
    case SET_CONFIRM_CART_LIST:
      return assign({}, state, {
        confirmCart: action.list,
      });
    case SET_ORDER_RECORD_LIST:
      return assign({}, state, {
        orderRecord: action.list,
      });
    default:
      return state;
  }
}
