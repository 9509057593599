/**
 * FeaturePath: 學員管理-直播管理-聊天室-連線狀態
 * FeaturePath: 學員管理-交易管理-退課-退課
 * FeaturePath: 學員管理-課程管理-上課-線上課程
 * FeaturePath: 學員管理-課程管理-上課-直播課程
 * FeaturePath: 學員管理-課程管理-上課-(直播)簽到
 * FeaturePath: 學員管理-課程管理-上課-(直播)時數計算
 * FeaturePath: 學員管理-資料管理-購物車-查看購物車
 * Accountable: Tom Jhuang, AlexCH Cheng
 */
export const SET_TARGET = 'SET_TARGET_COURSE';
export const SET_LIST = 'SET_COURSE_LIST';
export const SET_HOT_LIST = 'SET_HOT_COURSE_LIST';
export const SET_RELATED_LIST = 'SET_RELATED_COURSE_LIST';
export const SET_COMPAL_LIST = 'SET_COMPAL_COURSE_LIST';
export const SET_CREDIT_LIST = 'SET_CREDIT_COURSE_LIST';
export const SET_TRAINING_LIST = 'SET_TRAINING_COURSE_LIST';
export const SET_MY_LIST = 'SET_MY_COURSE_LIST';
export const GET_LIST_META = 'GET_LIST_META';
export const SET_NAVI_LIST = 'SET_NAVI_COURSE_LIST';
export const SET_SEARCH_LIST = 'SET_SEARCH_COURSE_LIST';
export const GET_SEARCH_META = 'SET_SEARCH_COURSE_META';
export const GET_FILTER_OPTION = 'GET_COURSE_FILTER_OPTION';
export const SAVE_FILTER_QUERY = 'SAVE_FILTER_QUERY';
export const SET_REFUND_BANK = 'SET_REFUND_BANK';
export const SET_REFUND_INFO = 'SET_REFUND_INFO';
export const SET_PACKAGE = 'SET_PACKAGE';
export const SET_PURCHASED = 'SET_PURCHASED';
export const SET_LIVE_SCRIPTS = 'SET_COURSE_LIVE_SCRIPTS';
export const GET_CALENDAR = 'GET_CALENDAR';
export const RESET_TARGET = 'RESET_TARGET_COURSE';
export const GET_RECOMMEND_COURSES = 'GET_RECOMMEND_COURSES';
export const GET_LEARNING_HISTORY = 'GET_LEARNING_HISTORY';
export const RESET_MOBILE_LIST = 'RESET_MOBILE_LIST';
export const SET_MOBILE_LIST = 'SET_MOBILE_LIST';

export const LEVEL_MAP = {
  ELEMENTARY: '初階',
  MEDIUM: '中階',
  ADVANCED: '進階',
};

export const finalRefundLimitDays = 3;

