/**
 * FeaturePath: 會員系統-其它-工具
 * Accountable: Landy Chu, AlexCH Cheng
 */

// ----- import npm modules -----
import React, { useMemo } from 'react';
import classnames from 'classnames';

// ----- import local modules -----
import Button from '../../Button/Button';

// ----- import styles -----
import styles from './styles.css';

const InformDialog = ({ className, header, description, approve }) => {
  /**
   * 描述文字的陣列(以換行字元分割)
   */
  const descriptions = useMemo(() => description?.split('\n') || [], [description]);

  return (
    <div className={classnames(styles.dialog, className)}>
      <div className={styles.title}>
        {header}
      </div>
      <div className={styles.body}>
        {
          descriptions.map((item, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={index}>
              {item}
            </div>
          ))
        }
      </div>
      <div className={styles.action}>
        <Button onClick={approve}>
          OK
        </Button>
      </div>
    </div>
  );
};

export default InformDialog;
