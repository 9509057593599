/**
 * FeaturePath: 學員管理-資料管理-註冊-O2O登入
 * FeaturePath: 學員管理-資料管理-註冊-O2O註冊
 * Accountable: Landy Chu, AlexCH Cheng
 */
/*
 * accountConstants
 * These are the variables that determine what our central data store (reducer.js)
 * changes in our state. When you add a new action, you have to add a new constant here
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'YOUR_ACTION_CONSTANT';
 */
export const SET_AUTH = 'SET_AUTH';
export const SET_AUTH_NAME = 'SET_AUTH_NAME';
export const SET_AUTHLEVEL = 'SET_AUTHLEVEL';
