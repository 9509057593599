/**
 * FeaturePath: 會員系統-購物車-購物車
 * Accountable: Landy Chu, AlexCH Cheng
 */

import React, { lazy, Suspense } from 'react';

import LoadingDimmer from '../components/Utils/LoadingDimmer';

const Cart = lazy(() => import('../components/Pages/Cart'));

export const CartPage = props => (
  <Suspense fallback={LoadingDimmer()}>
    <Cart {...props} />
  </Suspense>
);
