/**
 * FeaturePath: 會員系統-其它-廣告
 * Accountable: AlexCH Cheng, Landy Chu
 */

import { easyFetch, errorProcessing } from './endpoint';

const ROOT_NAME = 'live-scripts';

const liveScriptRequest = {
  async fetchLiveScripts(id) {
    return easyFetch(`/api/${ROOT_NAME}/${id}`, 'GET')
      .then(({ content }) => Promise.resolve({ success: true, content }))
      .catch(errorProcessing);
  },
};

export default liveScriptRequest;
