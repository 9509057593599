/**
 * FeaturePath: 會員系統-其它-工具
 * Accountable: AlexCH Cheng, Landy Chu
 */

import React, { useCallback, useMemo } from 'react';
import classnames from 'classnames';
import moment from 'moment';

import { CourseType, QuizStatus, getQuizStatusName } from '../../../../constants/enums/course';
import { ICourseCardViewProps } from './index.interface';

import { numberWithCommas } from '../../../../utils/helper';

import starImg from '../../../../../images/icon/star.svg';
import nostarImg from '../../../../../images/icon/nostar.svg';
import likeClickIcon from '../../../../../images/icon/likeClick.svg';
import likeDefaultIcon from '../../../../../images/icon/likeDefault.svg';

import MainButton from '../../Button/MainButton';

import styles from './styles.module.scss';

const CourseCardView: React.FC<ICourseCardViewProps> = ({
  className,
  id,
  imgClassName,
  img,
  type,
  title,
  star = 5,
  points = 0,
  originPrice,
  discount = null,
  state,
  progress,
  score,
  courseType,
  quota = 1,
  quizStatus,
  purchases = [],
  wishList = [],
  creditPointEndAt,
  onCourseCardClick,
  onAddToWishClick,
}: ICourseCardViewProps) => {
  const isCreditApplyOverDate = useMemo(() => {
    if (creditPointEndAt) {
      return moment(creditPointEndAt).isBefore(moment());
    }

    return false;
  }, [creditPointEndAt]);

  const renderCreditApplyLimitText = useCallback(() => {
    if (creditPointEndAt && (
      quizStatus === QuizStatus.Not ||
      quizStatus === QuizStatus.Done ||
      quizStatus === QuizStatus.Pass
    )) {
      return isCreditApplyOverDate ?
        '逾期未申請積分' : `積分申請期限: ${moment(creditPointEndAt).format('YYYY-MM-DD')}`;
    }

    return '';
  }, [creditPointEndAt, quizStatus]);

  return (
    <div
      className={classnames(styles.courseCard, className)}
      role="presentation"
      onClick={onCourseCardClick}
    >
      <div className={classnames(styles.imgWrap, imgClassName)}>
        <div className={styles.imgContainer}>
          <img src={img} alt="" />
        </div>
        <div
          className={classnames(styles.tag, {
            [styles.online]: courseType === CourseType.OnLine,
            [styles.inclass]: courseType === CourseType.InClass || courseType === CourseType.Link,
            [styles.package]: courseType === CourseType.Package,
            [styles.live]: courseType === CourseType.Live,
          })}
        >
          {courseType === CourseType.OnLine && '線上課程'}
          {courseType === CourseType.InClass && '實體課程'}
          {courseType === CourseType.Link && '導流課程'}
          {courseType === CourseType.Package && '組合包'}
          {courseType === CourseType.Live && '直播課程'}
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.wrap}>
          <p className={styles.title}>{title}</p>
          <p
            className={classnames(styles.points, {
              [styles.gray]: state === 'mycourse' && type === 'finished',
            })}
          >
            {courseType !== CourseType.Link && `教育積分：${points}`}
          </p>
          {
            !purchases.some(item => item === id) &&
            (quota > 0 || courseType !== CourseType.InClass) &&
            state !== 'mycourse' &&
            <p className={styles.price}>
              <span>{discount !== null && `$${numberWithCommas(originPrice)}`}</span><br />
              ${numberWithCommas(discount !== null ? discount : originPrice)}
            </p>
          }
          {
            purchases.some(item => item === id) && state !== 'mycourse' &&
            <p className={styles.bought}>
              已{courseType === 'INCLASS' ? '報名' : '購課'}
            </p>
          }
          {
            !purchases.some(item => item === id) && quota <= 0 && courseType === CourseType.InClass &&
            <p className={styles.full}>
              已額滿
            </p>
          }
          {
            state === 'mycourse' && type === 'unfinished' && courseType === CourseType.OnLine &&
            <p className={styles.finished}>
              <span>{progress === '100%' ? '完成' : '未完成'}</span><br />
              {progress}
            </p>
          }
          {
            state === 'mycourse' && type === 'finished' &&
            <p
              className={classnames(styles.score, {
                [styles.fail]: quizStatus === QuizStatus.Done,
              })}
            >
              {score !== null && score !== '' && `${score}分`}
            </p>
          }
          {
            state !== 'mycourse' &&
            <div className={styles.starGroup}>
              {<img src={star >= 1 ? starImg : nostarImg} alt="" />}
              {<img src={star >= 2 ? starImg : nostarImg} alt="" />}
              {<img src={star >= 3 ? starImg : nostarImg} alt="" />}
              {<img src={star >= 4 ? starImg : nostarImg} alt="" />}
              {<img src={star >= 5 ? starImg : nostarImg} alt="" />}
            </div>
          }
          {
            state === 'mycourse' && type === 'unfinished' &&
            <div className={classnames(styles.progressBar, { [styles.nonProgress]: courseType !== 'ONLINE' })}>
              {
                courseType === 'ONLINE' &&
                <div className={styles.progress} style={{ width: progress }} />
              }
            </div>
          }
          {
            state === 'mycourse' && (type === 'finished' || type === 'download') &&
            <p className={classnames(styles.apply, {
              [styles.success]: quizStatus === QuizStatus.Approved,
              [styles.fail]: quizStatus === QuizStatus.Fail,
            })}
            >
              {getQuizStatusName(quizStatus || '')}
            </p>
          }
          {
            state === 'mycourse' && (type === 'unfinished' || type === 'finished') &&
            <p className={classnames(styles.points, { [styles.gray]: isCreditApplyOverDate })}>
              {renderCreditApplyLimitText()}
            </p>
          }
        </div>
      </div>
      <div
        className={classnames(styles.hoverCard, {
          [styles.mobileshow]: type === 'unfinished',
        })}
        role="presentation"
        title={title}
      >
        {
          !purchases.some(item => item === id) &&
          <div
            className={styles.likeBtn}
            onClick={onAddToWishClick}
            title="加入願望清單"
          >
            <img src={wishList.some(item => item === id) ? likeClickIcon : likeDefaultIcon} alt="" />
          </div>
        }
        <p>{title}</p>
        {
          state === 'mycourse' && (type === 'unfinished' || type === 'finished') &&
          <div className={styles.btnWrap}>
            <MainButton
              className={styles.button}
              color="orange"
              height="40px"
            >
              查看課程
            </MainButton>
          </div>
        }
        {
          state === 'mycourse' && type === 'download' &&
          <div className={styles.btnWrap}>
            <MainButton
              className={styles.button}
              color="orange"
              height="40px"
            >
              下載證書
            </MainButton>
          </div>
        }
      </div>
    </div>
  );
};

export default CourseCardView;
