exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".styles__tab___1hBDO{margin-bottom:2em}.styles__tab___1hBDO>.styles__button___3eeem{background-color:transparent;color:#3e3a39;font-size:24px;border:none;margin:0 15px;padding:15px 0;box-sizing:border-box;font-weight:500}.styles__tab___1hBDO>.styles__button___3eeem:hover{color:#3caa8c}.styles__tab___1hBDO>.styles__selected___1ywpB{border-top:4px solid transparent;border-bottom:4px solid #3caa8c;color:#3caa8c}@media (max-width:719px){.styles__tab___1hBDO{margin-bottom:2em;display:-ms-flexbox;display:flex}.styles__tab___1hBDO>.styles__button___3eeem{font-size:18px;-ms-flex:1;flex:1;margin:0 10px;padding:10px 0}.styles__tab___1hBDO>.styles__button___3eeem:hover{color:#3caa8c}.styles__tab___1hBDO>.styles__selected___1ywpB{border-bottom:4px solid #3caa8c;color:#3caa8c}}", ""]);

// exports
exports.locals = {
	"tab": "styles__tab___1hBDO",
	"button": "styles__button___3eeem",
	"selected": "styles__selected___1ywpB"
};