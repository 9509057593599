exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".styles__icon___3sngd{position:relative;height:64px;width:64px;border-radius:50%;background:linear-gradient(0deg,#5ac864,#3caa8c)}.styles__icon___3sngd img{position:absolute;top:0;bottom:0;right:0;left:0;margin:auto;width:32px}.styles__icon___3sngd span{position:absolute;left:0;right:0;top:42%;margin:auto;text-align:center;color:#fff;font-size:18px;font-family:Calibri,Microsoft JhengHei,NotoSansCJKtc-Bold,sans-serif;height:1em;line-height:1em}.styles__icon___3sngd .styles__dot___1Y4KG{position:absolute;top:4px;right:4px;width:10px;height:10px;border-radius:50%;background-color:red;display:none}.styles__icon___3sngd .styles__dot___1Y4KG.styles__show___1W6ZP{display:block}", ""]);

// exports
exports.locals = {
	"icon": "styles__icon___3sngd",
	"dot": "styles__dot___1Y4KG",
	"show": "styles__show___1W6ZP"
};