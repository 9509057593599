/**
 * FeaturePath: 會員系統-其它-工具
 * Accountable: AlexCH Cheng, Landy Chu
 */

const _ALPHABETS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
const isNumberReg = /^[0-9]+$/;

function _derivePersonalIdChecksum(value = '') {
  const _NUMBERS = '0123456789'.split('');
  const _SPECIAL_ALPHABETS = 'WZIO'.split('');
  const _RELOCATED_ALPHABETS = _ALPHABETS.filter(
    (char: string) => !_SPECIAL_ALPHABETS.includes(char)).concat(_SPECIAL_ALPHABETS);

  const _PERSONAL_ID_LETTER_VALUE_MAP: any = {};

  _RELOCATED_ALPHABETS.forEach((char, index) => {
    _PERSONAL_ID_LETTER_VALUE_MAP[char] = index + 10;
  });

  _NUMBERS.forEach((char, index) => {
    _PERSONAL_ID_LETTER_VALUE_MAP[char] = index;
  });

  const dividend = 10;

  if (value.length !== 9) {
    return null;
  }

  let intValue = 0;
  let sum = 0;
  const chars = value.split('');

  intValue = _PERSONAL_ID_LETTER_VALUE_MAP[value.charAt(0)] as number;
  sum += Math.floor(intValue / dividend) % dividend;

  const chekcSum = chars.reduce((acc, char, index) => {
    const fold = 9 - index;
    intValue = _PERSONAL_ID_LETTER_VALUE_MAP[char] as number;
    return acc + ((intValue % dividend) * fold) % dividend;
  }, sum);

  const remainder = chekcSum % dividend;
  return (10 - remainder) % dividend;
}

export function isResidentialId(val: string): boolean {
  // 新第二碼格式 男9女8
  const foreignerGenderCodes = ['8', '9'];

  // 舊第二碼格式 中港澳地區人民： 男：A / 女：B 外國人： 男：C / 女：D
  const previousForeignerGenderCodes = ['A', 'B', 'C', 'D'];
  const ResidentialId = val.toUpperCase();

  if (typeof ResidentialId !== 'string') {
    return false;
  }

  if (ResidentialId.length !== 10) {
    return false;
  }

  if (!_ALPHABETS.includes(ResidentialId.charAt(0))) {
    return false;
  }

  if (!isNumberReg.test(ResidentialId.substring(2))) {
    return false;
  }

  const genderCode = ResidentialId.charAt(1);
  if (!foreignerGenderCodes.includes(genderCode) && !previousForeignerGenderCodes.includes(genderCode)) {
    return false;
  }
  const checksum = parseInt(ResidentialId.charAt(9), 10);
  return _derivePersonalIdChecksum(ResidentialId.substr(0, 9)) === checksum;
}

export function isPhoneNumber(val: string): boolean {
  return /^09\d{8}$/.test(val);
}

export function isIlearnAccountFormat(val: string): boolean {
  if (isPhoneNumber(val)) {
    return true;
  }

  if (isResidentialId(val)) {
    return true;
  }

  return false;
}
