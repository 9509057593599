/**
 * FeaturePath: 會員系統-其它-條款
 * Accountable: Landy Chu, AlexCH Cheng
 */

import React, { lazy, Suspense } from 'react';

import LoadingDimmer from '../components/Utils/LoadingDimmer';

const PrivacyPolicy = lazy(() => import('../components/Pages/PrivacyPolicy'));

export const PrivacyPolicyPage = props => (
  <Suspense fallback={LoadingDimmer()}>
    <PrivacyPolicy {...props} />
  </Suspense>
);
