/**
 * FeaturePath: 會員系統-其它-工具
 * Accountable: AlexCH Cheng, Landy Chu
 */

import React from 'react';
import classnames from 'classnames';

import styles from './styles.css';

const Tab = ({
  className,
  tabs,
  selected,
  onClick = () => {},
}) => {
  const renderTabs = tabs.map((tab, index) => {
    const selectedClass = selected === index ? styles.selected : '';

    return (
      <button
        key={tab}
        className={classnames(styles.button, selectedClass)}
        onClick={() => onClick(index)}
      >
        {tab}
      </button>
    );
  });

  return (
    <div className={classnames(className, styles.tab)}>
      {renderTabs}
    </div>
  );
};

export default Tab;
