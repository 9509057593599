exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".styles__error___1f3p2{color:#ff4c4c;padding:.5em 1em;font-size:1em}.styles__directions___26bfN{margin-top:8px;font-weight:400}.styles__account-row___2H-zE{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:justify;justify-content:space-between}.styles__account-row___2H-zE>button{width:120px}.styles__verify-code-row___3YtBw{display:-ms-flexbox;display:flex;-ms-flex-pack:justify;justify-content:space-between;-ms-flex-align:end;align-items:flex-end}.styles__verify-code-row___3YtBw>div{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row}.styles__verify-code-row___3YtBw>div>p{color:#999;font-weight:500}.styles__button___17F8T{width:180px}.styles__second-button___XhE4f{width:150px;margin:0 8px;background-color:#6c757d}.styles__second-button___XhE4f:hover{background-color:#5a6268}.styles__accountRow___3HVnA{width:100%}@media (max-width:719px){.styles__account-row___2H-zE{-ms-flex-align:end;align-items:flex-end}.styles__account-row___2H-zE,.styles__verify-code-row___3YtBw>div{-ms-flex-direction:column;flex-direction:column}.styles__button___17F8T{width:100px;margin:0 2px}.styles__buttonTrans___3R-3M{width:160px;margin:0 2px}.styles__second-button___XhE4f{width:100px;margin:0 2px}}", ""]);

// exports
exports.locals = {
	"error": "styles__error___1f3p2",
	"directions": "styles__directions___26bfN",
	"account-row": "styles__account-row___2H-zE",
	"accountRow": "styles__account-row___2H-zE",
	"verify-code-row": "styles__verify-code-row___3YtBw",
	"verifyCodeRow": "styles__verify-code-row___3YtBw",
	"button": "styles__button___17F8T",
	"second-button": "styles__second-button___XhE4f",
	"secondButton": "styles__second-button___XhE4f",
	"accountRow": "styles__accountRow___3HVnA",
	"buttonTrans": "styles__buttonTrans___3R-3M"
};