exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".styles__navi-icon___1IZxq{position:relative;height:40px;width:40px;border-radius:50%;background:hsla(0,0%,100%,.2)}.styles__navi-icon___1IZxq:hover{background:hsla(0,0%,100%,.5)}.styles__navi-icon___1IZxq img{position:absolute;top:0;bottom:0;right:0;left:0;margin:auto;width:20px}.styles__navi-icon___1IZxq img.styles__cart___2hb0W{width:25px}.styles__navi-icon___1IZxq span{position:absolute;left:0;right:0;top:42%;margin:auto;text-align:center;color:#fff;font-size:18px;line-height:18px;font-family:NotoSansCJKtc-Bold,sans-serif}.styles__navi-icon___1IZxq .styles__dot___1wREU{position:absolute;top:2px;right:2px;width:8px;height:8px;border-radius:50%;background-color:red;display:none}.styles__navi-icon___1IZxq .styles__dot___1wREU.styles__show___OmEGC{display:block}", ""]);

// exports
exports.locals = {
	"navi-icon": "styles__navi-icon___1IZxq",
	"naviIcon": "styles__navi-icon___1IZxq",
	"cart": "styles__cart___2hb0W",
	"dot": "styles__dot___1wREU",
	"show": "styles__show___OmEGC"
};