/**
 * FeaturePath: 會員系統-個人資訊-證照登錄
 * Accountable: Landy Chu, AlexCH Cheng
 */

// ----- import npm modules -----
import objectAssign from 'object.assign';
import moment from 'moment';

// ----- import local modules -----
import {
  SET_TARGET,
  SET_LIST,
  SET_LICENSE_UNIT,
  SET_LICENSE_IDCLASS,
  SET_IMAGE_LINK,
  SET_LICENSE_CONFIG,
  LICENSE_EXPIRING_YEAR,
} from 'react/constants/license';
import { LICENSE_STATUS } from 'react/constants/enum';

// Object.assign is not yet fully supported in all browsers, so we fallback to
// a polyfill
const assign = Object.assign || objectAssign;

// The initial application state
const initialState = {
  list: [],
  target: {
    _id: '',
    name: '',
    idClass: '',
    obtainDate: '',
    validStart: '',
    validEnd: '',
    isNoLimit: true,
    number: '',
    organization: '',
    note: '',
    copy: '',
    userId: '',
    createdAt: '',
    modifiedAt: '',
  },
  licenseUnit: [],
  licenseIdClass: [],
  link: '',
  licenseConfig: [],
  licenseValidEnd: null, // 證照有效期最晚的日期
  isLicenseExpiringSoon: false, // 證照是否即將到期(小於一年)
};

// Takes care of changing the application state
export function license(state = initialState, action) {
  switch (action.type) {
    case SET_TARGET:
      return assign({}, state, {
        target: action.license,
      });
    case SET_LIST:
    {
      // 證照列表
      const list = Array.isArray(action.licenses) ? action.licenses : [];
      // 證照有效期最晚的日期
      const licenseValidEnd = list.reduce((acc, item) => {
        if (item.status !== LICENSE_STATUS.Approved) {
          return acc;
        }

        if (acc && item.validEnd) {
          return moment(item.validEnd).diff(moment(acc)) > 0 ? item.validEnd : acc;
        }

        return item.validEnd || acc;
      }, null);
      // 證照是否即將到期(小於一年)
      const isLicenseExpiringSoon = licenseValidEnd
        ? moment(licenseValidEnd).startOf('day').diff(moment().startOf('day'), 'years') < LICENSE_EXPIRING_YEAR : false;
      return assign({}, state, {
        list,
        licenseValidEnd,
        isLicenseExpiringSoon,
      });
    }
    case SET_LICENSE_UNIT:
      return assign({}, state, {
        licenseUnit: action.licenseUnit,
      });
    case SET_LICENSE_IDCLASS:
      return assign({}, state, {
        licenseIdClass: action.licenseIdClass,
      });
    case SET_IMAGE_LINK:
      return assign({}, state, {
        link: action.link,
      });
    case SET_LICENSE_CONFIG:
      return assign({}, state, {
        licenseConfig: action.data,
      });
    default:
      return state;
  }
}
