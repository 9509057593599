/**
 * FeaturePath: 會員系統-課程-課程清單
 * Accountable: Tom Jhuang, Landy Chu
 */

export enum CourseType {
  OnLine = 'ONLINE',
  InClass = 'INCLASS',
  Link = 'LINK',
  Package = 'PACKAGE',
  Live = 'LIVE',
}

export enum CourseTypeName {
  ONLINE = '線上課程',
  INCLASS = '實體課程',
  LINK = 'LINK',
  PACKAGE = '組合包',
  Live = '直播課程',
}

export enum QuizStatus {
  Not = 'NOT',
  Done = 'DONE',
  Pass = 'PASS',
  Applying = 'APPLYING',
  Verifying = 'VERIFYING',
  Approved = 'APPROVED',
  Fail = 'FAIL',
}

export const QuizStatusNameMap = {
  [QuizStatus.Not]: '',
  [QuizStatus.Done]: '測驗未通過',
  [QuizStatus.Pass]: '測驗通過',
  [QuizStatus.Applying]: '教育積分申請中',
  [QuizStatus.Verifying]: '教育積分送審中',
  [QuizStatus.Approved]: '教育積分申請通過',
  [QuizStatus.Fail]: '教育積分申請未通過',
};

export const QuizStatusNameForLearningTableMap = {
  [QuizStatus.Not]: '',
  [QuizStatus.Done]: '測驗未過',
  [QuizStatus.Pass]: '測驗通過',
  [QuizStatus.Applying]: '尚未提交至審查單位',
  [QuizStatus.Verifying]: '審查單位審核中',
  [QuizStatus.Approved]: '通過審核',
  [QuizStatus.Fail]: '申請未通過',
};

export function getQuizStatusName(quizStatus: string, finished?: boolean, isLearningTable?: boolean) {
  if (quizStatus === QuizStatus.Not) {
    if (typeof finished === 'boolean') {
      return finished ? '已完課' : '未完課';
    }
  }

  return isLearningTable ?
    QuizStatusNameForLearningTableMap[quizStatus as QuizStatus] : QuizStatusNameMap[quizStatus as QuizStatus];
}
