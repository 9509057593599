/**
 * FeaturePath: 會員系統-個人資訊-登入
 * Accountable: Landy Chu, AlexCH Cheng
 */

import objectAssign from 'object.assign';

import {
  SET_AUTH_STATUS,
  SET_AUTH_WISHLIST,
  SET_BANNERS,
  SET_PWD_ERROR,
  SET_LOGIN_ERROR,
  SET_REGISTER_ERROR,
  SET_RESET_PASSWORD_ERROR,
  SET_IS_GET_AUTH_STATUS,
} from 'react/constants/auth';
// Object.assign is not yet fully supported in all browsers, so we fallback to
// a polyfill
const assign = Object.assign || objectAssign;

// The initial application state
const initialState = {
  status: false,
  id: '',
  accountId: '',
  account: '',
  personalId: '',
  name: '',
  nickname: '',
  gender: '',
  birthday: '',
  phone: '',
  address: '',
  email: '',
  isLuna: false,
  eLearnIdentityType: null,
  wishList: [],
  companyName: '', // 目前這個值不會更新
  banners: [],
  pwdError: '',
  loginError: '',
  registerError: '',
  resetPasswordError: '',
  isForeign: false,
  passport: '',
  headshot: '',
  isGetAuthStatus: false, // 是否已經呼叫取得帳號資訊 API
};

// Takes care of changing the application state
export function auth(state = initialState, action) {
  switch (action.type) {
    case SET_AUTH_STATUS:
    {
      const {
        status,
        id,
        accountId,
        account,
        personalId,
        name,
        nickname,
        gender,
        birthday,
        phone,
        address,
        email,
        isLuna,
        eLearnIdentityType,
        wishList,
        isForeign,
        passport,
        headshot,
      } = action.auth;

      return assign({}, state, {
        status,
        id,
        accountId,
        account,
        personalId,
        name,
        nickname,
        gender,
        birthday,
        phone,
        address,
        email,
        isLuna,
        eLearnIdentityType,
        wishList,
        isForeign,
        passport,
        headshot,
      });
    }
    case SET_AUTH_WISHLIST:
      return assign({}, state, {
        wishList: action.wishList,
      });
    case SET_BANNERS:
      return assign({}, state, {
        banners: action.banner,
      });
    case SET_PWD_ERROR:
      return assign({}, state, {
        pwdError: action.message,
      });
    case SET_LOGIN_ERROR:
      return assign({}, state, {
        loginError: action.message,
      });
    case SET_REGISTER_ERROR:
      return assign({}, state, {
        registerError: action.message,
      });
    case SET_RESET_PASSWORD_ERROR:
      return assign({}, state, {
        resetPasswordError: action.message,
      });
    case SET_IS_GET_AUTH_STATUS:
      return assign({}, state, {
        isGetAuthStatus: action.value,
      });
    default:
      return state;
  }
}
