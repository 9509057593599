/**
 * FeaturePath: 會員系統-個人資訊-我的商品
 * Accountable: AlexCH Cheng, Landy Chu
 */

import React, { lazy, Suspense } from 'react';

import LoadingDimmer from '../components/Utils/LoadingDimmer';

const MyProducts = lazy(() => import('../components/Pages/MyProducts'));

export const MyProductsPage = props => (
  <Suspense fallback={LoadingDimmer()}>
    <MyProducts {...props} />
  </Suspense>
);
