/**
 * FeaturePath: 會員系統-其它-抽獎
 * Accountable: AlexCH Cheng, Landy Chu
 */

import { authBodyFetch, easyFetch, errorProcessing } from './endpoint';

const activitiesRequest = {
  async luckyDraw(drawRecordId) {
    return easyFetch(`/api/lucky_draws/${drawRecordId}`, 'PATCH')
      .then(({ isWinning, prize }) => Promise.resolve({ success: true, isWinning, prize }))
      .catch(errorProcessing);
  },
  async luckyDrawByCourse(serial) {
    return authBodyFetch('/api/lucky_draws', 'POST', { serial })
      .then(data => Promise.resolve({ ...data, success: true }))
      .catch(errorProcessing);
  },
  async drawActivities(drawRecordId) {
    return easyFetch(`/api/draw_activities/${drawRecordId}`, 'GET')
      .then(({ isValidActivity }) => Promise.resolve({ success: true, isValidActivity }))
      .catch(errorProcessing);
  },
  async giveUpDraw(drawRecordId) {
    return easyFetch(`/api/draw-records/${drawRecordId}`, 'PATCH')
      .then(() => Promise.resolve({ success: true }))
      .catch(errorProcessing);
  },
  async unDrawList() {
    return easyFetch('/api/draw-records', 'GET')
      .then(({ list }) => Promise.resolve({ success: true, list }))
      .catch(errorProcessing);
  },
};

export default activitiesRequest;
