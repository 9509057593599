exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".styles__modal___35m3R{position:relative;top:50%;margin:auto;transform:translateY(-50%);width:100vw;padding:0 15px}.styles__modal___35m3R>.styles__wrapper___OIzoI{width:30%;min-width:350px;max-height:90vh;margin:auto;padding:20px 0;background:#fff;border-radius:10px;overflow-x:hidden;overflow-y:auto;display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;text-align:center}.styles__modal___35m3R>.styles__wrapper___OIzoI>.styles__content___26VVJ{word-wrap:break-word;max-width:80%;font-size:28px;line-height:normal;margin:8px auto}.styles__modal___35m3R>.styles__wrapper___OIzoI>.styles__content___26VVJ>.styles__foreign___-1xjh{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;font-size:26px}.styles__modal___35m3R>.styles__wrapper___OIzoI>.styles__content___26VVJ>.styles__foreign___-1xjh>a{font-size:24px}.styles__modal___35m3R>.styles__wrapper___OIzoI>.styles__content___26VVJ>.styles__foreign___-1xjh :first-child{font-weight:400}.styles__modal___35m3R>.styles__wrapper___OIzoI>.styles__content___26VVJ>.styles__foreign___-1xjh :nth-child(3){font-size:16px}.styles__modal___35m3R>.styles__wrapper___OIzoI>.styles__button___3xHwh{width:150px;margin:8px auto}@media (max-width:719px){.styles__modal___35m3R>.styles__wrapper___OIzoI>.styles__content___26VVJ{max-width:85%;font-size:26px}}", ""]);

// exports
exports.locals = {
	"modal": "styles__modal___35m3R",
	"wrapper": "styles__wrapper___OIzoI",
	"content": "styles__content___26VVJ",
	"foreign": "styles__foreign___-1xjh",
	"button": "styles__button___3xHwh"
};