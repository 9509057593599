/**
 * FeaturePath: 會員系統-個人資訊-證照登錄
 * Accountable: AlexCH Cheng, Landy Chu
 */
import auth from '../utils/auth';

import { easyFetch, bodyFetch, uploadFetch, errorProcessing } from './endpoint';

const ROOT_NAME = 'license';

const licenseRequest = {
  async fetchLicenseList(query) {
    return easyFetch(`/api/${ROOT_NAME}/user`, 'GET', query)
      .then(({ list, meta }) => Promise.resolve({ success: true, list, meta }))
      .catch(errorProcessing);
  },
  async createLicense(postData) {
    return bodyFetch(`/api/${ROOT_NAME}/create`, 'POST', postData)
      .then(data => Promise.resolve({ success: true, data }))
      .catch(auth.errorProcessing);
  },
  async updateLicense(id, postData) {
    return bodyFetch(`/api/${ROOT_NAME}/update/${id}`, 'PUT', postData)
      .then(data => Promise.resolve({ success: true, data }))
      .catch(auth.errorProcessing);
  },
  async fetchLicenseUnit() {
    return easyFetch('/api/admin/licenseunit/find', 'GET')
      .then(({ list }) => Promise.resolve({ success: true, list }))
      .catch(errorProcessing);
  },
  async fetchLicenseIdClass() {
    return easyFetch(`/api/${ROOT_NAME}/idclass`, 'GET')
      .then(({ list }) => Promise.resolve({ success: true, list }))
      .catch(errorProcessing);
  },
  async deleteLicense(id) {
    return bodyFetch(`/api/${ROOT_NAME}/del/${id}`, 'DELETE')
      .then(() => Promise.resolve({ success: true }))
      .catch(errorProcessing);
  },

  async recognizeLicense(file) {
    const { uploadImage, certType } = file;
    const formData = new FormData();

    if (file) {
      formData.append('uploadImage', uploadImage);
      formData.append('certType', certType);

      return uploadFetch(`/api/${ROOT_NAME}/recognize`, formData)
        .then(data => Promise.resolve({ success: true, data }))
        .catch(err => {
          if (err.status !== 200) {
            const { message } = err;
            return Promise.resolve({ err: {
              message,
            } });
          }
          const resType = { type: 'failed' };
          return Promise.resolve({ success: false, errType: resType });
        });
    }

    return Promise.resolve({
      success: false,
      status: null,
    });
  },

  async fetchLicenseConfig(query) {
    return easyFetch(`/api/${ROOT_NAME}/configs`, 'GET', query)
      .then(data => Promise.resolve({ success: true, data }))
      .catch(errorProcessing);
  },
};

export default licenseRequest;
