/**
 * FeaturePath: 會員系統-其它-抽獎
 * Accountable: AlexCH Cheng, Landy Chu
 */

import {
  SET_DRAW_ACTIVITIES,
  SET_UNDRAW_LIST,
  SET_CLOSED_UNDRAW_LIST,
  SET_LUCKY_DRAW_PRIZE,
} from '../constants/activities';

import { log } from '../utils/utils';

import activitiesReq from '../request/activities';

export function setDrawActivities(data) {
  return { type: SET_DRAW_ACTIVITIES, data };
}

export function setUnDrawList(data) {
  return { type: SET_UNDRAW_LIST, data };
}

export function setClosedUnDrawList(data) {
  return { type: SET_CLOSED_UNDRAW_LIST, data };
}

export function setLuckyDrawPrize(data) {
  return { type: SET_LUCKY_DRAW_PRIZE, data };
}

export function getLuckDraw(drawRecordId) {
  return async dispatch => {
    try {
      const { success, isWinning, prize } = await activitiesReq.luckyDraw(drawRecordId);
      if (success) {
        dispatch(setLuckyDrawPrize({
          isWinning,
          prize,
        }));
      }
    } catch (error) {
      log('error :>> ', error);
    }

    return {};
  };
}

export function getLuckDrawByCourse(serial) {
  return async () => {
    try {
      const { success, isWinning, prize } = await activitiesReq.luckyDrawByCourse(serial);
      if (success) {
        return ({ isWinning, prize });
      }
    } catch (error) {
      log('error :>> ', error);
    }

    return null;
  };
}

export function getDrawActivities(activityCode) {
  return async dispatch => {
    try {
      const { success, isValidActivity } = await activitiesReq.drawActivities(activityCode);
      if (success) {
        dispatch(setDrawActivities(isValidActivity));
      }
    } catch (error) {
      log('error :>> ', error);
    }

    return {};
  };
}

export function giveUpDraw(drawRecordId) {
  return async () => {
    try {
      const { success } = await activitiesReq.giveUpDraw(drawRecordId);
      if (success) {
        return true;
      }
    } catch (error) {
      log('error :>> ', error);
    }

    return {};
  };
}

export function getUnDrawList() {
  return async dispatch => {
    try {
      const { success, list } = await activitiesReq.unDrawList();
      if (success) {
        dispatch(setUnDrawList(list));
      }
    } catch (error) {
      log('error :>> ', error);
    }

    return {};
  };
}

export function closeUnDrawList() {
  return async dispatch => {
    dispatch(setClosedUnDrawList(true));
  };
}
