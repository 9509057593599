/**
 * FeaturePath: 會員系統-其它-工具
 * Accountable: Landy Chu, AlexCH Cheng
 */

import React, {
  useCallback,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react';
import classnames from 'classnames';

import { validateFunction } from 'react/utils/utils';

import Button from '../../../Button/Button';

import styles from './styles.css';

const TransferAccountForm = (
  { errorMessage, onTransferClick, onRegisterClick },
  ref,
) => {
  const [isAgree, setIsAgree] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const reset = useCallback(() => {
    setIsAgree(false);
    setIsSubmitted(false);
  }, []);

  const handleSubmit = useCallback(evt => {
    evt.preventDefault();
  }, []);

  const handleIsAgreeClick = useCallback(evt => {
    evt.preventDefault();
    setIsAgree(preValue => !preValue);
  }, []);

  const handleTransferClick = useCallback(
    evt => {
      evt.preventDefault();
      setIsSubmitted(true);

      if (isAgree && validateFunction(onTransferClick)) {
        onTransferClick();
      }
    },
    [isAgree, onTransferClick],
  );

  const handleRegisterClick = useCallback(
    evt => {
      evt.preventDefault();

      if (validateFunction(onRegisterClick)) {
        onRegisterClick();
      }
    },
    [onRegisterClick],
  );

  useImperativeHandle(ref, () => ({
    reset,
  }));

  return (
    <form className="registerForm" onSubmit={handleSubmit}>
      <div className="form__note-wrapper">
        <p className="form__note form__note--failed">{errorMessage}</p>
      </div>
      <div style={{ marginTop: '4px' }}>
        <div>
          <label
            htmlFor="checkbox"
            className={classnames(styles.checkbox, {
              [styles.checked]: isAgree,
            })}
            role="presentation"
            onKeyPress={() => {}}
            onClick={handleIsAgreeClick}
          >
            <input
              type="checkbox"
              checked={isAgree}
              onChange={handleIsAgreeClick}
            />
            <p>
              我是本人，同意建立<span className={styles.word}>仁寶 i 學習</span>
              平台帳號，並同意原帳號移轉到
              <span className={styles.word}>仁寶 i 學習</span>
              帳號。若以任何虛假或冒用他人名義登陸，願自負法律責任。
            </p>
          </label>
        </div>
      </div>
      {isSubmitted && !isAgree && (
        <p className={classnames(styles.error)}>確認身份請勾選或是註冊新帳號</p>
      )}
      <div className="ui center aligned basic segment">
        <Button
          className={isAgree ? styles.secondButtonDisable : styles.secondButton}
          onClick={handleRegisterClick}
          disabled={isAgree}
        >
          註冊新會員
        </Button>
        <Button className={styles.button} onClick={handleTransferClick}>
          帳號移轉
        </Button>
      </div>
    </form>
  );
};

export default forwardRef(TransferAccountForm);
