/**
 * FeaturePath: 會員系統-個人資訊-我的獎品
 * Accountable: Landy Chu, AlexCH Cheng
 */

import React, { lazy, Suspense } from 'react';

import LoadingDimmer from '../components/Utils/LoadingDimmer';

const MyPrizes = lazy(() => import('../components/Pages/MyPrizes'));

export const MyPrizesPage = props => (
  <Suspense fallback={LoadingDimmer()}>
    <MyPrizes {...props} />
  </Suspense>
);
