/**
 * FeaturePath: 會員系統-其它-廣告-公告訊息
 * Accountable: AlexCH Cheng, Landy Chu
 */

// ----- import npm modules -----
import objectAssign from 'object.assign';

// ----- import local modules -----
import { SET_POPUPS } from '../constants/home';

// Object.assign is not yet fully supported in all browsers, so we fallback to
// a polyfill
const assign = Object.assign || objectAssign;

/**
 * The initial state for the home reducer
 * @type {Object} The initial state of the home reducer
 * @property {Array} popups 公告訊息列表
 */
const initialState = {
  popups: [],
};

// Takes care of changing the application state
export function home(state = initialState, action: { type: string; [key: string]: any; }) {
  switch (action.type) {
    case SET_POPUPS:
      return assign({}, state, {
        popups: action.data,
      });
    default:
      return state;
  }
}
