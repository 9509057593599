/**
 * FeaturePath: 會員系統-其它-工具
 * Accountable: Landy Chu, AlexCH Cheng
 */

import React from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';

import PopupModal from 'react/components/Utils/Modal/PopupModal';

import loadingImg from 'images/loading.svg';

import styles from './styles.css';

const LoadingDialog = ({
  isShow,
  className,
  description,
}) => (
  <PopupModal modalVisible={isShow}>
    <div className={classnames(styles.dialog, className)}>
      <div />
      <div className={styles.body}>
        <img src={loadingImg} alt="Loading" />
        <p>{description}</p>
      </div>
    </div>
  </PopupModal>
);

function mapStateToProps({
  utils: { loadingDialog: { isShow, description } },
}) {
  return {
    isShow,
    description,
  };
}

export default connect(mapStateToProps, null)(LoadingDialog);
