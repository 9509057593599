/**
 * FeaturePath: 會員系統-個人資訊-學習覆歷
 * Accountable: Landy Chu, AlexCH Cheng
 */

import objectAssign from 'object.assign';

import {
  SET_LIST,
  SET_TARGET,
  SET_HOT_LIST,
  SET_RELATED_LIST,
  SET_COMPAL_LIST,
  SET_CREDIT_LIST,
  SET_TRAINING_LIST,
  SET_MY_LIST,
  GET_LIST_META,
  SET_NAVI_LIST,
  SET_SEARCH_LIST,
  GET_SEARCH_META,
  GET_FILTER_OPTION,
  SAVE_FILTER_QUERY,
  SET_REFUND_BANK,
  SET_REFUND_INFO,
  SET_PACKAGE,
  SET_PURCHASED,
  SET_LIVE_SCRIPTS,
  GET_CALENDAR,
  RESET_TARGET,
  GET_RECOMMEND_COURSES,
  GET_LEARNING_HISTORY,
  RESET_MOBILE_LIST,
  SET_MOBILE_LIST,
} from 'react/constants/course';

import { CATEGORY_TYPE, CATEGORY_TYPE_NAME } from '../constants/enum';

function getInitialTarget() {
  return {
    banner: '',
    category: '',
    chapters: [],
    creditPoints: 0,
    discount: 0,
    duration: 0,
    idClass: [],
    introduction: '',
    isHaveQuiz: false,
    isCreditPoints: false,
    level: '',
    mbanner: '',
    name: '',
    online: false,
    onlineTime: '', // 後端沒有傳，似乎沒有用到?
    price: 0,
    qa: '',
    quiz: [],
    quizNum: 0,
    quizStatus: '',
    recommend: false, // 後端沒有傳，似乎沒有用到?
    teacher: {
      name: '',
      _id: '',
    },
    weighting: 0, // 後端沒有傳，似乎沒有用到?
    _id: '',
    calendar: [],
    recommendCourses: [],
    learningHistory: {
      labels: {},
      list: [],
    },
    creditPointEndAt: null,
  };
}

// Object.assign is not yet fully supported in all browsers, so we fallback to
// a polyfill
const assign = Object.assign || objectAssign;

// The initial application state
const initialState = {
  list: {
    list: [],
    mobileList: [],
    hot: [],
    related: [],
    campal: [],
    credit: [],
    training: [],
    meta: {
      total: '',
      lastPage: '',
    },
  },
  mylist: {
    sumup: [{ hours: 0 }, { hours: 0 }, { hours: 0 }],
    finished: [],
    unfinished: [],
    creditApplyStudies: [],
    wish: [],
  },
  navilist: [],
  searchlist: {
    list: [],
    meta: {
      total: '',
      lastPage: '',
    },
  },
  filter: [],
  categoryDataList: [],
  target: getInitialTarget(),
  status: '',
  student: 0,
  query: {
    type: '',
    category: '',
    inclass: '',
    coursetype: [],
    attr: '',
    theme: '',
    page: 1,
  },
  bank: [],
  refund: {
    status: false,
    reason: '',
    bank: '',
    branchCode: '',
    account: '',
    name: '',
    personalId: '',
    phone: '',
  },
  coursePackage: {
    name: '',
    price: 0,
    discount: 0,
    packages: [],
  },
  purchases: [],
  liveScripts: [],
  calendar: [],
  recommendCourses: [],
  learningHistory: {
    labels: {},
    list: [],
  },
  isApplyExpired: false,
};

// Takes care of changing the application state
export function course(state = initialState, action) {
  switch (action.type) {
    case SET_TARGET:
    {
      const target = action.list;
      if (target) {
        target.isHaveQuiz = Array.isArray(target.quiz) && !!target.quiz.length;
        target.isCreditPoints = target.category !== 'TRAINING' && target.creditPoints > 0;
      }
      return assign({}, state, {
        target,
        status: action.status,
        student: action.student,
        isApplyExpired: action.isApplyExpired,
      });
    }
    case SET_LIST:
      return assign({}, state, {
        list: {
          ...state.list,
          list: action.courses,
        },
      });
    case SET_HOT_LIST:
      return assign({}, state, {
        list: {
          ...state.list,
          hot: action.courses,
        },
      });
    case SET_COMPAL_LIST:
      return assign({}, state, {
        list: {
          ...state.list,
          campal: action.courses,
        },
      });
    case SET_RELATED_LIST:
      return assign({}, state, {
        list: {
          ...state.list,
          related: action.courses,
        },
      });
    case SET_CREDIT_LIST:
      return assign({}, state, {
        list: {
          ...state.list,
          credit: action.courses,
        },
      });
    case SET_TRAINING_LIST:
      return assign({}, state, {
        list: {
          ...state.list,
          training: action.courses,
        },
      });
    case SET_MY_LIST:
      return assign({}, state, {
        mylist: {
          ...state.mylist,
          sumup: action.courses.sumup,
          finished: action.courses.finished,
          unfinished: action.courses.unfinished,
          creditApplyStudies: action.courses.creditApplyStudies,
          wish: action.courses.wish,
        },
      });
    case SET_NAVI_LIST:
      return assign({}, state, {
        navilist: action.courses,
      });
    case SET_SEARCH_LIST:
      return assign({}, state, {
        searchlist: {
          ...state.searchlist,
          list: action.courses,
        },
      });
    case GET_SEARCH_META:
      return assign({}, state, {
        searchlist: {
          ...state.searchlist,
          meta: {
            ...state.list.meta,
            total: action.meta.total,
            lastPage: action.meta.lastPage,
          },
        },
      });
    case GET_LIST_META:
      return assign({}, state, {
        list: {
          ...state.list,
          meta: {
            ...state.list.meta,
            total: action.meta.total,
            lastPage: action.meta.lastPage,
          },
        },
      });
    case GET_FILTER_OPTION:
    {
      const newCategoryDataList = [];
      if (Array.isArray(action.filter)) {
        action.filter.forEach(categoryItem => {
          switch (categoryItem._id) {
            case CATEGORY_TYPE.Credit:
            case CATEGORY_TYPE.Training:
              if (Array.isArray(categoryItem.attribute)) {
                newCategoryDataList.push(
                  ...categoryItem.attribute.map(attributeItem => ({
                    id: categoryItem._id,
                    name: CATEGORY_TYPE_NAME[categoryItem._id],
                    attribute: {
                      id: attributeItem._id,
                      name: attributeItem.name,
                    },
                    themeList: Array.isArray(attributeItem.theme) ? attributeItem.theme.map(themeItem => ({
                      id: themeItem._id,
                      name: themeItem.name,
                    })) : [],
                  })));
              }
              break;
            default:
              break;
          }
        });
      }

      return assign({}, state, {
        filter: action.filter,
        categoryDataList: newCategoryDataList,
      });
    }
    case GET_CALENDAR:
      return assign({}, state, {
        calendar: action.calendar,
      });
    case SAVE_FILTER_QUERY:
      return assign({}, state, {
        query: action.query,
      });
    case SET_REFUND_BANK:
      return assign({}, state, {
        bank: action.bank,
      });
    case SET_REFUND_INFO:
      return assign({}, state, {
        refund: action.info,
      });
    case SET_PACKAGE:
      return assign({}, state, {
        coursePackage: action.course,
        status: action.status,
      });
    case SET_PURCHASED:
      return assign({}, state, {
        purchases: action.purchases,
      });
    case SET_LIVE_SCRIPTS:
      return assign({}, state, {
        liveScripts: action.list,
      });
    case RESET_TARGET:
      return assign({}, state, {
        target: getInitialTarget(),
        status: '',
        student: 0,
        isApplyExpired: false,
      });
    case GET_RECOMMEND_COURSES:
      return assign({}, state, {
        recommendCourses: action.list,
      });
    case GET_LEARNING_HISTORY:
      return assign({}, state, {
        learningHistory: {
          labels: action.labels,
          list: action.list,
        },
      });
    case RESET_MOBILE_LIST:
      return assign({}, state, {
        list: {
          ...state.list,
          list: [],
          mobileList: [],
        },
      });
    case SET_MOBILE_LIST:
      return assign({}, state, {
        list: {
          ...state.list,
          mobileList: [...state.list.mobileList, ...state.list.list],
        },
      });
    default:
      return state;
  }
}
