/**
 * FeaturePath: 不需處理
 * Accountable: Tom Jhuang, AlexCH Cheng
 */

import { combineReducers } from 'redux';

import { utils } from './utils';
import { modal } from './modal';
import { admin } from './admin';
import { course } from './course';
import { auth } from './auth';
import { license } from './license';
import { cart } from './cart';
import { coupon } from './coupon';
import { chat } from './chat';
import { activities } from './activities';
import { luckyDraws } from './luckyDraws';
import { products } from './products';
import { wishes } from './wishes';
import { article } from './article';
import { home } from './home.ts';

export default combineReducers({
  utils,
  modal,
  admin,
  course,
  auth,
  license,
  cart,
  coupon,
  chat,
  activities,
  luckyDraws,
  products,
  wishes,
  article,
  home,
});
