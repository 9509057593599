/**
 * FeaturePath: 會員系統-個人資訊-折價券
 * Accountable: Landy Chu, AlexCH Cheng
 */

import objectAssign from 'object.assign';

import {
  SET_COUPON_LIST,
  SET_COURSE_COUPON_LIST,
  SET_PRODUCT_COUPON_LIST,
  SET_ADD_COUPON_MSG,
  SET_COUPON_DETAIL,
} from 'react/constants/coupon';
// Object.assign is not yet fully supported in all browsers, so we fallback to
// a polyfill
const assign = Object.assign || objectAssign;

// The initial application state
const initialState = {
  couponList: [],
  courseCoupon: [],
  productCoupon: [],
  couponMsg: '',
  couponDetail: null,
};

// Takes care of changing the application state
export function coupon(state = initialState, action) {
  switch (action.type) {
    case SET_COUPON_LIST:
      return assign({}, state, {
        couponList: action.list,
      });
    case SET_COURSE_COUPON_LIST:
      return assign({}, state, {
        courseCoupon: action.list,
      });
    case SET_PRODUCT_COUPON_LIST:
      return assign({}, state, {
        productCoupon: action.list,
      });
    case SET_ADD_COUPON_MSG:
      return assign({}, state, {
        couponMsg: action.msg,
      });
    case SET_COUPON_DETAIL:
      return assign({}, state, {
        couponDetail: action.data,
      });
    default:
      return state;
  }
}
