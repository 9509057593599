/**
 * FeaturePath: 會員系統-其它-工具
 * Accountable: Landy Chu, AlexCH Cheng
 */

import React, { useCallback } from 'react';
import { connect } from 'react-redux';

import {
  forwardTo,
  openLoginDialog,
  openLoadingDialog,
  closeLoadingDialog,
} from 'react/actions/utils';
import { downloadCertificate } from 'react/actions/course';
import { toggleWish } from 'react/actions/auth';

import CourseCardView from './CourseCardView';

const CourseCard = ({
  className,
  id,
  imgClassName,
  img,
  type,
  title,
  star = 5,
  points = 0,
  originPrice,
  discount = '',
  state,
  progress,
  score = '',
  courseType,
  quota = 1,
  quizStatus,
  wishList,
  authStatus,
  purchases,
  onCourse,
  onWishToggle,
  downloadFile,
  onOpenLoginDialog,
  openLoading,
  closeLoading,
  creditPointEndAt,
}) => {
  const handleCourseCardClick = useCallback(async event => {
    event.preventDefault();
    if (type === 'download') {
      openLoading('系統處理中，請勿關閉或重新整理');

      try {
        await downloadFile(id);
        closeLoading();
      } catch (err) {
        closeLoading();
        alert(err.message);
      }

      return;
    }

    onCourse(id, courseType);
  }, [type, openLoading, downloadFile, closeLoading, onCourse]);

  const handleAddToWishClick = useCallback(event => {
    event.stopPropagation();
    if (!authStatus) {
      onOpenLoginDialog();
      return;
    }

    onWishToggle(id);
  }, [authStatus, id]);

  return (
    <CourseCardView
      className={className}
      id={id}
      imgClassName={imgClassName}
      img={img}
      type={type}
      title={title}
      star={star}
      points={points}
      originPrice={originPrice}
      discount={discount}
      state={state}
      progress={progress}
      score={score}
      courseType={courseType}
      quota={quota}
      quizStatus={quizStatus}
      purchases={purchases}
      wishList={wishList}
      creditPointEndAt={creditPointEndAt}
      onCourseCardClick={handleCourseCardClick}
      onAddToWishClick={handleAddToWishClick}
    />
  );
};

function mapStateToProps({
  auth: {
    wishList,
    status,
  },
  course: {
    purchases,
  },
}) {
  return {
    wishList,
    authStatus: status,
    purchases,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onCourse: (id, type) => {
      switch (type) {
        case 'PACKAGE':
          dispatch(forwardTo(`/package/${id}`));
          break;
        default:
          dispatch(forwardTo(`/course/${id}`));
      }
    },
    onWishToggle: id => dispatch(toggleWish(id)),
    downloadFile: courseId => dispatch(downloadCertificate(courseId)),
    onOpenLoginDialog: () => dispatch(openLoginDialog()),
    openLoading: description => dispatch(openLoadingDialog(description)),
    closeLoading: () => dispatch(closeLoadingDialog()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CourseCard);
