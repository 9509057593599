/**
 * FeaturePath: 會員系統-其它-活動頁面
 * Accountable: Landy Chu, AlexCH Cheng
 */

import objectAssign from 'object.assign';

import {
  SET_DRAW_ACTIVITIES,
  SET_UNDRAW_LIST,
  SET_CLOSED_UNDRAW_LIST,
  SET_LUCKY_DRAW_PRIZE,
} from 'react/constants/activities';

// Object.assign is not yet fully supported in all browsers, so we fallback to
// a polyfill
const assign = Object.assign || objectAssign;

// The initial application state
const initialState = {
  drawActivities: false,
  undrawList: [],
  isClosedUndrawList: false,
  luckyDrawPrize: {},
};

// Takes care of changing the application state
export function activities(state = initialState, action) {
  switch (action.type) {
    case SET_DRAW_ACTIVITIES:
      return assign({}, state, {
        drawActivities: action.data,
      });
    case SET_UNDRAW_LIST:
      return assign({}, state, {
        undrawList: action.data,
      });
    case SET_CLOSED_UNDRAW_LIST:
      return assign({}, state, {
        isClosedUndrawList: true,
      });
    case SET_LUCKY_DRAW_PRIZE:
      return assign({}, state, {
        luckyDrawPrize: action.data,
      });
    default:
      return state;
  }
}
