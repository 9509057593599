/**
 * FeaturePath: 會員系統-其它-抽獎
 * Accountable: Landy Chu, AlexCH Cheng
 */

import objectAssign from 'object.assign';

import {
  SET_LUCKY_DRAWS_LIST,
} from 'react/constants/luckyDraws';
// Object.assign is not yet fully supported in all browsers, so we fallback to
// a polyfill
const assign = Object.assign || objectAssign;

// The initial application state
const initialState = {
  luckyDrawsList: [],
};

// Takes care of changing the application state
export function luckyDraws(state = initialState, action) {
  switch (action.type) {
    case SET_LUCKY_DRAWS_LIST:
      return assign({}, state, {
        luckyDrawsList: action.list,
      });
    default:
      return state;
  }
}
