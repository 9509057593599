/**
 * FeaturePath: 學員管理-資料管理-抽獎-抽獎
 * Accountable: Landy Chu, AlexCH Cheng
 */

/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import * as R from 'ramda';

import { closeLoginDialog } from 'react/actions/utils';

import DrawResultComponent from './components/DrawResultComponent';
import { luckyDrawPrizeType } from '../../../constants/activities';


const LuckyDrawModal = ({
  onCloseLoginDialog,
  onClose,
  luckyDrawPrize,
}) => {
  const [isShowResultModal, setIsShowResultModal] = useState(false);

  useEffect(() => {
    setIsShowResultModal(!R.isEmpty(luckyDrawPrize));
  }, [luckyDrawPrize]);

  const handelClickConfirmResult = () => {
    const prizeType = R.pathOr(1, ['prize', 'type'], luckyDrawPrize);
    const redirectPath = prizeType === luckyDrawPrizeType.thankYou ? '/' : '/myprizes';

    setIsShowResultModal(false);
    onCloseLoginDialog();
    onClose();

    window.location.href = (redirectPath);
  };

  return (
    <React.Fragment>
      <DrawResultComponent
        prize={luckyDrawPrize}
        isShowModal={isShowResultModal}
        onClickConfirmResult={handelClickConfirmResult}
      />
    </React.Fragment>
  );
};

const mapStateToProps = ({
  activities: {
    luckyDrawPrize,
  },
}) => ({
  luckyDrawPrize,
});


const mapDispatchToProps = dispatch => ({
  onCloseLoginDialog: () => dispatch(closeLoginDialog()),
});


export default connect(mapStateToProps, mapDispatchToProps)(LuckyDrawModal);
