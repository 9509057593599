/**
 * FeaturePath: 會員系統-其它-工具
 * Accountable: AlexCH Cheng, Landy Chu
 */

/* eslint-disable no-plusplus */
/* eslint-disable camelcase */
import JsPDF from 'jspdf';

const DEFAULT_WATERMARK = '仁寶電腦版權所有，翻印必究';
const INTERVAL_CONTENT = ' ';
const FONT_URL = 'https://storage.googleapis.com/ilearn_sys_data/FE_fonts/msjh.ttf';
const FONT_NAME = 'mjs.ttf';
const FILE_NAME = 'handout.pdf';
const FONT_SIZE = 20;
const TEXT_COLOR = '#FF4C4C';
const IMAGE_FORMAT = 'PNG';

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

async function getObjectUrl(url) {
  const result = await fetch(url);
  const resultBlog = await result.blob();

  return URL.createObjectURL(resultBlog);
}

export async function generateHandoutWithWatermark(
  data,
  fileName = FILE_NAME,
  onProgress,
) {
  const handleProgress = value => {
    if (onProgress) {
      onProgress(value);
    }
  };

  // Step1: 下載字型檔 - 20%
  // Step2: 處理每張圖浮水印 - 90%
  // Step3: 完成 - 100%

  handleProgress(5);

  // 取得字型檔
  const response = await fetch(FONT_URL);
  if (!response.ok) {
    return false;
  }

  handleProgress(10);

  const blob = await response.blob();
  const font = await getBase64(blob)
    .then(value => value.split(',')[1]);

  handleProgress(20);

  const doc = new JsPDF({
    orientation: 'l',
    unit: 'px',
    format: 'a4',
  });

  // 設定中文字型、大小
  doc.addFileToVFS(FONT_NAME, font);
  doc.addFont(FONT_NAME, 'font', 'normal');

  doc.setFont('font');
  doc.setFontSize(FONT_SIZE);
  doc.setTextColor(TEXT_COLOR);

  const { images, textConfig } = data || {};
  if (!Array.isArray(images) || images.length === 0) {
    return false;
  }

  const {
    content = DEFAULT_WATERMARK,
    margin_x = 10,
    margin_y = 10,
    position_x = 0,
    position_y = 0,
    rotation = 45,
    transparency = 0.5,
  } = Array.isArray(textConfig) ? textConfig[0] || {} : {};
  // width :>>  631.4175
  // height :>>  446.46
  const width = doc.internal.pageSize.getWidth();
  const height = doc.internal.pageSize.getHeight();

  const intervalContentList = [INTERVAL_CONTENT];
  let intervalWidth = doc.getTextWidth(INTERVAL_CONTENT);
  while (intervalWidth < margin_x) {
    intervalContentList.push(INTERVAL_CONTENT);
    intervalWidth = doc.getTextWidth(intervalContentList.join(''));
  }
  const intervalContent = intervalContentList.join('');

  const { w: textWidth, h: textHeight } = doc.getTextDimensions(content);
  const count = Math.trunc((width * 2) / textWidth);
  const contentList = [];
  for (let index = 0; index < count; index++) {
    if (index > 0) {
      contentList.push(intervalContent);
    }
    contentList.push(content);
  }
  const textContent = contentList.join('');

  // 準備產生 pdf
  for (let index = 0; index < images.length; index++) {
    const imageUrl = images[index];
    if (index > 0) {
      doc.addPage('a4', 'l');
    }

    // eslint-disable-next-line no-await-in-loop
    const objectUrl = await getObjectUrl(imageUrl);

    doc.addImage(objectUrl, IMAGE_FORMAT, 0, 45.5, 631, 355);
    doc.saveGraphicsState();
    doc.setGState(doc.GState({ opacity: transparency }));

    let top = position_y;
    let textCount = 0;
    while (top < height * 2.5) {
      doc.text(
        textCount % 2 === 0 ? textContent : `${intervalContent}${textContent}`,
        position_x,
        top,
        {
          rotationDirection: 1,
          angle: rotation,
        },
      );

      top = top + textHeight + margin_y;
      textCount++;
    }

    doc.restoreGraphicsState();

    handleProgress(20 + Math.round(70 / images.length * (index + 1)));
  }
  handleProgress(95);

  doc.save(fileName);

  handleProgress(100);
  return true;
}
