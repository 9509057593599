/**
 * FeaturePath: 會員系統-其它-工具
 * Accountable: Landy Chu, AlexCH Cheng
 */

import * as R from 'ramda';
import { ENV } from '../../config';

const PERSONAL_ID_LETTER_VALUE_MAP = new Map([
  ...'ABCDEFGHJKLMNPQRSTUVXYWZIO'.split('').map((char, index) => [char, index + 10]),
  ...'0123456789'.split('').map((char, index) => [char, index]),
]);

const CHECK_ON_LINE_PROD_URL = 'https://storage.googleapis.com/ilearn_sys_data/FE_image/marcom/20211202134354_ccf334fa-f3a9-4636-ae27-79bc6d32f6c4..png';
const CHECK_ON_LINE_URL = 'https://upload.wikimedia.org/wikipedia/commons/c/ca/1x1.png';

function derivePersonalIdChecksum(value = '') {
  if (value.length !== 9) {
    return null;
  }

  const dividend = 10;

  let intValue = 0;
  let sum = 0;
  intValue = PERSONAL_ID_LETTER_VALUE_MAP.get(value.charAt(0));
  sum += Math.floor(intValue / dividend) % dividend;
  // eslint-disable-next-line no-restricted-syntax
  for (const [index, char] of value.split('').entries()) {
    const fold = 9 - index;
    intValue = PERSONAL_ID_LETTER_VALUE_MAP.get(char);
    sum += ((intValue % dividend) * fold) % dividend;
  }

  const remainder = sum % dividend;
  return (10 - remainder) % dividend;
}

export const validateFunction = value => typeof value === 'function';

export function log(message, ...optionalParams) {
  if (ENV !== 'production') {
    // eslint-disable-next-line no-console
    console.log(message, ...optionalParams);
  }
}

export function isNumber(value) {
  return typeof value === 'number';
}

export function validateChineseName(value) {
  if (value) {
    return /^[ •\u3400-\u9fa5]+$/g.test(value);
  }

  return false;
}

export function validateChineseEnglishName(value) {
  if (value) {
    return /^[a-zA-Z •\u3400-\u9fa5]+$/g.test(value);
  }

  return false;
}

export function validateName(value) {
  if (value) {
    return !/[a-zA-Z0-9`\-=[\]\\;',./~!@#$%^&*()_+{}|:"<>?\s]+/g.test(value);
  }

  return false;
}

export function validatePersonalId(value, isForeign) {
  if (value) {
    const newValue = value.toUpperCase();
    // 特殊外籍居留證號格式(1碼英文+7碼數字)，此格式不檢查規則
    if (isForeign) {
      if (/^[A-Z]\d{7}$/g.test(newValue)) {
        return true;
      }
    }

    if (/^[A-Z][1289A-D]\d{8}$/g.test(newValue)) {
      const checksum = parseInt(newValue.charAt(9), 10);
      return derivePersonalIdChecksum(newValue.substr(0, 9)) === checksum;
    }
  }

  return false;
}

export function validateMobilePhone(value) {
  return /^09\d{8}$/g.test(value);
}

export function validateMail(value) {
  return /^[^@]+@[^@]+$/g.test(value);
}

export function sleep(time) {
  return new Promise(resolve => window.setTimeout(resolve, time));
}

export function getRandom(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export async function checkOnlineStatus() {
  try {
    const url = (ENV === 'production') ? CHECK_ON_LINE_PROD_URL : CHECK_ON_LINE_URL;
    const resp = await fetch(url);
    return resp.status >= 200 && resp.status < 300;
  } catch (err) {
    log('err :>> ', err);
  }

  return false;
}

export function addQueryStringToSurveycake(content, queryString) {
  if (content && queryString) {
    const newQueryString = queryString.replace(/^\?/g, '');
    return content
      .replace(/(\shref="https?:\/\/www\.surveycake\.com[^"?]+)(?:\?([^"]+))?/ig, `$1?$2&${newQueryString}`)
      .replace(/(\shref="https?:\/\/www\.surveycake\.com[^"?]+)\?&([^"]+)/ig, '$1?$2');
  }

  return content;
}

export function convertMarkdownToHtml(content) {
  if (content) {
    return content
      // eslint-disable-next-line no-useless-escape
      .replace(/\[([^\[\]]+)\]\(([^\(\)]+)\)/g, '<a href="$2">$1</a>')
      .replace(/\n/g, '<br />');
  }

  return content;
}

export function toHalf(str) {
  if (str && /[\uFF01-\uFF5E]/g.test(str)) {
    const tempList = [];
    for (let index = 0; index < str.length; index += 1) {
      const charCode = str.charCodeAt(index);
      if (charCode >= 65281 && charCode <= 65554) {
        tempList.push(String.fromCharCode(charCode - 65248));
      } else {
        tempList.push(str[index]);
      }
    }
    return tempList.join('');
  }

  return str;
}

export function add(num1, num2) {
  const tempNumber1 = R.is(Number, num1) ? num1 : 0;
  const tempNumber2 = R.is(Number, num2) ? num2 : 0;
  const num1Digits = (tempNumber1.toString().split('.')[1] || '').length;
  const num2Digits = (tempNumber2.toString().split('.')[1] || '').length;
  // eslint-disable-next-line no-restricted-properties
  const baseNum = Math.pow(10, Math.max(num1Digits, num2Digits));
  return (tempNumber1 * baseNum + tempNumber2 * baseNum) / baseNum;
}
