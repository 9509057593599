/**
 * FeaturePath: 會員系統-其它-工具
 * Accountable: AlexCH Cheng, Landy Chu
 */

// ----- local modules -----
import { log } from './utils';

// ----- constants -----
/**
 * localStorage key
 */
const KEY = 'POPUP_READ_ANNOUNCEMENTS';

const windowsLocalStorage = window.localStorage;

/**
 * 用來儲存已讀公告的集合
 */
let readAnnouncements: Set<any> | null = null;

/**
 * 讀取
 */
function load() {
  if (!readAnnouncements) {
    const jsonString = windowsLocalStorage.getItem(KEY);
    if (jsonString) {
      try {
        readAnnouncements = new Set(JSON.parse(jsonString));
      } catch (error) {
        log('error :>> ', error);
      }
    }

    if (!readAnnouncements) {
      readAnnouncements = new Set();
    }
  }
}

/**
 * 儲存
 */
function save() {
  if (readAnnouncements) {
    windowsLocalStorage.setItem(KEY, JSON.stringify(Array.from(readAnnouncements)));
  }
}

/**
 * 清除
 */
function clear() {
  readAnnouncements = null;
  windowsLocalStorage.removeItem(KEY);
}

const popupUtil = {
  /**
   * 是否已讀公告訊息
   * @param id 公告訊息 ID
   */
  hasReadAnnouncement: (id: string) => {
    load();
    return readAnnouncements?.has(id);
  },
  /**
   * 新增已讀公告訊息
   * @param id 公告訊息 ID
   */
  addReadAnnouncement: (id: string) => {
    load();
    readAnnouncements?.add(id);
    save();
  },
  /**
   * 清除已讀公告訊息
   */
  clearReadAnnouncement: clear,
};

export default popupUtil;
