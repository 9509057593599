/**
 * FeaturePath: 會員系統-其它-工具
 * Accountable: AlexCH Cheng, Landy Chu
 */

// ----- import npm modules -----
import React, {
  useCallback, useMemo,
  ChangeEvent, MouseEvent,
  forwardRef, useImperativeHandle,
  useState, useEffect,
} from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';

// ----- import local modules -----
import { getServiceTerms, getPrivacyPolicy } from '../../../../../actions/article';
import { LINE_URL } from '../../../../../constants/website';
import { useCountdown } from '../../../../../utils/hooks';
import useFormValidation from '../hooks/useFormValidation';
import { isPhoneNumber } from '../../../../../utils/validator';
import TextMap from '../../../../../configs/Utils/Dialog/LoginDialog/InputContent.json';

import FormInput, { FormInputType } from '../../../Input/FormInput';
import Button from '../../../Button/Button';

// ----- import css -----
import styles from './styles.module.scss';

// ----- constants -----
/**
 * 預設倒數秒數
 */
const DEFAULT_COUNT = 60;
/**
 * 勾選同意服務條款標籤文字
 */
const SERVICE_TERMS_LABEL = '我已詳閱並同意服務條款';
/**
 * 勾選同意隱私權政策標籤文字
 */
const PRIVACY_POLICY_LABEL = '我已詳閱並同意隱私權政策';
/**
 * 勾選同意服務條款錯誤訊息
 */
const SERVICE_TERMS_ERROR_MESSAGE = '請捲動捲軸詳細閱讀服務條款後勾選同意';
/**
 * 勾選同意隱私權政策錯誤訊息
 */
const PRIVACY_POLICY_ERROR_MESSAGE = '請捲動捲軸詳細閱讀隱私權政策後勾選同意';

// ----- interface -----
/**
 * 輸入欄位類型
 */
enum FieldType {
  /**
   * 輸入欄位
   */
  InputField,
  /**
   * 取得驗證碼欄位
   */
  GetVerifyCodeField,
}

/**
 * 輸入內容元件 Ref
 */
interface IRef {
  /**
   * 是否有錯誤訊息
   */
  isHaveErrorMessage: boolean;
}

/**
 * 輸入內容資料介面
 */
interface IData {
  /**
   * 帳號
   */
  account?: string;
  /**
   * 驗證碼
   */
  verifyCode?: string;
  /**
   * 姓名
   */
  name?: string;
  /**
   * 電子郵件
   */
  email?: string;
  /**
   * 密碼
   */
  password?: string;
  /**
   * 確認密碼
   */
  passwordConfirm?: string;
  [x: string]: string | undefined;
}

/**
 * 表單輸入屬性介面
 */
interface FormInputProps {
  /**
   * 輸入欄位類型
   */
  type: FormInputType;
  /**
   * 圖示
   */
  icon: string;
  /**
   * 欄位名稱
   */
  name: string;
  /**
   * 欄位 id
   */
  htmlFor: string;
  /**
   * 欄位提示文字
   */
  placeholder: string;
  /**
   * 欄位標籤文字
   */
  label: string;
  /**
   * 最大長度
   */
  maxLength: number;
  /**
   * 正規表示式
   */
  pattern?: string;
  /**
   * 輸入值
   */
  value?: string;
  /**
   * 輸入事件
   * @param event HTML Input 事件
   */
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

/**
 * 輸入欄位資料介面
 */
interface IFieldDataItem {
  /**
   * 是否顯示
   */
  isShow: boolean;
  /**
   * 欄位 key
   */
  keyId: string;
  /**
   * 欄位類型
   */
  type: FieldType;
  /**
   * 表單輸入屬性
   */
  formInputProps: FormInputProps;
  /**
   * 錯誤訊息
   */
  errorMessage?: string;
}

/**
 * 條款欄位資料介面
 */
interface IArticleFieldDataItem {
  /**
   * 是否顯示
   */
  isShow?: boolean;
  /**
   * 欄位 key
   */
  keyId: string;
  /**
   * 欄位屬性
   */
  fieldProps: {
    /**
     * 欄位名稱
     */
    name?: string;
    /**
     * 欄位內容
     */
    content?: string;
    /**
     * 標籤文字
     */
    label?: string;
    /**
     * 是否已閱讀
     */
    hasRead?: boolean;
    /**
     * 是否同意
     */
    isAgreed?: boolean;
  };
  /**
   * 錯誤訊息
   */
  errorMessage?: string;
  /**
   * 捲動事件
   * @param event HTML Input 事件
   */
  onScroll?: (event: React.UIEvent<HTMLTextAreaElement>) => void;
  /**
   * 變更事件
   * @param event HTML Input 事件
   */
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

interface IProps {
  /**
   * 是否顯示錯誤訊息
   */
  isShowError?: boolean;
  /**
   * 是否為註冊頁面
   */
  isRegister?: boolean;
  /**
   * 是否為重設密碼頁面
   */
  isResetPassword?: boolean;
  /**
   * 輸入內容資料
   */
  data?: IData;
  /**
   * 隱私權政策內容
   */
  privacyPolicyContent?: string,
  /**
   * 服務條款內容
   */
  serviceTermsContent?: string,
  /**
   * 資料變更事件
   */
  onChange?: (data: IData) => void;
  /**
   * 取得驗證碼事件
   */
  onGetVerifyCodeClick?: (account: string) => Promise<boolean>;
  /**
   * 取得服務條款事件
   */
  onGetServiceTerms: () => void;
  /**
   * 取得隱私權政策事件
   */
  onGetPrivacyPolicy: () => void;
}

// ----- 輸入內容元件 -----
/**
 * 輸入內容元件
 */
const InputContent = ({
  isShowError, // 是否顯示錯誤訊息
  isRegister = false, // 是否為註冊頁面
  isResetPassword = false, // 是否為重設密碼頁面
  data, // 輸入內容資料
  privacyPolicyContent, // 隱私權政策內容
  serviceTermsContent, // 服務條款內容
  onChange, // 資料變更事件
  onGetVerifyCodeClick, // 取得驗證碼事件
  onGetServiceTerms, // 取得服務條款事件
  onGetPrivacyPolicy, // 取得隱私權政策事件
}: IProps, ref: React.Ref<IRef> | undefined) => {
  // 是否同意隱私權政策及服務條款
  const [agreedData, setAgreedData] = useState({
    privacyPolicy: false,
    serviceTerms: false,
  });
  // 是否已閱讀隱私權政策及服務條款
  const [readData, setReadData] = useState({
    privacyPolicy: false,
    serviceTerms: false,
  });

  // 倒數秒數
  const [count, start] = useCountdown(DEFAULT_COUNT);
  // 驗證資料
  const { errorData } = useFormValidation(data, isRegister);

  const {
    accountError, // 帳號錯誤訊息
    verifyCodeError, // 驗證碼錯誤訊息
    nameError, // 姓名錯誤訊息
    emailError, // 電子郵件錯誤訊息
    passwordError, // 密碼錯誤訊息
    passwordConfirmError, // 確認密碼錯誤訊息
  } = errorData;

  const {
    account, // 帳號
    verifyCode, // 驗證碼
    name, // 姓名
    email, // 電子郵件
    password, // 密碼
    passwordConfirm, // 確認密碼
  } = data || {};

  /**
   * 是否禁用取得驗證碼按鈕
   */
  const verifyCodeDisabled = useMemo(() => !!accountError, [errorData]);
  /**
   * 是否有錯誤訊息
   */
  const isHaveErrorMessage = useMemo(() => {
    // 是否有錯誤訊息
    const isHaveError = Object.values(errorData).some(item => !!item);
    // 是否有未同意條款
    const isNotAgreed = Object.values(agreedData).some(item => !item);

    // 是否有錯誤訊息或是當是註冊頁面則判斷是否有未同意條款
    return isHaveError || (isRegister && isNotAgreed);
  },
  [isRegister, errorData, agreedData]);

  /**
   * 取得驗證碼按鈕點擊事件
   * @param event HTML Button 事件
   */
  const handleGetVerifyCodeClick = useCallback(async (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    let result = true;
    if (onGetVerifyCodeClick && account) {
      // 取得驗證碼
      result = await onGetVerifyCodeClick(account);
    }

    if (result) {
      // 開始倒數
      start();
    }
  }, [data, start]);

  /**
   * 資料變更事件
   * @param event HTML Input 事件
   */
  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();

    const { target } = event;
    const newData = { ...data };
    switch (target.name) {
      case 'account':
        newData.account = target.value;
        break;
      case 'verifyCode':
        newData.verifyCode = target.value.trim().replace(/\D/g, '');
        break;
      case 'name':
        newData.name = target.value;
        break;
      case 'email':
        newData.email = target.value.trim();
        break;
      case 'password':
        newData.password = target.value.trim();
        break;
      case 'passwordConfirm':
        newData.passwordConfirm = target.value.trim();
        break;
      default:
        break;
    }

    if (onChange) {
      onChange(newData);
    }
  }, [data, onChange]);

  /**
   * 同意事件
   * @param event HTML Input 事件
   */
  const handleAgreedChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: {
        name: fieldName,
        checked,
      },
    } = event;

    setAgreedData({
      ...agreedData,
      [fieldName]: checked,
    });
  }, [agreedData]);

  /**
   * 捲動事件
   * @param event HTML Input 事件
   */
  const handleScroll = useCallback((event: React.UIEvent<HTMLTextAreaElement>) => {
    const target = event.target as HTMLInputElement;
    const { scrollTop, scrollHeight, clientHeight } = target;

    // 判斷是否捲動到底部
    if (Math.floor(scrollTop + clientHeight) >= Math.floor(scrollHeight - 1)) {
      const { name: fieldName } = target;
      setReadData({
        ...readData,
        [fieldName]: true,
      });
    }
  }, [readData]);

  // 設定 Ref
  useImperativeHandle(ref, () => ({
    isHaveErrorMessage, // 是否有錯誤訊息
  }));

  /**
   * 輸入欄位資料
   */
  const fieldData: IFieldDataItem[] = useMemo(() => ([
    {
      isShow: isRegister,
      keyId: 'name',
      type: FieldType.InputField,
      formInputProps: {
        type: 'text',
        icon: 'pencil alternate',
        name: 'name',
        htmlFor: 'name',
        placeholder: TextMap.NAME_PLACEHOLDER,
        label: TextMap.NAME_LABEL,
        maxLength: 24,
        value: name,
        onChange: handleChange,
      },
      errorMessage: nameError,
    },
    {
      isShow: isRegister,
      keyId: 'email',
      type: FieldType.InputField,
      formInputProps: {
        type: 'text',
        icon: 'pencil alternate',
        name: 'email',
        htmlFor: 'email',
        placeholder: TextMap.EMAIL_PLACEHOLDER,
        label: TextMap.EMAIL_LABEL,
        maxLength: 40,
        value: email,
        onChange: handleChange,
      },
      errorMessage: emailError,
    },
    {
      isShow: true,
      keyId: 'password',
      type: FieldType.InputField,
      formInputProps: {
        type: 'password',
        icon: 'lock',
        name: 'password',
        htmlFor: 'password',
        placeholder: TextMap.PASSWORD_PLACEHOLDER,
        label: TextMap.PASSWORD_LABEL,
        maxLength: 16,
        pattern: '^[a-zA-Z0-9]{8,16}$',
        value: password,
        onChange: handleChange,
      },
      errorMessage: passwordError,
    },
    {
      isShow: true,
      keyId: 'passwordConfirm',
      type: FieldType.InputField,
      formInputProps: {
        type: 'password',
        icon: 'lock',
        name: 'passwordConfirm',
        htmlFor: 'passwordConfirm',
        placeholder: TextMap.PASSWORD_CONFIRM_PLACEHOLDER,
        label: TextMap.PASSWORD_CONFIRM_LABEL,
        maxLength: 16,
        pattern: '^[a-zA-Z0-9]{8,16}$',
        value: passwordConfirm,
        onChange: handleChange,
      },
      errorMessage: passwordConfirmError,
    },
  ]), [data, errorData, handleChange]);

  /**
   * 手機欄位資料
   */
  const phoneFieldData: IFieldDataItem[] = useMemo(() => ([
    {
      isShow: true,
      keyId: 'account',
      type: FieldType.InputField,
      formInputProps: {
        type: 'text',
        icon: 'user',
        name: 'account',
        htmlFor: 'account',
        placeholder: TextMap.ACCOUNT_PLACEHOLDER,
        label: TextMap.ACCOUNT_LABEL,
        maxLength: 10,
        value: account,
        onChange: handleChange,
      },
      errorMessage: accountError,
    },
    {
      isShow: true,
      keyId: 'verifyCode',
      type: FieldType.InputField,
      formInputProps: {
        type: 'text',
        icon: 'paper plane',
        name: 'verifyCode',
        htmlFor: 'verifyCode',
        placeholder: TextMap.VERIFY_CODE_PLACEHOLDER,
        label: TextMap.VERIFY_CODE_LABEL,
        maxLength: 4,
        pattern: '^\\d{4}$',
        value: verifyCode,
        onChange: handleChange,
      },
      errorMessage: verifyCodeError,
    },
  ]), [isRegister, data, errorData, handleChange]);

  /**
   * 條款欄位資料
   */
  const articleFieldData: IArticleFieldDataItem[] = useMemo(() => ([
    {
      isShow: isRegister,
      keyId: 'serviceTerms',
      fieldProps: {
        name: 'serviceTerms',
        content: serviceTermsContent,
        label: SERVICE_TERMS_LABEL,
        hasRead: readData.serviceTerms,
        isAgreed: agreedData.serviceTerms,
      },
      errorMessage: SERVICE_TERMS_ERROR_MESSAGE,
      onScroll: handleScroll,
      onChange: handleAgreedChange,
    },
    {
      isShow: isRegister,
      keyId: 'privacyPolicy',
      fieldProps: {
        name: 'privacyPolicy',
        content: privacyPolicyContent,
        label: PRIVACY_POLICY_LABEL,
        hasRead: readData.privacyPolicy,
        isAgreed: agreedData.privacyPolicy,
      },
      errorMessage: PRIVACY_POLICY_ERROR_MESSAGE,
      onScroll: handleScroll,
      onChange: handleAgreedChange,
    },
  ]), [serviceTermsContent, privacyPolicyContent, readData, agreedData, handleScroll, handleAgreedChange]);

  useEffect(() => {
    onGetServiceTerms();
    onGetPrivacyPolicy();
  }, []);

  return (
    <React.Fragment>
      {
        phoneFieldData.map(item => (
          <React.Fragment key={item.keyId}>
            <FormInput
              {...item.formInputProps}
            />
            {
              item.keyId === 'account' &&
              <div className={styles.foreignContent}>
                {
                  isResetPassword &&
                  <>
                    <span>{TextMap.RESET_PASSWORD_ACCOUNT_PROMPT}</span>
                    <span>{TextMap.RESET_PASSWORD_ACCOUNT_PROMPT_ENG}</span>
                  </>
                }
                {
                  !isResetPassword &&
                  <>
                    <span>{TextMap.REGISTER_ACCOUNT_PROMPT}</span>
                    <span>{TextMap.REGISTER_ACCOUNT_PROMPT_ENG}</span>
                  </>
                }
              </div>
            }
            {
              isShowError && item.errorMessage &&
              <p className={classnames(styles.error)}>{item.errorMessage}</p>
            }
          </React.Fragment>
        ))
      }
      <div className={styles.verifyCodeRow}>
        <div>
          <p>{TextMap.CAN_NOT_RECEIVE_VERIFY_CODE}</p>
          <a target="_blank" rel="noopener noreferrer" href={LINE_URL}>
            {TextMap.LINE_CUSTOMER_SERVICE}
          </a>
        </div>
        <Button
          style={{ width: '120px' }}
          disabled={verifyCodeDisabled || !isPhoneNumber(account ?? '') || count !== DEFAULT_COUNT}
          onClick={handleGetVerifyCodeClick}
        >
          {
            count === DEFAULT_COUNT
              ? TextMap.GET_VERIFY_CODE
              : `${TextMap.RESEND}${count === 0 ? '' : `(${count})`}`
          }
        </Button>
      </div>
      {
        fieldData.filter(item => item.isShow).map(item => (
          <React.Fragment key={item.keyId}>
            <FormInput
              {...item.formInputProps}
            />
            {
              isShowError && item.errorMessage &&
              <p className={classnames(styles.error)}>{item.errorMessage}</p>
            }
          </React.Fragment>
        ))
      }
      {
        articleFieldData.filter(item => item.isShow).map(item => (
          <div key={item.keyId} className={styles.articleField}>
            <textarea
              name={item.fieldProps.name}
              value={item.fieldProps.content}
              onScroll={item.onScroll}
              disabled
            />
            <div>
              <input
                name={item.fieldProps.name}
                id={`${item.fieldProps.name}Agreed`}
                type="checkbox"
                disabled={!item.fieldProps.hasRead}
                onChange={item.onChange}
              />
              <label htmlFor={`${item.fieldProps.name}Agreed`}>{item.fieldProps.label}</label>
            </div>
            {
              isShowError && !item.fieldProps.isAgreed &&
              <p className={classnames(styles.error)}>{item.errorMessage}</p>
            }
          </div>
        ))
      }
    </React.Fragment>
  );
};

const mapStateToProps = ({
  article: {
    privacyPolicyContent,
    serviceTermsContent,
  },
}: {
  article: {
    privacyPolicyContent: string;
    serviceTermsContent: string;
  };
}) => ({
  privacyPolicyContent,
  serviceTermsContent,
});

const mapDispatchToProps = (dispatch: any) => ({
  onGetServiceTerms: () => dispatch(getServiceTerms()),
  onGetPrivacyPolicy: () => dispatch(getPrivacyPolicy()),
});

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(forwardRef(InputContent));
