/**
 * FeaturePath: 會員管理-學涯-課程瀏灠-檢視課程列表
 * FeaturePath: 會員管理-學涯-課程瀏灠-進階搜尋
 * FeaturePath: 會員管理-學涯-課程瀏灠-課程資訊
 * FeaturePath: 會員管理-學涯-課程瀏灠-我的課程列表
 * FeaturePath: 會員管理-學涯-上課-新增個人課程滿意度
 * FeaturePath: 會員管理-學涯-上課-更新線上課程進度
 * FeaturePath: 會員管理-學涯-上課-提交測驗
 * FeaturePath: 會員管理-學涯-課程瀏灠-檢視課程類別列表
 * FeaturePath: 會員管理-學涯-積分管理-確認可申請積分
 * FeaturePath: 會員管理-學涯-積分管理-申請積分
 * FeaturePath: 會員管理-學涯-積分管理-同意小卡到期提醒通知
 * FeaturePath: 會員管理-交易-退款-退課
 * FeaturePath: 會員管理-學涯-上課-線上課程
 * FeaturePath: 會員管理-交易-退款-取得銀行列表
 * FeaturePath: 會員管理-交易-退款-取得使用者退款資料
 * FeaturePath: 會員管理-學涯-課程瀏灠-檢視單一組合包課程內容
 * FeaturePath: 會會員管理-個人資料-交易清單-取得購課紀錄
 * FeaturePath: 會員管理-學涯-上課-簽到
 * FeaturePath: 會員管理-學涯-上課-簽退
 * FeaturePath: 會員管理-學涯-上課-抓取目前直播時間
 * FeaturePath: 會員管理-學涯-證書-下載證明
 * FeaturePath: 會員管理-學涯-學習行事曆-取得行事曆
 * FeaturePath: 會員管理-學涯-上課-讀取該課程的推薦課程
 * FeaturePath: 會員管理-學涯-上課-建立課程願望
 * FeaturePath: 會員管理-學涯-學習課程-學習清單
 * Accountable: Tom Jhuang, Landy Chu
 */

// ---------------------------------------- import * from local modules ----------------------------------------
import { commonFetch, commonAuthFetch, fileBufferFetch } from './endpoint';

// ---------------------------------------- constants ----------------------------------------
// 簽到/退狀態
const SIGN_TYPE = {
  SignIn: 1, // 簽到
  SignOut: 2, // 簽退
};

// 呼叫課程相關 API
const courseRequest = {
  /**
   * FeaturePath: 會員管理-學涯-課程瀏灠-檢視課程列表
   * @param {string} query 查詢參數
   * @returns Fetch Promise
   */
  async fetchCourseList(query) {
    return commonFetch({
      url: '/api/course/find',
      method: 'GET',
      query,
    });
  },
  /**
   * FeaturePath: 會員管理-學涯-課程瀏灠-進階搜尋
   * @param {string} query 查詢參數
   * @returns Fetch Promise
   */
  async fetchAdvancedCourseList(query) {
    return commonFetch({
      url: '/api/course/advance_find',
      method: 'GET',
      query,
    });
  },
  /**
   * FeaturePath: 會員管理-學涯-課程瀏灠-課程資訊
   * @param {string} id 課程 Id
   * @returns Fetch Promise
   */
  async fetchTarget(id) {
    return commonFetch({
      url: `/api/course/${id}`,
      method: 'GET',
      isAuthProcessing: false,
    });
  },
  /**
   * FeaturePath: 會員管理-學涯-課程瀏灠-我的課程列表
   * @param {string} query 查詢參數
   * @returns Fetch Promise
   */
  async fetchMyCourseList(query) {
    return commonFetch({
      url: '/api/user/study',
      method: 'GET',
      query,
      isAuthProcessing: false,
    });
  },
  /**
   * FeaturePath: 會員管理-學涯-上課-新增個人課程滿意度
   * @param {object} postData 查詢參數
   * @returns Fetch Promise
   */
  async rateCourse(postData) {
    return commonFetch({
      url: '/api/course/rate',
      method: 'POST',
      postData,
    });
  },
  /**
   * FeaturePath: 會員管理-學涯-上課-更新線上課程進度
   * @param {object} postData 查詢參數
   * @returns Fetch Promise
   */
  async updateCourseProgress(postData) {
    return commonFetch({
      url: '/api/course/progress',
      method: 'POST',
      postData,
    });
  },
  /**
   * FeaturePath: 會員管理-學涯-上課-提交測驗
   * @param {object} postData 測驗答案
   * @returns Fetch Promise
   */
  async submitQuiz(postData) {
    return commonFetch({
      url: '/api/course/quiz',
      method: 'POST',
      postData,
    });
  },
  /**
   * FeaturePath: 會員管理-學涯-課程瀏灠-檢視課程類別列表
   * @returns Fetch Promise
   */
  async courseFilterOption() {
    return commonFetch({
      url: '/api/course/category',
      method: 'GET',
    });
  },
  /**
   * FeaturePath: 會員管理-學涯-積分管理-確認可申請積分
   * @param {object} postData 查詢參數
   * @returns Fetch Promise
   */
  async userCreditCheck(postData) {
    // TODO: 目前這個 API 未使用
    return commonFetch({
      url: '/api/course/creditcheck',
      method: 'POST',
      postData,
      isAuthProcessing: false,
    });
  },
  /**
   * FeaturePath: 會員管理-學涯-積分管理-申請積分
   * @param {object} postData 查詢參數
   * @returns Fetch Promise
   */
  async submitCredit(postData) {
    return commonFetch({
      url: '/api/course/creditapply',
      method: 'POST',
      postData,
      isAuthProcessing: false,
    })
      .then(result => {
        const { success, study, showOrderPage, product, creditApplicationId } = result;
        if (success) {
          const creditProduct = {
            creditApplicationId,
            product,
          };
          return Promise.resolve({ success, study, showOrderPage, creditProduct });
        }

        return Promise.resolve(result);
      });
  },
  /**
   * FeaturePath: 會員管理-交易-退款-退課
   * @param {object} postData 查詢參數
   * @returns Fetch Promise
   */
  async courseRefund(postData) {
    return commonFetch({
      url: '/api/course/refund',
      method: 'POST',
      postData,
      isAuthProcessing: false,
    });
  },
  /**
   * FeaturePath: 會員管理-學涯-上課-線上課程
   * @param {string} id 課程 Id
   * @returns Fetch Promise
   */
  async startCourse(id) {
    return commonFetch({
      url: `/api/course/start/${id}`,
      method: 'GET',
      isAuthProcessing: false,
    });
  },
  /**
   * FeaturePath: 會員管理-交易-退款-取得銀行列表
   * @returns Fetch Promise
   */
  async fetchRefundBank() {
    return commonFetch({
      url: '/api/refund/banks',
      method: 'GET',
      isAuthProcessing: false,
    });
  },
  /**
   * FeaturePath: 會員管理-交易-退款-取得使用者退款資料
   * @param {string} id 使用者 Id
   * @returns Fetch Promise
   */
  async fetchRefundInfo(id) {
    return commonFetch({
      url: `/api/refund/${id}`,
      method: 'GET',
      isAuthProcessing: false,
    });
  },
  /**
   * FeaturePath: 會員管理-學涯-課程瀏灠-檢視單一組合包課程內容
   * @param {string} id 組合包課程 Id
   * @returns Fetch Promise
   */
  async fetchPackage(id) {
    return commonFetch({
      url: `/api/course/package/${id}`,
      method: 'GET',
      isAuthProcessing: false,
    });
  },
  /**
   * FeaturePath: 會會員管理-個人資料-交易清單-取得購課紀錄
   * @returns Fetch Promise
   */
  async fetchPurchased() {
    return commonFetch({
      url: '/api/user/purchased',
      method: 'GET',
      isAuthProcessing: false,
    });
  },
  /**
   * FeaturePath: 會員管理-學涯-上課-簽到
   * @param {string} studyId 學習 Id
   * @returns Fetch Promise
   */
  async signInCourse(studyId) {
    return commonFetch({
      url: `/api/studies/${studyId}/check-out`,
      method: 'PATCH',
      postData: { signType: SIGN_TYPE.SignIn },
      isAuthProcessing: false,
    });
  },
  /**
   * FeaturePath: 會員管理-學涯-上課-簽退
   * @param {string} studyId 學習 Id
   * @returns Fetch Promise
   */
  async signOutCourse(studyId) {
    return commonFetch({
      url: `/api/studies/${studyId}/check-out`,
      method: 'PATCH',
      postData: { signType: SIGN_TYPE.SignOut },
      isAuthProcessing: false,
    });
  },
  /**
   * FeaturePath: 會員管理-學涯-上課-抓取目前直播時間
   * @param {string} id 課程 Id
   * @returns Fetch Promise
   */
  async fetchCourseLiveTime(id) {
    return commonFetch({
      url: `/api/course/${id}/live-time`,
      method: 'GET',
      isAuthProcessing: false,
    })
      .then(result => {
        const { success, content } = result;
        if (success) {
          return Promise.resolve({ success, liveTime: content });
        }

        return Promise.resolve(result);
      });
  },
  /**
   * FeaturePath: 會員管理-學涯-證書-下載證明
   * @param {string} id 證書 Id
   * @returns Fetch Promise
   */
  async downloadCompletionCertificate(id) {
    return fileBufferFetch(`/api/studies/certificates/${id}`, 'GET', `t=${Date.now()}`, 'pdf')
      .then(({ buffer, filename }) => {
        const content = new Blob([buffer], { type: 'application/pdf' });
        const encodedUri = URL.createObjectURL(content);
        const link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', decodeURIComponent(filename));
        link.click();
      })
      .catch(err => {
        if (!err.response.extras.success) {
          return Promise.reject(err);
        }
        return Promise.resolve({ success: false });
      });
  },
  /**
   * FeaturePath: 會員管理-學涯-學習行事曆-取得行事曆
   * @param {object} searchDate 查詢參數
   * @returns Fetch Promise
   */
  async courseCalendar(searchDate) {
    const { start, end } = searchDate;
    return commonFetch({
      url: '/api/user/calendar',
      method: 'GET',
      query: `start=${start}&end=${end}`,
      isAuthProcessing: false,
    });
  },
  /**
   * FeaturePath: 會員管理-學涯-上課-讀取該課程的推薦課程
   * @param {string} courseId 課程 Id
   * @returns Fetch Promise
   */
  async getRecommendCourses(courseId) {
    return commonAuthFetch({
      url: `/api/recommend-courses/${courseId}`,
      method: 'GET',
      isAuthProcessing: false,
    });
  },
  /**
   * FeaturePath: 會員管理-學涯-上課-建立課程願望
   * @param {object} postData 查詢參數
   * @returns Fetch Promise
   */
  async setWillingCourse(postData) {
    return commonFetch({
      url: '/api/recommend-courses/willing',
      method: 'POST',
      postData,
    });
  },
  /**
   * FeaturePath: 會員管理-學涯-學習課程-學習清單
   * @returns Fetch Promise
   */
  async getLearningHistory() {
    return commonAuthFetch({
      url: '/api/studies',
      method: 'GET',
    });
  },
  /**
   * FeaturePath: 會員管理-學涯-積分管理-同意小卡到期提醒通知
   * @param {string} courseId 課程 Id
   * @returns Fetch Promise
   */
  async agreeToExpirationNotice(courseId) {
    return commonAuthFetch({
      url: '/api/studies/agreeToExpirationNotice',
      method: 'POST',
      postData: { courseId },
    });
  },
};

export default courseRequest;
