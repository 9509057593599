/**
 * FeaturePath: 會員系統-個人資訊-個人資訊
 * Accountable: Landy Chu, AlexCH Cheng
 */

import React, { lazy, Suspense } from 'react';

import LoadingDimmer from '../components/Utils/LoadingDimmer';

const Information = lazy(() => import('../components/Pages/Information'));

export const InformationPage = props => (
  <Suspense fallback={LoadingDimmer()}>
    <Information {...props} />
  </Suspense>
);
