/**
 * FeaturePath: 會員系統-其它-條款-隱私權政策
 * FeaturePath: 會員系統-其它-條款-服務條款
 * Accountable: Tom Jhuang, Landy Chu
 */

// ----- import npm modules -----
import moment from 'moment';

// ----- import local modules -----
import {
  SET_PRIVACY_POLICY,
  SET_SERVICE_TERMS,
  SET_PRIVACY_POLICY_HTML,
  SET_SERVICE_TERMS_HTML,
  SERVICE_TERMS_TYPE,
  PRIVACY_POLICY_TYPE,
} from '../constants/article';
import { DATE_FORMAT } from '../constants/share';

import articleRequest from '../request/article';

/**
 * 附加版本號與修訂日期
 * @param content 內容
 * @param version 版本號
 * @param revision 修訂日期
 * @returns 附加版本號與修訂日期後的內容
 */
function appendVersionAndRevisionDate(content: string, version: string, revision: string): string {
  return content
    .replace(/<h1>([^<]+)<\/h1>/ig, `<h1>$1<span>(${version})</span></h1><span>修訂日期：${revision}</span>`);
}

/**
 * 將 HTML 的有序清單轉換成文字
 * @param content 內容
 * @returns 轉換後的內容
 */
function convertListTabToText(content: string): string {
  const matches = content.match(/<li>((.|\n)+?)<\/li>/gm);
  if (matches) {
    if (/<ol>/gm.test(content)) {
      return matches
        .map((match, index) => match.replace(/<li>((.|\n)+?)<\/li>/m, `    ${index + 1}. $1`))
        .join('\n');
    }

    return matches
      .map(match => match.replace(/<li>((.|\n)+?)<\/li>/m, '    。 $1'))
      .join('\n');
  }

  return content;
}

/**
 * 移除 HTML 標籤
 * @param content 內容
 * @returns 移除 HTML 標籤後的內容
 */
function removeHtmlTag(content: string): string {
  let result = content;
  const matches = result.match(/<ol>(.|\n)+?<\/ol>/gm);
  matches?.forEach(match => {
    result = result.replace(match, convertListTabToText(match));
  });

  return result.replace(/<.+?>/gm, '');
}

/**
 * 設定隱私權政策
 * @param content 隱私權政策內容
 */
export function setPrivacyPolicy(content: string) {
  return { type: SET_PRIVACY_POLICY, content };
}

/**
 * 設定服務條款
 * @param content 服務條款內容
 */
export function setServiceTerms(content: string) {
  return { type: SET_SERVICE_TERMS, content };
}

/**
 * 設定隱私權政策 HTML
 * @param content 隱私權政策 HTML
 */
export function setPrivacyPolicyHtml(content: string) {
  return { type: SET_PRIVACY_POLICY_HTML, content };
}

/**
 * 設定服務條款 HTML
 * @param content 服務條款 HTML
 */
export function setServiceTermsHtml(content: string) {
  return { type: SET_SERVICE_TERMS_HTML, content };
}

/**
 * 取得隱私權政策
 * @param isRefresh 是否重新取得
 */
export function getPrivacyPolicy(isRefresh = false) {
  return async (dispatch: any, getState: any) => {
    const {
      article: {
        privacyPolicyContent,
        privacyPolicyHtml,
      },
    } = getState();

    if (!isRefresh && privacyPolicyContent && privacyPolicyHtml) {
      return;
    }

    const { success, content } = await articleRequest.fetchArticle({
      type: PRIVACY_POLICY_TYPE,
    });
    if (success === true) {
      const versionText = content?.version?.text || '';
      let revisionText = '';
      if (content?.revisionDate) {
        revisionText = moment(content.revisionDate).format(DATE_FORMAT);
      }
      dispatch(setPrivacyPolicy(removeHtmlTag(content?.content)));
      dispatch(setPrivacyPolicyHtml(appendVersionAndRevisionDate(content?.content, versionText, revisionText)));
    }
  };
}

/**
 * 取得服務條款
 * @param isRefresh 是否重新取得
 */
export function getServiceTerms(isRefresh = false) {
  return async (dispatch: any, getState: any) => {
    const {
      article: {
        serviceTermsContent,
        serviceTermsHtml,
      },
    } = getState();

    if (!isRefresh && serviceTermsContent && serviceTermsHtml) {
      return;
    }

    const { success, content } = await articleRequest.fetchArticle({
      type: SERVICE_TERMS_TYPE,
    });
    if (success === true) {
      const versionText = content?.version?.text || '';
      let revisionText = '';
      if (content?.revisionDate) {
        revisionText = moment(content.revisionDate).format(DATE_FORMAT);
      }
      dispatch(setServiceTerms(removeHtmlTag(content?.content)));
      dispatch(setServiceTermsHtml(appendVersionAndRevisionDate(content?.content, versionText, revisionText)));
    }
  };
}
