/**
 * FeaturePath: 會員系統-其它-首頁
 * Accountable: Landy Chu, AlexCH Cheng
 */

import React, { Component } from 'react';
import Slider from 'react-slick';
import classnames from 'classnames';

import lastpage from 'images/icon/lastpage.svg';
import nextpage from 'images/icon/nextpage.svg';

import CourseCard from 'react/components/Utils/CourseCard';

import { add } from '../../../../utils/utils';

import styles from './styles.css';

import '../../../../../styles/components/slick.css';
import '../../../../../styles/components/slick-theme.css';

class CardSlider extends Component {
  constructor(props) {
    super(props);
    this._onSlickNext = this._onSlickNext.bind(this);
    this._onSlickPrev = this._onSlickPrev.bind(this);
    this.state = {
      // slideIndex: 0,
    };
  }

  componentDidMount() {
    setTimeout(() => {
      if (this.slider) {
        this.slider.slickGoTo(0);
      }
    }, 500);
  }

  _onSlickNext() {
    this.slider.slickNext();
  }

  _onSlickPrev() {
    this.slider.slickPrev();
  }

  render() {
    const {
      className,
      type,
      cardList,
    } = this.props;
    const settings = {
      dots: false,
      infinite: cardList.length > 4,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      lazyRender: true,
      draggable: false,
      arrows: false,
    };

    return (
      <div className={classnames(styles.cardSlider, className)}>
        {
          type === 'pc' && cardList.length > 4 &&
          <div
            className={classnames(styles.prev, { [styles.pc]: type === 'pc' })}
            onClick={this._onSlickPrev}
          >
            <img src={lastpage} alt="" />
          </div>
        }
        {
          type === 'pc' &&
          <Slider ref={slider => { this.slider = slider; }} {...settings} initialSlide={0}>
            {
              type === 'pc' &&
              cardList.map(list => (
                <div className={styles.div} key={list._id}>
                  <CourseCard
                    key={list._id}
                    className={styles.card}
                    id={list._id}
                    img={list.banner}
                    title={list.name}
                    time={list.duration}
                    originPrice={list.price}
                    discount={list.discount}
                    star={list.rating === 0 ? 5 : list.rating}
                    points={
                      list.type === 'PACKAGE'
                        ? list.packages.map(p => p.creditPoints)
                          .reduce((a, b) => add(a, b), 0)
                        : list.creditPoints
                    }
                    courseType={list.type}
                    quota={list.quota - list.signups}
                  />
                </div>
              ))
            }
            {
              type === 'pc' && (cardList.length === 1 || cardList.length === 2 || cardList.length === 3) ?
                <div className={styles.div} /> : ''
            }
            {
              type === 'pc' && (cardList.length === 1 || cardList.length === 2) ?
                <div className={styles.div} /> : ''
            }
            {
              type === 'pc' &&
              cardList.length === 1 &&
              <div className={styles.div} />
            }
          </Slider>
        }
        {
          type === 'pc' && cardList.length > 4 &&
          <div
            className={classnames(styles.next, { [styles.pc]: type === 'pc' })}
            onClick={this._onSlickNext}
          >
            <img src={nextpage} alt="" />
          </div>
        }
        {
          type === 'mobile' &&
          <div className={styles.scrollWrap}>
            {
              cardList.map(list => (
                <div className={styles.mobileDiv} key={list._id}>
                  <CourseCard
                    key={list._id}
                    className={styles.card}
                    id={list._id}
                    img={list.banner}
                    title={list.name}
                    time={list.duration}
                    originPrice={list.price}
                    discount={list.discount}
                    star={list.rating === 0 ? 5 : list.rating}
                    points={
                      list.type === 'PACKAGE'
                        ? list.packages.map(p => p.creditPoints)
                          .reduce((a, b) => add(a, b), 0)
                        : list.creditPoints
                    }
                    courseType={list.type}
                    quota={list.quota - list.signups}
                  />
                </div>
              ))
            }
          </div>
        }
      </div>
    );
  }
}

export default CardSlider;
