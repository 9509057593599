/**
 * FeaturePath: 會員系統-其它-工具
 * Accountable: Landy Chu, AlexCH Cheng,
 */
export const SENDING_REQUEST = 'SENDING_REQUEST';
export const LOADING_REQUEST = 'LOADING_REQUEST';
export const OPEN_ALERT = 'OPEN_ALERT';
export const CLOSE_ALERT = 'CLOSE_ALERT';
export const TOGGLE_NAVI = 'TOGGLE_NAVI';
export const TOGGLE_SEARCH = 'TOGGLE_SEARCH';
export const TOGGLE_ADVANCED_SEARCH = 'TOGGLE_ADVANCED_SEARCH';
export const LOADING_DIALOG_REQUEST = 'LOADING_DIALOG_REQUEST';
export const LOGIN_DIALOG_REQUEST = 'LOGIN_DIALOG_REQUEST';
export const SET_IS_TRANSITIONS = 'SET_IS_TRANSITIONS';
export const REGISTER_DIALOG_REQUEST = 'REGISTER_DIALOG_REQUEST';
export const SET_WORK_CERT_VALIDITY_STATUS = 'SET_WORK_CERT_VALIDITY_STATUS';
export const SET_USER_ADDRESS = 'SET_USER_ADDRESS';
