/**
 * FeaturePath: 會員系統-其它-工具
 * Accountable: AlexCH Cheng, Landy Chu
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import bannerBg from 'images/bannerbg.png';

import 'react-responsive-carousel/lib/styles/carousel.min.css';

import { fetchBanner, clickBanner } from 'react/actions/auth';

import styles from './styles.css';

const GTAG_CLICK_ACTION_NAME = '_CLICK_COUNT';
const GTAG_CLICK_CATEGORY_NAME = 'O2O_ADVERTISEMENT_CLICK_COUNT';
const GTAG_CLICK_WITHOUT_ACCOUNT_LABEL_NAME = 'WITHOUT_ACCOUNT';
const GTAG_CLICK_WITH_ACCOUNT_LABEL_NAME = 'WITH_ACCOUNT_';
const GTAG_EXPOSURE_ACTION_NAME = '_EXPOSURE_COUNT';
const GTAG_EXPOSURE_CATEGORY_NAME = 'O2O_ADVERTISEMENT_EXPOSURE_COUNT';
const GTAG_EXPOSURE_WITHOUT_ACCOUNT_LABEL_NAME = 'WITHOUT_ACCOUNT';
const GTAG_EXPOSURE_WITH_ACCOUNT_LABEL_NAME = 'WITH_ACCOUNT_';

class BannerSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      banners: [],
    };

    this._onBannerClick = this._onBannerClick.bind(this);
    this._onAfterSlide = this._onAfterSlide.bind(this);
  }

  componentDidMount() {
    this.props.fetchBanner();
  }

  componentDidUpdate(prevProps) {
    const {
      banners,
    } = this.props;

    if (banners !== prevProps.banners) {
      const newBanners = [
        ...banners.filter(banner => banner.course === null),
        ...banners.filter(banner => banner.course !== null)];
      /* eslint-disable react/no-did-update-set-state */
      this.setState({
        banners: newBanners,
      }, () => {
        // 第一次顯示廣告的時候，先記錄第一個廣告的曝光次數
        if (newBanners.length > 0) this._onAfterSlide(0);
      });
    }
  }

  _onBannerClick(id, link) {
    const {
      authStatus,
      account,
    } = this.props;

    // 記錄廣告的點擊次數，分為會員和非會員
    if (authStatus) {
      window.gtag('event', `${link}${GTAG_CLICK_ACTION_NAME}`, {
        event_category: GTAG_CLICK_CATEGORY_NAME,
        event_label: `${GTAG_CLICK_WITH_ACCOUNT_LABEL_NAME}${account}`,
      });
    } else {
      window.gtag('event', `${link}${GTAG_CLICK_ACTION_NAME}`, {
        event_category: GTAG_CLICK_CATEGORY_NAME,
        event_label: GTAG_CLICK_WITHOUT_ACCOUNT_LABEL_NAME,
      });
    }

    this.props.clickBanner(id);
  }

  _onAfterSlide(slideIndex) {
    const {
      authStatus,
      account,
    } = this.props;
    const { banners } = this.state;

    // 記錄廣告的曝光次數，分為會員和非會員
    const banner = banners[slideIndex];
    if (!banner) {
      return;
    }

    window.gtag('event', `${banner.link}${GTAG_EXPOSURE_ACTION_NAME}`, {
      event_category: GTAG_EXPOSURE_CATEGORY_NAME,
      event_label: authStatus ?
        `${GTAG_EXPOSURE_WITH_ACCOUNT_LABEL_NAME}${account}` : GTAG_EXPOSURE_WITHOUT_ACCOUNT_LABEL_NAME,
    });
  }

  render() {
    const { className } = this.props;
    const { banners } = this.state;

    return (
      <div className={classnames(styles.slider, className)}>
        <div className={styles.pc}>
          <Carousel
            showThumbs={false}
            showStatus={false}
            showArrows={false}
            autoPlay
            infiniteLoop
            interval={5000}
            key={JSON.stringify(banners)}
            onChange={slideIndex => { this._onAfterSlide(slideIndex); }}
          >
            {
              banners.map(banner => {
                if (banner.course === null) {
                  return (
                    <a href={banner.link} target="_blank" rel="noopener noreferrer">
                      <div
                        key={banner._id}
                        className={styles.recommend}
                        onClick={() => { this._onBannerClick(banner._id, banner.link); }}
                      >
                        <img src={banner.banner} alt="" />
                      </div>
                    </a>
                  );
                }
                return (
                  <div
                    key={banner._id}
                    className={styles.recommend}
                    style={{ backgroundColor: banner.bgcolor }}
                    onClick={() => { this._onBannerClick(banner._id, banner.link); }}
                  >
                    <Link to={banner.link}>
                      <div
                        className={styles.left}
                        style={{ backgroundColor: banner.bgcolor }}
                      >
                        <img src={bannerBg} alt="" />
                        <div className={styles.wording}>
                          <p className={styles.title}>
                            {banner.title}<br />
                            <span className={styles.subtitle}>
                              {banner.subtitle}
                            </span>
                          </p>
                        </div>
                      </div>
                      <div
                        className={styles.right}
                        style={{ backgroundColor: banner.bgcolor }}
                      >
                        <img src={banner.banner} alt="" />
                      </div>
                    </Link>
                  </div>
                );
              })
            }
          </Carousel>
        </div>
        <div className={styles.mobile}>
          <Carousel
            key={`${JSON.stringify(banners)}_mobile`}
            showThumbs={false}
            showStatus={false}
            showArrows={false}
            autoPlay
            infiniteLoop
            interval={5000}
            onChange={slideIndex => { this._onAfterSlide(slideIndex); }}
          >
            {
              banners.map(banner => {
                if (banner.course === null) {
                  return (
                    <a href={banner.link} target="_blank" rel="noopener noreferrer">
                      <div
                        key={banner._id}
                        className={styles.recommend}
                        onClick={() => { this._onBannerClick(banner._id, banner.link); }}
                      >
                        <img src={banner.mbanner} alt="" />
                      </div>
                    </a>
                  );
                }
                return (
                  <div
                    key={banner._id}
                    className={styles.recommend}
                    style={{ backgroundColor: banner.bgcolor }}
                    onClick={() => { this._onBannerClick(banner._id, banner.link); }}
                  >
                    <Link to={banner.link}>
                      <div
                        className={styles.left}
                        style={{ backgroundColor: banner.bgcolor }}
                      >
                        <img src={bannerBg} alt="" />
                        <div className={styles.wording}>
                          <p className={styles.title}>
                            {banner.title}<br />
                            <span className={styles.subtitle}>
                              {banner.subtitle}
                            </span>
                          </p>
                        </div>
                      </div>
                      <div
                        className={styles.right}
                        style={{ backgroundColor: banner.bgcolor }}
                      >
                        <img src={banner.mbanner} alt="" />
                      </div>
                    </Link>
                  </div>
                );
              })
            }
          </Carousel>
        </div>
      </div>
    );
  }
}

function mapStateToProps({
  auth: { banners, status, account },
}) {
  return {
    banners,
    authStatus: status,
    account,
  };
}

const mapDispatchToProps = dispatch => ({
  fetchBanner: () => {
    dispatch(fetchBanner());
  },
  clickBanner: id => {
    dispatch(clickBanner(id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(BannerSlider);
