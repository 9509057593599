/**
 * FeaturePath: 會員系統-其它-工具
 * Accountable: Landy Chu, AlexCH Cheng
 */

export const SET_DRAW_ACTIVITIES = 'SET_DRAW_ACTIVITIES';
export const SET_UNDRAW_LIST = 'SET_UNDRAW_LIST';
export const SET_CLOSED_UNDRAW_LIST = 'SET_CLOSED_UNDRAW_LIST';
export const SET_LUCKY_DRAW_PRIZE = 'SET_LUCKY_DRAW_PRIZE';

export const luckyDrawPrizeType = {
  thankYou: 3,
};
