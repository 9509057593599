/**
 * FeaturePath: 會員系統-其它-工具
 * Accountable: AlexCH Cheng, Landy Chu
 */

import { useState, useEffect, useRef, useMemo, useCallback } from 'react';

const DEFAULT_COUNT = 60;

export const useCountdown = (initialCount: (number | undefined)):
[number, () => void, () => void, () => void] => {
  const [count, setCount] = useState(initialCount || DEFAULT_COUNT);
  const timeoutIdRef = useRef<null | number>(null);

  const defaultCount = useMemo(() => (initialCount || DEFAULT_COUNT), [initialCount]);

  const stop = useCallback(() => {
    if (timeoutIdRef.current) {
      window.clearTimeout(timeoutIdRef.current);
      timeoutIdRef.current = null;
    }
  }, []);

  const start = useCallback(() => {
    setCount(preValue => (preValue - 1));
    timeoutIdRef.current = window.setTimeout(() => {
      start();
    }, 1000);
  }, []);

  const reset = useCallback(() => {
    stop();
    setCount(defaultCount);
  }, [defaultCount]);

  useEffect(() => {
    if (count <= 0) {
      reset();
    }
  }, [count, reset]);

  useEffect(() => () => {
    stop();
  }, []);

  return [count, start, stop, reset];
};
