/**
 * FeaturePath: 會員系統-其它-工具
 * Accountable: AlexCH Cheng, Landy Chu
 */

import React, { Component } from 'react';
import { forwardTo } from 'react/actions/utils';
import { connect } from 'react-redux';
import queryString from 'query-string-for-all';

import MainButton from 'react/components/Utils/Button/MainButton';
import SelectBox from './SelectBox';

import styles from './styles.css';

class CourseFilter extends Component {
  static getDerivedStateFromProps(props, state) {
    if (props !== state.props) {
      let category = '';
      if (props.query.category && props.query.coursetype) {
        switch (props.query.coursetype) {
          case 'LIVE':
            category = `${props.query.category}live`;
            break;
          case 'ONLINE':
            category = `${props.query.category}online`;
            break;
          default:
            category = `${props.query.category}entity`;
            break;
        }
      }

      let coursetype = props.query.coursetype;
      if (!Array.isArray(coursetype) && coursetype) {
        coursetype = [coursetype];
      }

      return {
        props,
        category,
        attr: props.query.attr ? props.query.attr : '',
        theme: props.query.theme ? props.query.theme : '',
        coursetype,
      };
    }

    return null;
  }

  constructor(props) {
    super(props);
    this._onChange = this._onChange.bind(this);
    this._onSearch = this._onSearch.bind(this);

    let category = '';
    if (props.query.category && props.query.coursetype) {
      switch (props.query.coursetype) {
        case 'LIVE':
          category = `${props.query.category}live`;
          break;
        case 'ONLINE':
          category = `${props.query.category}online`;
          break;
        default:
          category = `${props.query.category}entity`;
          break;
      }
    }

    let coursetype = props.query.coursetype;
    if (!Array.isArray(coursetype) && coursetype) {
      coursetype = [coursetype];
    }

    this.state = {
      category,
      attr: props.query.attr ? props.query.attr : '',
      theme: props.query.theme ? props.query.theme : '',
      coursetype,
    };
  }

  _onChange(value, name) {
    switch (name.name) {
      case 'category':
        {
          let courseType = [];
          switch (value.value.match(/([a-z]+)$/)?.[1]) {
            case 'live':
              courseType = ['LIVE'];
              break;
            case 'online':
              courseType = ['ONLINE'];
              break;
            case 'entity':
              courseType = ['INCLASS', 'LINK'];
              break;
            case 'package':
              courseType = ['PACKAGE'];
              break;
            default:
              break;
          }

          this.setState({
            ...this.state,
            attr: '',
            theme: '',
            coursetype: courseType,
            [name.name]: value.value,
          });
        }
        break;
      case 'attr':
        this.setState({
          ...this.state,
          theme: '',
          [name.name]: value.value,
        });
        break;
      case 'theme':
        this.setState({
          ...this.state,
          [name.name]: value.value,
        });
        break;
      default:
        break;
    }
  }

  _onSearch() {
    const {
      category,
      attr,
      theme,
      coursetype,
    } = this.state;

    const queryOptions = {
      arrayFormat: 'comma',
    };

    if (category && category !== '') {
      const queryObject = {
        coursetype,
        category: category.match(/^([A-Z]+)/)?.[1],
      };
      if (this.props.query.type) {
        queryObject.type = this.props.query.type;
      }
      if (attr && attr !== '') {
        queryObject.attr = attr;
      }
      if (theme && theme !== '') {
        queryObject.theme = theme;
      }

      this.props.onFilter(`/course?${queryString.stringify(queryObject, queryOptions)}`);
      window.scrollTo(0, 0);
    }
  }

  render() {
    const { filterOptions, isLuna } = this.props;
    const {
      category,
      attr,
      theme,
      coursetype,
    } = this.state;

    let liveCourseOption = [];
    let onlineCourseOption = [];
    let entityCourseOption = [];
    let packageCourseOption = [];
    let attrOption = [];
    let themeArray = [];
    let themeOption = [];

    if (isLuna) {
      liveCourseOption = [{ value: 'CREDITlive', label: '繼續教育積分課程' }, { value: 'COMPALlive', label: '仁寶 i 照護系統課程' }, { value: 'TRAININGlive', label: '培訓課程' }];
      onlineCourseOption = [{ value: 'CREDITonline', label: '繼續教育積分課程' }, { value: 'COMPALonline', label: '仁寶 i 照護系統課程' }, { value: 'TRAININGonline', label: '培訓課程' }];
      entityCourseOption = [{ value: 'CREDITentity', label: '繼續教育積分課程' }, { value: 'COMPALentity', label: '仁寶 i 照護系統課程' }, { value: 'TRAININGentity', label: '培訓課程' }];
      packageCourseOption = [{ value: 'CREDITpackage', label: '組合包課程' }];
    } else {
      liveCourseOption = [{ value: 'CREDITlive', label: '繼續教育積分課程' }, { value: 'TRAININGlive', label: '培訓課程' }];
      onlineCourseOption = [{ value: 'CREDITonline', label: '繼續教育積分課程' }, { value: 'TRAININGonline', label: '培訓課程' }];
      entityCourseOption = [{ value: 'CREDITentity', label: '繼續教育積分課程' }, { value: 'TRAININGentity', label: '培訓課程' }];
      packageCourseOption = [{ value: 'CREDITpackage', label: '組合包課程' }];
    }
    const categoryString = category.match(/^([A-Z]+)/)?.[1];
    const attrArray = filterOptions.filter(item => item._id === categoryString);
    if (attrArray.length !== 0) {
      attrOption = attrArray[0].attribute.map(
        option => ({ value: option._id, label: option.name }));
      themeArray = attrArray[0].attribute.filter(option => option._id === attr);
    }
    if (themeArray.length !== 0) {
      themeOption = themeArray[0].theme.map(
        option => ({ value: option._id, label: option.name }));
    }

    let categoryValue = null;
    if (Array.isArray(coursetype)) {
      let isFound = true;
      for (let index = 0; index < coursetype.length; index += 1) {
        switch (coursetype[index]) {
          case 'LIVE':
            categoryValue = liveCourseOption.find(item => item.value === category);
            break;
          case 'ONLINE':
            categoryValue = onlineCourseOption.find(item => item.value === category);
            break;
          case 'INCLASS':
          case 'LINK':
            categoryValue = entityCourseOption.find(item => item.value === category);
            break;
          case 'PACKAGE':
            categoryValue = packageCourseOption.find(item => item.value === category);
            break;
          default:
            isFound = false;
            break;
        }

        if (isFound) {
          break;
        }
      }
    }

    return (
      <div className={styles.courseFilter}>
        <div className={styles.container}>
          <p className={styles.title}>
            課程快搜
          </p>
          <SelectBox
            name="category"
            value={categoryValue}
            selected="CREDIT"
            className={styles.selectBox}
            options={[
              {
                label: '直播課程',
                options: liveCourseOption,
              },
              {
                label: '線上課程',
                options: onlineCourseOption,
              },
              {
                label: '實體課程',
                options: entityCourseOption,
              },
              {
                label: '組合包',
                options: packageCourseOption,
              },
            ]}
            placeholder="課程分類"
            onChange={this._onChange}
          />
          <SelectBox
            name="attr"
            value={
              attr ?
                attrOption[attrOption.findIndex(option => attr === option.value)]
                : ''
            }
            className={styles.selectBox}
            options={attrOption}
            placeholder="課程屬性"
            onChange={this._onChange}
            disabled={this.state.category === ''}
          />
          <SelectBox
            name="theme"
            value={
              theme ?
                themeOption[themeOption.findIndex(option => theme === option.value)]
                : ''
            }
            className={styles.selectBox}
            options={themeOption}
            placeholder="單元主題"
            onChange={this._onChange}
            disabled={this.state.attr === ''}
          />
          <MainButton
            disabled={category === ''}
            className={styles.button}
            color="orange"
            onClick={this._onSearch}
          >
            搜尋
          </MainButton>
        </div>
      </div>
    );
  }
}

function mapStateToProps({
  course: {
    filter,
  },
  auth: {
    isLuna,
  },
}) {
  return {
    filterOptions: filter,
    isLuna,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onFilter: url => dispatch(forwardTo(url)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CourseFilter);
