/**
 * FeaturePath: 會員系統-其它-工具
 * Accountable: Tom Jhuang, AlexCH_Cheng
 */

export const HOST_MAP = {
  dev: 'http://localhost:9000',
  demo: 'http://localhost:8100',
  uat: 'https://icareuat.compal-health.com',
  production: '',
  liveDemo: 'http://192.168.77.78:9001',
  docker: '{{ILEARN_HOST}}',
};

export const REDIRECT_MAP = {
  dev: 'http://localhost:9000',
  demo: 'http://icare.cacdidemo.com',
  uat: 'https://icareuat.compal-health.com',
  production: 'https://cl.care',
  liveDemo: 'http://192.168.77.78:9001',
  docker: '{{ILEARN_REDIRECT}}',
};

export const MAIN_MAP = {
  dev: 'http://localhost:9000',
  demo: 'http://localhost:8100',
  uat: 'https://icareuat.compal-health.com',
  production: '',
  liveDemo: 'http://192.168.77.78:9001',
  docker: '{{ILEARN_MAIN_HOST}}',
};

export const LOGIN_LINK_MAP = {
  dev: 'http://localhost:3000/mocklogin',
  demo: 'http://icare.cacdidemo.com/mocklogin',
  uat: 'https://icaretest.compal-health.com',
  production: 'https://icare.compal-health.com',
  liveDemo: 'http://192.168.77.78:9001/mocklogin',
  docker: '{{ILEARN_LOGIN_LINK}}',
};

export const META_TITLE_MAP = {
  dev: '仁寶 i 學習',
  demo: '仁寶 i 學習',
  uat: '仁寶 i 學習',
  production: '仁寶 i 學習',
  liveDemo: '仁寶 i 學習',
  docker: '仁寶 i 學習',
};

export const HEADER_COLOR_LEFT_MAP = {
  dev: '#64AAE6',
  demo: '#64AAE6',
  uat: '#64AAE6',
  production: '#64AAE6',
  liveDemo: '#64AAE6',
  docker: '#64AAE6',
};

export const HEADER_COLOR_RIGHT_MAP = {
  dev: '#00CC99',
  demo: '#00CC99',
  uat: '#00CC99',
  production: '#00CC99',
  liveDemo: '#00CC99',
  docker: '#00CC99',
};

export const CHAT_HOST_MAP = {
  dev: 'http://localhost:9034/wss/v1/chat-room',
  demo: 'http://localhost:9034/wss/v1/chat-room',
  uat: 'https://icarechatuat.compal-health.com/wss/v1/chat-room',
  production: 'http://localhost:9034/wss/v1/chat-room',
  liveDemo: 'http://192.168.77.78:9034/wss/v1/chat-room',
  docker: '{{ILEARN_CHAT_HOST}}',
};

export const CHAT_API_MAP = {
  dev: 'http://localhost:9034/api/v1',
  demo: 'http://localhost:9034/api/v1',
  uat: 'https://icarechatuat.compal-health.com/api/v1',
  production: 'http://localhost:9034/api/v1',
  liveDemo: 'http://192.168.77.78:9034/api/v1',
  docker: '{{ILEARN_CHAT_API}}',
};

export const CLIENT_ID_MAP = {
  dev: 'YmQ0YzgzMDYtZjk5MC00NjRkLWJmMzctZTY5ODNkZjEzNjQ2',
  demo: 'YmQ0YzgzMDYtZjk5MC00NjRkLWJmMzctZTY5ODNkZjEzNjQ2',
  uat: 'YmQ0YzgzMDYtZjk5MC00NjRkLWJmMzctZTY5ODNkZjEzNjQ2',
  production: 'YmQ0YzgzMDYtZjk5MC00NjRkLWJmMzctZTY5ODNkZjEzNjQ2',
  liveDemo: 'YmQ0YzgzMDYtZjk5MC00NjRkLWJmMzctZTY5ODNkZjEzNjQ2',
  docker: '{{ILEARN_CLIENT_ID}}',
};

export const CLIENT_SECRET_MAP = {
  dev: 'ZjRmZWY4NDAtYWRjYS00MWI1LTg3YWItODAzZjhhMDgxMDIy',
  demo: 'ZjRmZWY4NDAtYWRjYS00MWI1LTg3YWItODAzZjhhMDgxMDIy',
  uat: 'ZjRmZWY4NDAtYWRjYS00MWI1LTg3YWItODAzZjhhMDgxMDIy',
  production: 'ZjRmZWY4NDAtYWRjYS00MWI1LTg3YWItODAzZjhhMDgxMDIy',
  liveDemo: 'ZjRmZWY4NDAtYWRjYS00MWI1LTg3YWItODAzZjhhMDgxMDIy',
  docker: '{{ILEARN_CLIENT_SECRET}}',
};

export const HOST = HOST_MAP[process.env.API];
export const API_ENDPOINT = HOST_MAP[process.env.API];
export const SHORTEN_URL = REDIRECT_MAP[process.env.API];
export const MAIN_ENDPOINT = MAIN_MAP[process.env.API];
export const LOGIN_LINK = LOGIN_LINK_MAP[process.env.API];
export const META_TITLE = META_TITLE_MAP[process.env.API];
export const HEADER_COLOR_LEFT = HEADER_COLOR_LEFT_MAP[process.env.API];
export const HEADER_COLOR_RIGHT = HEADER_COLOR_RIGHT_MAP[process.env.API];
export const CHAT_HOST = CHAT_HOST_MAP[process.env.API];
export const CHAT_API_ENDPOINT = CHAT_API_MAP[process.env.API];
export const CLIENT_ID = CLIENT_ID_MAP[process.env.API];
export const CLIENT_SECRET = CLIENT_SECRET_MAP[process.env.API];
export const ENV = '{{ILEARN_ENV}}';
