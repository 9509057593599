/**
 * FeaturePath: 學員管理-直播管理-聊天室-連線狀態
 * FeaturePath: 學員管理-交易管理-退課-退課
 * FeaturePath: 學員管理-課程管理-上課-線上課程
 * FeaturePath: 學員管理-課程管理-上課-直播課程
 * FeaturePath: 學員管理-課程管理-上課-(直播)簽到
 * FeaturePath: 學員管理-課程管理-上課-(直播)時數計算
 * FeaturePath: 學員管理-資料管理-購物車-查看購物車
 * Accountable: Tom Jhuang, AlexCH Cheng
 */
import queryString from 'query-string-for-all';
import moment from 'moment';

import {
  SET_LIST,
  SET_HOT_LIST,
  SET_RELATED_LIST,
  SET_COMPAL_LIST,
  SET_CREDIT_LIST,
  SET_TRAINING_LIST,
  GET_LIST_META,
  SET_TARGET,
  SET_MY_LIST,
  SET_NAVI_LIST,
  SET_SEARCH_LIST,
  GET_SEARCH_META,
  GET_FILTER_OPTION,
  SAVE_FILTER_QUERY,
  SET_REFUND_BANK,
  SET_REFUND_INFO,
  SET_PACKAGE,
  SET_PURCHASED,
  SET_LIVE_SCRIPTS,
  RESET_TARGET,
  GET_RECOMMEND_COURSES,
  GET_CALENDAR,
  GET_LEARNING_HISTORY,
  RESET_MOBILE_LIST,
  SET_MOBILE_LIST,
} from 'react/constants/course';
import routePath from 'react/constants/path';

import courseRequest from 'react/request/course';
import authRequest from 'react/request/auth';
import liveScriptRequest from 'react/request/liveScript';
import handoutsRequest from 'react/request/handouts';
import utilsRequest from 'react/request/utils';

import { openGlobalModal } from 'react/actions/modal';
import { forwardTo } from 'react/actions/utils';
import { getOrderList } from 'react/actions/cart';
import { getAuthStatus } from 'react/actions/auth';

import { log } from '../utils/utils';
import { generateHandoutWithWatermark } from '../utils/pdf';

export function setCourseList(type, list) {
  return { type, courses: list };
}

export function setTargetList(type, list, status = '', student = 0, isApplyExpired = false) {
  return { type, list, status, student, isApplyExpired };
}

export function setCourseListMeta(type, meta) {
  return { type, meta: { total: meta.total, lastPage: meta.lastPage } };
}

export function setFilterOption(type, filter) {
  return { type, filter };
}

export function setCalendar(type, calendar) {
  return { type, calendar };
}

export function setRefundBank(type, bank) {
  return { type, bank };
}

export function setRefundInfo(type, info) {
  return { type, info };
}

export function setPackage(type, course, status = '') {
  return { type, course, status };
}

export function setPurchased(type, purchases) {
  return { type, purchases };
}

export function setLiveScripts(list) {
  return { type: SET_LIVE_SCRIPTS, list };
}

export function resetTarget() {
  return { type: RESET_TARGET };
}

export function restMobileList() {
  return { type: RESET_MOBILE_LIST };
}

export function setMobileList() {
  return { type: SET_MOBILE_LIST };
}

export function initialTarget() {
  return dispatch => {
    dispatch(resetTarget());
  };
}

export function setRecommendCourses(type, list) {
  return { type, list };
}
export function setLearningHistory(type, labels, list) {
  return { type, labels, list };
}
export function initialRefund(auth) {
  const {
    name = '',
    personalId = '',
    phone = '',
  } = auth || {};

  return dispatch => {
    const initial = {
      status: false,
      reason: '',
      bank: '',
      branchCode: '',
      account: '',
      name,
      personalId,
      phone,
    };
    dispatch(setRefundInfo(SET_REFUND_INFO, initial));
  };
}

export function getCourseList(type) {
  return async dispatch => {
    const { success, list } = await courseRequest.fetchCourseList(`sort=${type}&pageSize=16`);
    if (success === true) {
      dispatch(setCourseList(SET_LIST, list));
    }
  };
}

export function fetchFilterCourse(type, category, coursetype, attr, theme, page) {
  return async dispatch => {
    let query = 'pageSize=16';
    if (type) { query += `&sort=${type}`; }
    if (category) { query += `&category=${category}`; }
    if (coursetype.length !== 0) {
      query += `&${queryString.stringify({ coursetype }, { arrayFormat: 'bracket' })}`;
    }
    if (attr) { query += `&attribute=${attr}`; }
    if (theme) { query += `&theme=${theme}`; }
    if (page) { query += `&page=${page}`; }

    const { success, list, meta } = await courseRequest.fetchCourseList(query);
    if (success === true) {
      dispatch(setCourseList(SET_LIST, list));
      dispatch(setCourseListMeta(GET_LIST_META, meta));
    }
  };
}

export function fetchRelatedCourse(category, inclass, id) {
  return async dispatch => {
    const { success, list } = await courseRequest.fetchCourseList(
      `pageSize=4&category=${category}&inClass=${inclass}&excludeIds=${id}`);
    if (success === true) {
      dispatch(setCourseList(SET_RELATED_LIST, list));
    }
  };
}

export function getCourseHotList(haveMeta) {
  return async dispatch => {
    const { success, list, meta } = await courseRequest.fetchCourseList('sort=hot&pageSize=8');
    if (success === true) {
      dispatch(setCourseList(SET_HOT_LIST, list));
      if (haveMeta) {
        dispatch(setCourseListMeta(GET_LIST_META, meta));
      }
    }
  };
}

export function getCourseCompalList() {
  return async dispatch => {
    const { success, list } = await courseRequest.fetchCourseList('category=COMPAL&pageSize=8');
    if (success === true) {
      dispatch(setCourseList(SET_COMPAL_LIST, list));
    }
  };
}

export function getCourseCreditList() {
  return async dispatch => {
    const { success, list } = await courseRequest.fetchCourseList('category=CREDIT&pageSize=8');
    if (success === true) {
      dispatch(setCourseList(SET_CREDIT_LIST, list));
    }
  };
}

export function getCourseTrainingList() {
  return async dispatch => {
    const { success, list } = await courseRequest.fetchCourseList('category=TRAINING&pageSize=8');
    if (success === true) {
      dispatch(setCourseList(SET_TRAINING_LIST, list));
    }
  };
}

export function fetchTargetCourse(id) {
  return async dispatch => {
    const { course, status, student } = await courseRequest.fetchTarget(id);
    if (!course) {
      dispatch(forwardTo(routePath.root));
      return;
    }

    let isApplyExpired = false;
    const { creditPointEndAt } = course;
    if (creditPointEndAt) {
      isApplyExpired = moment().isAfter(moment(moment(creditPointEndAt).format('YYYY-MM-DD 23:59:59')));
    }

    dispatch(setTargetList(SET_TARGET, course, status, student, isApplyExpired));
  };
}

export function getMyCourseList() {
  return async dispatch => {
    const {
      success,
      sumup,
      finished,
      unfinished,
      creditApplyStudies,
      wish,
    } = await courseRequest.fetchMyCourseList();

    if (success === true) {
      dispatch(setCourseList(SET_MY_LIST,
        {
          sumup,
          finished,
          unfinished,
          creditApplyStudies,
          wish,
        },
      ));
    }
  };
}

export function getNaviCourse() {
  return async dispatch => {
    const { success, user } = await authRequest.fetchAuthStatus();
    if (success === true) {
      dispatch(setCourseList(SET_NAVI_LIST, user.studies));
    }
  };
}

export function rateCourse(rating) {
  return async dispatch => {
    const { success } = await courseRequest.rateCourse(rating);
    if (success) {
      dispatch(fetchTargetCourse(rating.courseId));
    }
  };
}

export function setCourseProgress(progress, newCourse, refresh = true) {
  return async dispatch => {
    const { success } = await courseRequest.updateCourseProgress(progress);
    if (success) {
      if (refresh) dispatch(fetchTargetCourse(newCourse || progress.courseId));
      dispatch(getNaviCourse());
    }
  };
}

export function getSearchCourseList(word, page) {
  return async dispatch => {
    const { success, list, meta } = await courseRequest.fetchCourseList(`search=${encodeURIComponent(word)}&pageSize=16&page=${page}`);
    if (success === true) {
      dispatch(setCourseList(SET_SEARCH_LIST, list));
      dispatch(setCourseListMeta(GET_SEARCH_META, meta));
    }
  };
}

export function getAdvancedSearchCourseList(types, isCredit, host, location, startDate, endDate, page) {
  return async dispatch => {
    const queryList = [];
    if (Array.isArray(types)) {
      queryList.push(...types.map(type => `type[]=${encodeURIComponent(type)}`));
    }
    if (typeof isCredit === 'boolean') queryList.push(`isCredit=${isCredit}`);
    if (host) queryList.push(`host=${encodeURIComponent(host)}`);
    if (location) queryList.push(`location=${encodeURIComponent(location)}`);
    if (startDate && /^\d{4}-\d{2}-\d{2}$/.test(startDate)) queryList.push(`startDate=${startDate}`);
    if (endDate && /^\d{4}-\d{2}-\d{2}$/.test(endDate)) queryList.push(`endDate=${endDate}`);
    queryList.push('pageSize=16');
    if (page) queryList.push(`page=${page}`);

    const { success, list, meta } = await courseRequest.fetchAdvancedCourseList(queryList.join('&'));
    if (success === true) {
      dispatch(setCourseList(SET_SEARCH_LIST, list));
      dispatch(setCourseListMeta(GET_SEARCH_META, meta));
    }
  };
}

export function submitQuiz(answer) {
  return async dispatch => {
    const { success } = await courseRequest.submitQuiz(answer);
    if (success) {
      dispatch(fetchTargetCourse(answer.courseId));
    }
  };
}

export function getCourseFilterOption() {
  return async dispatch => {
    const { success, categories } = await courseRequest.courseFilterOption();
    if (success === true) {
      dispatch(setFilterOption(GET_FILTER_OPTION, categories));
    }
  };
}


export function getCourseCalendar(searchDate) {
  return async dispatch => {
    const { success, data } = await courseRequest.courseCalendar(searchDate);
    if (success === true) {
      dispatch(setCalendar(GET_CALENDAR, data));
    }
  };
}

export function saveFilterQuery(type, category, coursetype, attr, theme, page) {
  return {
    type: SAVE_FILTER_QUERY,
    query: {
      type,
      category,
      coursetype,
      attr,
      theme,
      page,
    },
  };
}

export function userCreditCheck(postData) {
  return async dispatch => {
    const { success } = await courseRequest.userCreditCheck(postData);
    if (success) {
      dispatch(fetchTargetCourse(postData.courseId));
    }

    return Promise.resolve({ success });
  };
}

export function submitCredit(postData) {
  return async dispatch => {
    const {
      success,
      err,
      study,
      showOrderPage,
      creditProduct } = await courseRequest.submitCredit(postData);
    if (success) {
      dispatch(fetchTargetCourse(postData.courseId));
      dispatch(getAuthStatus());
    }

    return Promise.resolve({ success, err, study, showOrderPage, creditProduct });
  };
}

export function courseRefund(postData) {
  return async dispatch => {
    const { success } = await courseRequest.courseRefund(postData);
    if (success) {
      dispatch(openGlobalModal('courseRefundSuccess'));
      dispatch(getOrderList());
    }
  };
}

export function startCourse(id) {
  return async dispatch => {
    const { success } = await courseRequest.startCourse(id);
    if (success) {
      dispatch(fetchTargetCourse(id));
    }
  };
}

export function getRefundBank() {
  return async dispatch => {
    const { success, banks } = await courseRequest.fetchRefundBank();
    if (success) {
      dispatch(setRefundBank(SET_REFUND_BANK, banks));
    }
  };
}

export function getRefundInfo(id) {
  return async dispatch => {
    const { success, refund } = await courseRequest.fetchRefundInfo(id);
    if (success) {
      if (refund && refund.status === 'APPLYING') {
        dispatch(setRefundInfo(
          SET_REFUND_INFO,
          {
            status: true,
            reason: refund.reason,
            bank: (refund.bankcode && refund.bankname) ? `${refund.bankcode} ${refund.bankname}` : '',
            branchCode: (refund.branchBank?.code && refund.branchBank?.name) ?
              `${refund.branchBank.code} ${refund.branchBank.name}` : '',
            account: refund.bankaccount || '',
            name: refund.accountname || '',
            personalId: refund.personalId || '',
            phone: refund.phone || '',
          },
        ));
      } else {
        dispatch(setRefundInfo(
          SET_REFUND_INFO,
          {
            status: false,
            reason: '',
            bank: '',
            branchCode: '',
            account: '',
            name: '',
            personalId: '',
            phone: '',
          },
        ));
      }
    }
  };
}

export function fetchPackage(id) {
  return async dispatch => {
    const { course, status } = await courseRequest.fetchPackage(id);
    if (!course) {
      dispatch(forwardTo(routePath.root));
    } else {
      dispatch(setPackage(SET_PACKAGE, course, status));
    }
  };
}

export function fetchPurchased() {
  return async dispatch => {
    const { success, purchases } = await courseRequest.fetchPurchased();
    if (success) {
      dispatch(setPurchased(SET_PURCHASED, purchases));
    }
  };
}

export function signInCourse(studyId, courseId) {
  return async dispatch => {
    const { success, err } = await courseRequest.signInCourse(studyId);
    if (!success) {
      const { message } = err || {};
      alert(`簽到發生未知的錯誤${message ? `(${message})` : ''}`);
    } else if (courseId) {
      dispatch(fetchTargetCourse(courseId));
    }
  };
}

export function signOutCourse(studyId, courseId) {
  return async dispatch => {
    const { success, content, err } = await courseRequest.signOutCourse(studyId);
    const { completeDraw = false } = content || {};
    if (!success) {
      const { message } = err || {};
      alert(`簽退發生未知的錯誤${message ? `(${message})` : ''}`);
    } else if (courseId) {
      dispatch(fetchTargetCourse(courseId));
    }

    return { success, completeDraw };
  };
}

export function getLiveScripts(id) {
  return async dispatch => {
    const { success, content } = await liveScriptRequest.fetchLiveScripts(id);
    if (success && content && content.content) {
      dispatch(setLiveScripts(content.content));
    }
  };
}

export function initialLiveScripts() {
  return async dispatch => {
    dispatch(setLiveScripts([]));
  };
}

export function getCourseLiveTime(id) {
  return async () => {
    const { success, liveTime } = await courseRequest.fetchCourseLiveTime(id);
    if (success) {
      return Promise.resolve(liveTime);
    }

    return Promise.reject();
  };
}

export function downloadCertificate(id) {
  return async () => {
    try {
      await courseRequest.downloadCompletionCertificate(id);
    } catch (err) {
      throw err;
    }
  };
}

export function downloadCompletionCertificate(id) {
  return async () => {
    try {
      const { success, data: { qualified } } = await utilsRequest.qualifications(2, id);
      if (!success) {
        alert('下載訓練證明時發生不可預期的錯誤');
        return;
      }

      if (!qualified) {
        alert('您沒有權限下載訓練證明');
        return;
      }

      await courseRequest.downloadCompletionCertificate(id);
    } catch (err) {
      throw err;
    }
  };
}

export function getRecommendCourses(id) {
  return async dispatch => {
    const { success, list } = await courseRequest.getRecommendCourses(id);
    if (success) {
      dispatch(setRecommendCourses(GET_RECOMMEND_COURSES, list));
    }
  };
}

export function submitWillingRecommendCourses(data, courseId) {
  return async dispatch => {
    await courseRequest.setWillingCourse(data);

    if (courseId) {
      dispatch(fetchTargetCourse(courseId));
    }
  };
}

export function getLearningHistory() {
  return async dispatch => {
    const { success, labels, list } = await courseRequest.getLearningHistory();
    if (success) {
      dispatch(setLearningHistory(GET_LEARNING_HISTORY, labels, list));
    }
  };
}

export function downloadHandoutWithoutQualifications(serial, fileName, onProgress) {
  return async () => {
    const { success, data } = await handoutsRequest.fetchHandouts(serial);
    if (!success) {
      alert('找不到講義資料');
      return false;
    }

    try {
      if (!await generateHandoutWithWatermark(data, fileName, onProgress)) {
        alert('找不到講義資料');
        return false;
      }
    } catch (error) {
      log('error :>> ', error);
      alert('下載講義時發生不可預期的錯誤');
      return false;
    }

    return true;
  };
}

export function downloadHandout(studyId, serial, fileName, onProgress) {
  return async dispatch => {
    // TODO: Remove late
    const result = await utilsRequest.qualifications(1, studyId);
    if (!result.success) {
      alert('下載講義時發生不可預期的錯誤');
      return false;
    }

    if (!result?.data?.qualified) {
      alert('您沒有權限下載講義');
      return false;
    }

    const downloadResult =
      await dispatch(downloadHandoutWithoutQualifications(serial, fileName, onProgress));
    return downloadResult;
  };
}

export function initialMobileList() {
  return dispatch => {
    dispatch(restMobileList());
  };
}

export function getMobileList() {
  return dispatch => {
    dispatch(setMobileList());
  };
}

/**
 * 同意小卡到期通知
 * @param {string} courseId 課程 ID
 * @returns Action function
 */
export function agreeToExpirationNotice(courseId) {
  return async () => {
    await courseRequest.agreeToExpirationNotice(courseId);
  };
}
