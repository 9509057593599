/**
 * FeaturePath: 會員系統-其它-工具
 * Accountable: Landy Chu, AlexCH Cheng
 */

import React, { useEffect } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';

import { closeAlert } from 'react/actions/utils';

import styles from './styles.css';

const SHOW_TIME = 2000;

export const AlertComponent = ({ className, type, message }) => (
  <div className={classnames(styles.alert, { [styles.warning]: type === 'warning' }, className)}>
    <div className={styles.content}>
      {type === 'warning' ? <i className="icon exclamation triangle" /> : <i className="icon check circle" />}
      <span>{message}</span>
    </div>
  </div>
);

const AlertModal = ({ className, alert, close }) => {
  useEffect(() => {
    if (alert.active) {
      setTimeout(() => close(), SHOW_TIME);
    }
  }, [alert.active]);

  return (
    <div className={classnames(styles.modal, { [styles.active]: alert.active })}>
      <AlertComponent className={className} type={alert.type} message={alert.message} />
    </div>
  );
};

// Which props do we want to inject, given the global state?
function mapStateToProps({ utils: { alert } }) {
  return {
    alert,
  };
}

function mapDispatchToProps(dispatch) {
  return ({
    close: () => dispatch(closeAlert()),
  });
}

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps, mapDispatchToProps)(AlertModal);
