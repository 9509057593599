/**
 * FeaturePath: 會員系統-個人資訊-學習履歷
 * Accountable: Landy Chu, AlexCH Cheng
 */

import React, { lazy, Suspense } from 'react';

import LoadingDimmer from '../components/Utils/LoadingDimmer';

const LearningHistory = lazy(() => import('../components/Pages/LearningHistory'));

export const LearningHistoryPage = props => (
  <Suspense fallback={LoadingDimmer()}>
    <LearningHistory {...props} />
  </Suspense>
);
