/**
 * FeaturePath: 會員系統-其它-工具
 * Accountable: Landy Chu, AlexCH Cheng
 */

import * as R from 'ramda';
import history from 'react/utils/history';

import {
  SENDING_REQUEST,
  LOADING_REQUEST,
  OPEN_ALERT,
  CLOSE_ALERT,
  TOGGLE_NAVI,
  TOGGLE_SEARCH,
  TOGGLE_ADVANCED_SEARCH,
  LOADING_DIALOG_REQUEST,
  LOGIN_DIALOG_REQUEST,
  SET_IS_TRANSITIONS,
  REGISTER_DIALOG_REQUEST,
  SET_WORK_CERT_VALIDITY_STATUS,
  SET_USER_ADDRESS,
} from 'react/constants/utils';

import {
  LOGIN_FORM_SELECTOR,
  REGISTER_FORM_SELECTOR,
} from 'react/constants/auth';

import { setLoginError, setRegisterError } from 'react/actions/auth';

import utilsReq from '../request/utils';

let lastNoteType = '';

/**
 * Forwards the user
 * @param {string} location The route the user should be forwarded to
 */
export function forwardTo(location) {
  return () => history.push(location);
}

/**
 * Removes the last error from the form
 */
export function removeLastFormNote(selector) {
  const form = document.querySelector(selector);
  if (form) {
    form.classList.remove(`js-form__note--${lastNoteType}`);
  }
}

/**
 * Called when a request failes
 * @param  {object} note An object containing information about the error
 * @param  {string} note.type The js-form__note + note.type class will be set on the form
 */
export function requestNoted(selector, note) {
  // Remove the class of the last error so there can only ever be one
  removeLastFormNote(selector);
  const form = document.querySelector(selector);
  if (!form) {
    return;
  }
  // And add the respective classes
  form.classList.add('js-form__note');
  form.classList.add('js-form__note-animation');
  form.classList.add(`js-form__note--${note.type}`);
  lastNoteType = note.type;
  // Remove the animation class after the animation is finished, so it
  // can play again on the next error
  setTimeout(() => {
    form.classList.remove('js-form__note-animation');
  }, 150);
}
/**
 * Checks if any elements of a JSON object are empty
 * @param  {object} elements The object that should be checked
 * @return {boolean}         True if there are empty elements, false if there aren't
 */
export function anyElementsEmpty(elements) {
  return Object.keys(elements).some(key => !elements[key]);
}

/**
 * Sets the requestSending state, which displays a loading indicator during requests
 * @param  {boolean} sending The new state the app should have
 * @return {object}          Formatted action for the reducer to handle
 */
export function sendingRequest(sending) {
  return { type: SENDING_REQUEST, sending };
}

/**
 * Sets the requestSending state, which displays a loading indicator during requests
 * @param  {boolean} loading The new state the app should have
 * @return {object}          Formatted action for the reducer to handle
 */
export function loadingRequest(loading) {
  return { type: LOADING_REQUEST, loading };
}

/**
 * Sets the alert state, close the alert modal
 * @return {object}          Formatted action for the reducer to handle
 */
export function closeAlert() {
  return { type: CLOSE_ALERT };
}

/**
 * Sets the alert state, which displays a alert modal with message
 * @param  {string} message The new state the app should have
 * @return {object}          Formatted action for the reducer to handle
 */
export function openWarningAlert(message) {
  return {
    type: OPEN_ALERT,
    alert: { type: 'warning', message },
  };
}

/**
 * @param  {boolean} naviToggle The new state the app should have
 * @return {object}          Formatted action for the reducer to handle
 */
export function toggleNavi(naviToggle) {
  return { type: TOGGLE_NAVI, naviToggle };
}

/**
 * @param  {boolean} searchToggle The new state the app should have
 * @return {object}          Formatted action for the reducer to handle
 */
export function toggleSearch(searchToggle) {
  return { type: TOGGLE_SEARCH, searchToggle };
}

/**
 * @param  {boolean} advancedSearchToggle The new state the app should have
 * @return {object}          Formatted action for the reducer to handle
 */
export function toggleAdvancedSearch(advancedSearchToggle) {
  return { type: TOGGLE_ADVANCED_SEARCH, advancedSearchToggle };
}

export function loadingDialogRequest(loadingDialog) {
  return {
    type: LOADING_DIALOG_REQUEST,
    loadingDialog,
  };
}

export function loginDialogRequest(isShow) {
  return {
    type: LOGIN_DIALOG_REQUEST,
    isShow,
  };
}

export function setIsTransitions(transitions) {
  return {
    type: SET_IS_TRANSITIONS,
    transitions,
  };
}
export function registerDialogRequest(isShow, isActivitiesRegister) {
  return {
    type: REGISTER_DIALOG_REQUEST,
    isShow,
    isActivitiesRegister,
  };
}

export function setWorkCertValidity(data) {
  return {
    type: SET_WORK_CERT_VALIDITY_STATUS,
    data,
  };
}

export function setUserAddress(data) {
  return {
    type: SET_USER_ADDRESS,
    data,
  };
}

export function openLoadingDialog(description) {
  return dispatch => {
    dispatch(loadingDialogRequest({
      isShow: true,
      description,
    }));
  };
}

export function closeLoadingDialog() {
  return dispatch => {
    dispatch(loadingDialogRequest({
      isShow: false,
      description: '',
    }));
  };
}

export function openLoginDialog() {
  return dispatch => {
    dispatch(loginDialogRequest(true));
  };
}

export function openRegisterDialog(isActivitiesRegister) {
  return dispatch => {
    dispatch(registerDialogRequest(true, isActivitiesRegister));
  };
}

export function closeLoginDialog() {
  return dispatch => {
    dispatch(setLoginError(''));
    dispatch(setRegisterError(''));
    removeLastFormNote(LOGIN_FORM_SELECTOR);
    removeLastFormNote(REGISTER_FORM_SELECTOR);
    dispatch(loginDialogRequest(false));
    dispatch(registerDialogRequest(false, false));
  };
}


export function onTransitionsPage(isTransitions) {
  return dispatch => {
    dispatch(setIsTransitions(isTransitions));
  };
}

export function getWorkCertValidity(products, scenario) {
  return async () => {
    const { success, data, err } = await utilsReq.checkWorkCertValidity(products, scenario);
    if (success === true) {
      const { message } = data;
      return {
        isShowMessage: !R.isEmpty(message),
        message,
      };
    }
    return err;
  };
}


export function getPostInfo() {
  return async () => {
    const { success, data, err } = await utilsReq.getPostInfo();
    if (success === true) {
      return data;
    }
    return err;
  };
}

export function getUserAddress() {
  return async dispatch => {
    const { success, data } = await utilsReq.getUserAddress();
    const { list } = data;
    if (success === true) {
      dispatch(setUserAddress(list));
    }
  };
}

export function createUsuallyAddress(addressData) {
  return async () => {
    const { success, err } = await utilsReq.createUsuallyAddress(addressData);
    if (success === true) {
      return true;
    }
    return err;
  };
}

export function updateUsuallyAddress(addressId, addressData) {
  return async () => {
    const { success, err } = await utilsReq.updateUsuallyAddress(addressId, addressData);
    if (success === true) {
      return true;
    }
    return err;
  };
}

export function deleteUsuallyAddress(addressId) {
  return async () => {
    const { success, err } = await utilsReq.deleteUsuallyAddress(addressId);
    if (success === true) {
      return true;
    }
    return err;
  };
}
