/**
 * FeaturePath: 學員管理-資料管理-抽獎-抽獎
 * Accountable: Landy Chu, AlexCH Cheng
 */

import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';

import PopupModal from 'react/components/Utils/Modal/PopupModal';

import {
  giveUpDraw,
  getUnDrawList,
  getLuckDraw,
} from 'react/actions/activities';

import Button from '../../../../Utils/Button/Button';
import styles from './styles.css';


const UnDrawListComponent = ({
  list,
  onClose,
  isClosedUndrawList,
  setIsShowLuckyDraw,
  onGiveUpDraw,
  fetchUnDrawList,
  onGetLuckDraw,
}) => {
  const isShowModal = list.length !== 0 && !isClosedUndrawList;
  return (
    <PopupModal className={styles.modal} modalVisible={isShowModal}>
      <div className={styles.wrapper}>
        感謝您參加註冊會員抽好禮活動，
        <br />
        請點選下方 『抽獎』按鈕進行抽獎
        {
          list.map(activity => (
            <div
              className={styles.activityItem}
              key={activity.drawRecordId}
            >
              <div className={styles.buttonContainer}>
                <Button
                  className={styles.button}
                  onClick={() => {
                    setIsShowLuckyDraw(true);
                    onGetLuckDraw(R.pathOr('', ['drawRecordId'], activity));
                    onClose();
                  }}
                >
                  抽獎
                </Button>
                <Button
                  className={styles.buttonGiveUp}
                  onClick={() => {
                    onGiveUpDraw(activity.drawRecordId).then(() => {
                      fetchUnDrawList();
                    });
                  }}
                >
                  放棄
                </Button>
              </div>
            </div>
          ))
        }
      </div>
    </PopupModal>
  );
};

function mapStateToProps() {
  return {
  };
}

const mapDispatchToProps = dispatch => ({
  onGiveUpDraw: drawRecordId => dispatch(giveUpDraw(drawRecordId)),
  onGetLuckDraw: drawRecordId => dispatch(getLuckDraw(drawRecordId)),
  fetchUnDrawList: () => dispatch(getUnDrawList()),
});


export default connect(mapStateToProps, mapDispatchToProps)(UnDrawListComponent);
