/**
 * FeaturePath: 會員系統-其它-工具
 * Accountable: Landy Chu, AlexCH Cheng
 */

import React, { Component, createRef, RefObject } from 'react';
import * as R from 'ramda';
import classnames from 'classnames';
import DropdownIcon from 'images/icon/dropdown.svg';

import styles from './styles.module.scss';

interface IDropDownProps {
  /** 下拉選單的類別名稱 */
  className?: string;
  /* 是否顯示錯誤狀態 */
  error?: boolean;
  /* 下拉選單標籤 */
  label?: string;
  /* 下拉選單的選項列表 */
  options: Array<string | OptionProps>;
  /* 是否啟用物件形式的選項 */
  coded?: boolean;
  /* 目前選取的值 */
  value?: string;
  /* 是否禁用下拉選單 */
  disabled?: boolean;
  /* 當選項改變時的回呼函式 */
  onChange: (value: string) => void;
  name: string
}

interface IDropDownStates {
  /** 目前選取的值 */
  value: string;
  /* 是否顯示下拉選單 */
  dropdown: boolean;
}

interface OptionProps {
  /** 選項的ID */
  id: string;
  /* 選項的值 */
  value: string;
}

interface ITriangleIconProps {
  /** 是否顯示下拉選單 */
  dropdown: boolean;
  /* 是否禁用元素 */
  disabled?: boolean;
  /* 當元素被點擊時的回呼函式 */
  onClick?: () => void;
}

const TriangleIcon = ({ dropdown, disabled, onClick }: ITriangleIconProps) => (
  <div
    className={classnames({ [styles.disabled]: disabled })}
    onClick={onClick}
  >
    <img
      src={DropdownIcon}
      alt=""
      className={classnames(
        styles.dropdownIcon, { [styles.rotate]: dropdown })}
    />
  </div>
);

class Dropdown extends Component<IDropDownProps, IDropDownStates> {
  constructor(props: IDropDownProps) {
    super(props);

    this._handleOutsideClick = this._handleOutsideClick.bind(this);
    this.state = {
      value: props.value || '',
      dropdown: false,
    };
  }

  private refDrop: RefObject<HTMLDivElement> = createRef<HTMLDivElement>();

  _handleOutsideClick(e: MouseEvent) {
    // ignore clicks on the component itself
    if (this.refDrop.current && !this.refDrop.current.contains(e.target as Node)) {
      this._onDropdown();
    }
  }

  _onChange(value: OptionProps | string) {
    const { coded, disabled } = this.props;
    if (disabled) return;
    const showValue = (coded ? R.pathOr('', ['value'], value) : value) as string;
    const storeValue = (coded ? R.pathOr('', ['id'], value) : value) as string;

    if (showValue !== '') {
      this.setState(() => ({ value: showValue }));
    } else {
      this.setState(() => ({ value: '' }));
    }
    this._onDropdown();
    // Transmit to parent
    if (this.props.onChange) {
      this.props.onChange(storeValue);
    }
  }

  _onDropdown() {
    if (this.props.disabled) {
      return;
    }

    if (!this.state.dropdown) {
      // attach/remove event handler
      document.addEventListener('click', this._handleOutsideClick, false);
    } else {
      document.removeEventListener('click', this._handleOutsideClick, false);
    }
    this.setState({ dropdown: !this.state.dropdown });
  }

  render() {
    const {
      className,
      error = false,
      label,
      options,
      coded,
      value,
      disabled,
    } = this.props;

    const optionItems = options.map(option => {
      const id = R.pathOr(null, ['id'], option);
      if (coded || id) {
        return (
          <a
            key={id}
            className={styles.item}
            onClick={() => this._onChange(option)}
          >
            {R.pathOr(null, ['value'], option)}
          </a>
        );
      }

      return (
        <a
          key={option as string}
          className={styles.item}
          onClick={() => this._onChange(option)}
        >
          {option as string}
        </a>
      );
    });
    return (
      <div
        className={classnames(styles.select, className, {
          [styles.valid]: value && !error,
          [styles.dropdown]: this.state.dropdown,
        })}
        ref={this.refDrop}
      >
        <div
          style={
            disabled
              ? {
                color: '#B5B5B5',
                cursor: 'not-allowed',
              }
              : {}
          }
          className={classnames(styles.header)}
          onClick={() => this._onDropdown()}
        >
          {label}
        </div>
        <TriangleIcon
          dropdown={this.state.dropdown}
          disabled={disabled}
          onClick={() => this._onDropdown()}
        />
        <div
          className={styles.menu}
          style={{
            height: this.state.dropdown ? `${30 * options.length + 2}px` : '0',
            display: this.state.dropdown ? 'block' : 'none',
          }}
        >
          {optionItems}
        </div>
      </div>
    );
  }
}

export default Dropdown;
