/**
 * FeaturePath: 會員系統-其它-工具
 * Accountable: Landy Chu, AlexCH Cheng
 */

import React, { useCallback } from 'react';

import PopupModal from 'react/components/Utils/Modal/PopupModal';
import Button from '../../../Button/Button';

import { LINE_URL } from '../../../../../constants/website';
import { isPhoneNumber } from '../../../../../utils/validator';

import styles from './styles.css';

const RegisterSuccess = ({
  isShow,
  account,
  isCustomRegister,
  onConfirmClick,
}) => {
  const handleClick = useCallback(event => {
    event.preventDefault();

    if (onConfirmClick) {
      onConfirmClick();
    }
  }, [onConfirmClick]);

  return (
    <PopupModal className={styles.modal} modalVisible={isShow}>
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <div style={{ color: 'red' }}>{isCustomRegister ? '移轉成功' : '註冊成功'}</div>
          {
            isPhoneNumber(account) &&
            <>
              {`請使用帳號 ${account} `}
              <div>{isCustomRegister ? '重新' : ''}登入 仁寶 i 學習™</div>
            </>
          }
          {
            !isPhoneNumber(account) &&
            <div className={styles.foreign}>
              <span>請聯繫客服協助帳號開通</span>
              <a target="_blank" rel="noopener noreferrer" href={LINE_URL}>
                Line 線上客服
              </a>
              <span>
                Foreign nationals, please contact customer service to complete membership registration.
              </span>
            </div>
          }
        </div>
        <Button
          onClick={handleClick}
          className={styles.button}
        >
          確定
        </Button>
      </div>
    </PopupModal>
  );
};

export default RegisterSuccess;
