exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._35FiL6wRs36AkKZA1SEKCx{color:#ff4c4c;padding:.5em 1em;font-size:1em}._3uCqRgYRb5CWOaXuqcfKQk{display:flex;justify-content:space-between;align-items:flex-end}._3uCqRgYRb5CWOaXuqcfKQk>div{display:flex;flex-direction:row}@media(max-width:720px){._3uCqRgYRb5CWOaXuqcfKQk>div{flex-direction:column}}._3uCqRgYRb5CWOaXuqcfKQk>div>p{color:#999;font-weight:500}._3h5joA3kboItq_ROfU0ctC{display:flex;flex-direction:column;color:#999;margin-top:-8px}.kJaBV1GmNqUAhIvadJ--8{width:100%}.kJaBV1GmNqUAhIvadJ--8>textarea{width:100%;height:100px;resize:none}.kJaBV1GmNqUAhIvadJ--8>div{margin:1em 0}.kJaBV1GmNqUAhIvadJ--8>div>label{padding:0 .5em}", ""]);

// exports
exports.locals = {
	"tablet": "1100",
	"mobile": "720",
	"error": "_35FiL6wRs36AkKZA1SEKCx",
	"verifyCodeRow": "_3uCqRgYRb5CWOaXuqcfKQk",
	"foreignContent": "_3h5joA3kboItq_ROfU0ctC",
	"articleField": "kJaBV1GmNqUAhIvadJ--8"
};