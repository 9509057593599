/**
 * FeaturePath: 學員管理-資料管理-購物車-加入購物車
 * FeaturePath: 學員管理-資料管理-購物車-移出購物車
 * FeaturePath: 學員管理-資料管理-購物車-查看購物車
 * FeaturePath: 學員管理-資料管理-購物車-未登入購物車
 * Accountable: Tom Jhuang, Landy Chu
 */

export const SET_CART_LIST = 'SET_CART_LIST';
export const SET_VALID_COUPON = 'SET_VALID_COUPON';
export const SET_COUPON_INFO = 'SET_COUPON_INFO';
export const SET_ORDER_LIST = 'SET_ORDER_LIST';
export const SET_BRANCH_BANK_LIST = 'SET_BRANCH_BANK_LIST';
export const SET_ORDER_DETAIL = 'SET_ORDER_DETAIL';
export const SET_CONFIRM_CART_LIST = 'SET_CONFIRM_CART_LIST';
export const SET_ORDER_RECORD_LIST = 'SET_ORDER_RECORD_LIST';
