/**
 * FeaturePath: 會員系統-個人資訊-證照登錄
 * Accountable: Landy Chu, AlexCH Cheng
 */

import Loadable from 'react-loadable';

import LoadingDimmer from '../components/Utils/LoadingDimmer';

export const LicenseEditPage = Loadable({
  loader() {
    return import('../components/Pages/LicenseEdit').then(object => object.default);
  },
  loading: LoadingDimmer,
});
