/**
 * FeaturePath: 不需處理
 * Accountable: Tom Jhuang, AlexCH Cheng
 */

import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { Link as ScrollLink } from 'react-scroll';
import { Link } from 'react-router-dom';

import {
  EMAIL,
  LINE_URL,
  PRIVACY_POLICY_URL,
  TERMS_OF_SERVICE_URL,
  FB_URL,
} from 'react/constants/website';

import { openLoginDialog } from 'react/actions/utils';

import MainButton from 'react/components/Utils/Button/MainButton';

import logoImg from 'images/logo_icon.svg';
import compalImg from 'images/compal.png';

import { COMPANY_NAME, ADDRESS, UNIFIED_ID, COMPANY_HEALTHY_NAME, UNIFIED_HEALTHY_ID } from '../../constants/website';

import packageJson from '../../../../package.json';

import styles from './styles.module.css';

const SCROLL_DURATION = 500;

const Footer = ({
  authStatus,
  onOpenLoginDialog,
}) => {
  const handleLoginClick = useCallback(evt => {
    evt.preventDefault();
    if (!authStatus) {
      onOpenLoginDialog();
    }
  }, [authStatus]);

  return (
    <footer className={styles.footer}>
      <div className={styles.container}>
        <div className={styles.left}>
          <div className={styles.logoWrap}>
            <Link to="/">
              <img src={logoImg} alt="" />
            </Link>
          </div>
        </div>
        <div className={classnames(styles.menu, authStatus && styles.authMenu)}>
          <p className={styles.title}>仁寶 i 學習™</p>
          <ul>
            {
              !authStatus &&
              <li>
                <div style={{ cursor: 'pointer' }} onClick={handleLoginClick}>
                  會員登入
                </div>
              </li>
            }
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={FB_URL}
              >
                FB 粉絲團
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={LINE_URL}
              >
                Line 線上客服
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={TERMS_OF_SERVICE_URL}
              >
                服務條款
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={PRIVACY_POLICY_URL}
              >
                隱私權政策
              </a>
            </li>
          </ul>
        </div>
        <div className={styles.menu}>
          <p className={styles.title}>聯絡我們</p>
          <p className={styles.gradualText}>
            <a href={`mailto:${EMAIL}`}>{`E | ${EMAIL}`}</a>
          </p>
        </div>
        <p className={styles.content}>
          FROM VISION TO REALITY<br />
          <span>Innovation Empowered</span>
        </p>
        <div className={styles.toTop}>
          <ScrollLink
            activeClass="active"
            to="top"
            smooth="true"
            duration={SCROLL_DURATION}
          >
            <MainButton color="gray" height="40px">回到頂部</MainButton>
          </ScrollLink>
        </div>
      </div>
      <div className={styles.bottom}>
        <Link className={styles.logoLink} to="/">
          <img src={compalImg} alt="" />
        </Link>
        <p>{COMPANY_NAME}</p>
        <p>{UNIFIED_ID}</p>
        <p>{COMPANY_HEALTHY_NAME}</p>
        <p>{UNIFIED_HEALTHY_ID}</p>
        <p>{ADDRESS}</p>
        <p>{`Copyright © 2022 COMPAL Inc. | v${packageJson.version}`}</p>
      </div>
      <div className={styles.pcBottom}>
        <p>{`${COMPANY_NAME} | ${UNIFIED_ID} | ${COMPANY_HEALTHY_NAME} | ${UNIFIED_HEALTHY_ID} | ${ADDRESS}`}</p>
        <p>{`Copyright © 2022 COMPAL Inc. | v${packageJson.version}`}</p>
      </div>
    </footer>
  );
};

function mapStateToProps({
  auth: { status: authStatus },
}) {
  return {
    authStatus,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onOpenLoginDialog: () => dispatch(openLoginDialog()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
