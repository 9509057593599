/**
 * FeaturePath: 會員系統-其它-廣告-公告訊息
 * Accountable: AlexCH Cheng, Landy Chu
 */

// ----- import local modules -----
import { SET_POPUPS } from '../constants/home';
import homeRequest from '../request/home';

/**
 * 設定公告訊息
 * @param data 公告訊息
 */
export function setPopups(data: any) {
  return { type: SET_POPUPS, data };
}

/**
 * 取得公告訊息
 */
export function getPopups() {
  return async (dispatch: any) => {
    const { success, list } = await homeRequest.fetchPopup();
    if (success === true) {
      dispatch(setPopups(list));
    }
  };
}
