/**
 * FeaturePath: 會員系統-個人資訊-我的商品
 * Accountable: Landy Chu, AlexCH Cheng
 */

import objectAssign from 'object.assign';

import {
  SET_PRODUCT_LIST,
  SET_MY_PRODUCTS,
} from '../constants/products';
// Object.assign is not yet fully supported in all browsers, so we fallback to
// a polyfill
const assign = Object.assign || objectAssign;

// The initial application state
const initialState = {
  productList: [],
  myProducts: [],
};

// Takes care of changing the application state
export function products(state = initialState, action) {
  switch (action.type) {
    case SET_PRODUCT_LIST:
      return assign({}, state, {
        productList: action.list,
      });
    case SET_MY_PRODUCTS:
      return assign({}, state, {
        myProducts: action.list,
      });
    default:
      return state;
  }
}
