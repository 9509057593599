/**
 * FeaturePath: 會員系統-其它-工具
 * Accountable: AlexCH Cheng, Landy Chu
 */

import React, { ButtonHTMLAttributes } from 'react';
import classNames from 'classnames';

import styles from './styles.module.scss';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  transparent?: boolean;
  step?: boolean;
  icon?: string;
  small?: boolean;
  filled?: boolean;
  inverse?: boolean;
  disabled?: boolean;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const Button: React.FC<ButtonProps> = ({
  className,
  transparent,
  children,
  step,
  icon,
  small,
  filled,
  inverse,
  disabled,
  onClick,
  ...props
}) => (
  <button
    className={classNames(className, styles.button, {
      [styles.step]: step || icon,
      [styles.small]: small,
      [styles.filled]: filled,
      [styles.transparent]: transparent,
      [styles.inverse]: inverse,
      [styles.disabled]: disabled,
    })}
    onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (!disabled && onClick) {
        onClick(e);
      }
    }}
    {...props}
    disabled={disabled}
  >
    <div>
      {children}
      {step && <i className="ui chevron right icon" />}
      {icon && <i className={`ui ${icon} icon`} />}
    </div>
  </button>
);

export default Button;
