/**
 * FeaturePath: 會員系統-課程-課程搜尋
 * Accountable: Tom Jhuang, AlexCH Cheng
 */

import React, { lazy, Suspense } from 'react';

import LoadingDimmer from '../components/Utils/LoadingDimmer';

const Search = lazy(() => import('../components/Pages/Search'));

export const SearchPage = props => (
  <Suspense fallback={LoadingDimmer()}>
    <Search {...props} />
  </Suspense>
);
