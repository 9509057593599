exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".styles__button___1qu_4{width:100%;height:50px;border:1px solid #3caa8c;background-color:#3caa8c;color:#fff;border-radius:10px;font-size:18px}.styles__button___1qu_4:hover{opacity:.8}.styles__light___2RKKi{background-color:#fff;color:#3caa8c}.styles__light___2RKKi:hover{color:#fff;background-color:#3caa8c;opacity:.8}.styles__disabled___2KWBv{cursor:not-allowed;background-color:#c8c9ca!important;border:none}", ""]);

// exports
exports.locals = {
	"button": "styles__button___1qu_4",
	"light": "styles__light___2RKKi",
	"disabled": "styles__disabled___2KWBv"
};