/**
 * FeaturePath: 會員系統-其它-工具
 * Accountable: Landy Chu, AlexCH Cheng
 */

import React from 'react';
import classnames from 'classnames';
import Select from 'react-select';

// import ArrorIcon from 'images/icon/arrow.inline.svg';

import styles from './style.css';

// const arrowRenderer = () => <ArrorIcon className={styles.arrow} />;

const SelectBox = ({
  className,
  optionClassName,
  error,
  disabled,
  clearable = false,
  searchable = false,
  ...props
}) => (
  <Select
    className={classnames(className, styles.selectBox, {
      [styles.error]: error,
      [styles.disabled]: disabled,
    })}
    classNamePrefix="Select"
    optionClassName={classnames(optionClassName, styles.option)}
    // arrowRenderer={arrowRenderer}
    isDisabled={disabled}
    clearable={clearable}
    searchable={searchable}
    {...props}
  />
);

export default SelectBox;
