exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".styles__search-input___3sb4_{width:270px;height:30px;border-radius:25px}.styles__search-input___3sb4_>input{width:100%;height:30px;line-height:30px;background:hsla(0,0%,100%,.2);border:1px solid #fff;border-radius:25px;font-size:14px;color:#fff;padding-left:40px;outline:none;box-sizing:border-box}.styles__search-input___3sb4_>input:-webkit-autofill,.styles__search-input___3sb4_>input:-webkit-autofill:active,.styles__search-input___3sb4_>input:-webkit-autofill:focus,.styles__search-input___3sb4_>input:-webkit-autofill:hover{-webkit-box-shadow:0 0 0 30px #fff inset!important}.styles__search-input___3sb4_>input:-ms-input-placeholder{font-size:14px;line-height:30px;opacity:.65;color:#fff}.styles__search-input___3sb4_>input::placeholder{font-size:14px;line-height:30px;opacity:.65;color:#fff}.styles__search-input___3sb4_ .styles__img-wrap___35V6s{position:absolute;top:0;bottom:0;left:10px;margin:auto;height:20px;width:20px;cursor:pointer}", ""]);

// exports
exports.locals = {
	"search-input": "styles__search-input___3sb4_",
	"searchInput": "styles__search-input___3sb4_",
	"img-wrap": "styles__img-wrap___35V6s",
	"imgWrap": "styles__img-wrap___35V6s"
};