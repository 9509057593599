/**
 * FeaturePath: 會員系統-個人資訊-登入
 * Accountable: Landy Chu, AlexCH Cheng
 */

import jwtDecode from 'jwt-decode';

import routePath from 'react/constants/path';
import history from 'react/utils/history';

/**
 * Authentication lib
 * @type {Object}
 */
const windowsLocalStorage = window.localStorage;

const auth = {

  /**
  * Checks if anybody is logged in
  * @return {boolean} True if there is a logged in user, false if there isn't
  */
  loggedIn() {
    return !!windowsLocalStorage.admintoken;
  },
  /**
  * Get the stored admintoken
  * @return {String} token string
  */
  token() {
    return windowsLocalStorage.admintoken;
  },
  /**
  * Get the user's email
  * @return {String} email string
  */
  email() {
    const token = windowsLocalStorage.admintoken;
    if (token) {
      const payload = jwtDecode(token);
      return payload.email;
    }
    return '';
  },
  /**
  * Get the user's id
  * @return {String} id string
  */
  id() {
    return windowsLocalStorage.id;
  },
  /**
  * Get the user's authority
  * @return {String} authority number
  */
  auth() {
    return windowsLocalStorage.auth;
  },

  name() {
    const token = windowsLocalStorage.admintoken;
    if (token) {
      const payload = jwtDecode(token);
      return payload.name;
    }
    return '';
  },

  errorProcessing(error) {
    if (error?.status === 401) {
      windowsLocalStorage.removeItem('admintoken');
      return history.push(routePath.root);
    }

    return Promise.resolve({ success: false, err: error });
  },
};

export default auth;
