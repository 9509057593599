exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".styles__error___1UIgk{color:#ff4c4c;padding:.5em 1em;font-size:1em}.styles__verify-code-row___3lokM{display:-ms-flexbox;display:flex;-ms-flex-pack:justify;justify-content:space-between;-ms-flex-align:end;align-items:flex-end}.styles__verify-code-row___3lokM>div{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row}.styles__verify-code-row___3lokM>div>p{color:#999;font-weight:500}.styles__checkbox___3xA4w{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;position:relative;padding-left:20px;width:100%;cursor:pointer}.styles__checkbox___3xA4w>input{opacity:0;height:0;width:0}.styles__checkbox___3xA4w.styles__checked___2o-QM{font-weight:700;color:#333}.styles__checkbox___3xA4w:before{display:inline-block;content:\"\";margin-right:10px;vertical-align:text-top;width:16px;height:16px;border:1px solid #979797;border-radius:2px;background:#fff;box-shadow:0 0 3px 0 rgba(0,0,0,.07);position:absolute;top:0;left:0}.styles__checkbox___3xA4w.styles__checked___2o-QM:after{content:\"\";position:absolute;left:3px;top:7px;background:#3caa8c;width:2px;height:2px;box-shadow:2px 0 0 #3caa8c,4px 0 0 #3caa8c,4px -1px 0 #3caa8c,4px -3px 0 #3caa8c,4px -5px 0 #3caa8c,4px -7px 0 #3caa8c;transform:rotate(45deg)}.styles__checkbox___3xA4w>p{font-size:14px;line-height:1.2em}.styles__button___O2A1E{width:150px;margin:0 8px}.styles__second-button___1PUz3{width:150px;margin:0 8px;background-color:#6c757d}.styles__second-button___1PUz3:hover{background-color:#5a6268}.styles__second-button-disable___2WY3V{width:150px;margin:0 8px;cursor:not-allowed}.styles__second-button-disable___2WY3V:hover{background-color:#9ab1c6}.styles__word___IDdj2{white-space:nowrap}@media (max-width:719px){.styles__verify-code-row___3lokM>div{-ms-flex-direction:column;flex-direction:column}.styles__button___O2A1E,.styles__second-button___1PUz3{width:100px;margin:0 2px}}", ""]);

// exports
exports.locals = {
	"error": "styles__error___1UIgk",
	"verify-code-row": "styles__verify-code-row___3lokM",
	"verifyCodeRow": "styles__verify-code-row___3lokM",
	"checkbox": "styles__checkbox___3xA4w",
	"checked": "styles__checked___2o-QM",
	"button": "styles__button___O2A1E",
	"second-button": "styles__second-button___1PUz3",
	"secondButton": "styles__second-button___1PUz3",
	"second-button-disable": "styles__second-button-disable___2WY3V",
	"secondButtonDisable": "styles__second-button-disable___2WY3V",
	"word": "styles__word___IDdj2"
};