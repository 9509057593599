/**
 * FeaturePath: 會員系統-課程-課程清單
 * Accountable: Tom Jhuang, AlexCH Cheng
 */

import React, { lazy, Suspense } from 'react';

import LoadingDimmer from '../components/Utils/LoadingDimmer';

const Course = lazy(() => import('../components/Pages/Course'));

export const CoursePage = props => (
  <Suspense fallback={LoadingDimmer()}>
    <Course {...props} />
  </Suspense>
);
