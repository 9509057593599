/**
 * FeaturePath: 會員系統-其它-工具
 * Accountable: AlexCH Cheng, Landy Chu
 */

import { useState, useEffect } from 'react';
import { validateMail } from '../../../../../utils/utils';
import { isPhoneNumber, isIlearnAccountFormat } from '../../../../../utils/validator';

interface IInputContentErrorData {
  accountError?: string;
  verifyCodeError?: string;
  nameError?: string;
  emailError?: string;
  passwordError?: string;
  passwordConfirmError?: string;
  [x: string]: string | undefined;
}
type ValidationFn<T> = (value: T) => boolean;

const hasSpecialCharacterOrDigit: ValidationFn<string> = name =>
  /[!@#$%^&*(),.?":{}|<>0-9]/g.test(name);

export const isAllChinese: ValidationFn<string> = name => /^[\u3400-\u9FD5\uF900-\uFAFF\u{20000}-\u{2FA1D}]+$/gu.test(name);

export const isAllEnglish: ValidationFn<string> = name => /^[a-zA-Z\s]+$/g.test(name);

export const containsSpace: ValidationFn<string> = str => /\s+/gu.test(str);

export const removeChineseNameSpaces = (name: string): string => {
  if (!isAllEnglish(name) && containsSpace(name)) {
    return name.replace(/\s+/g, '');
  }
  return name;
};

const getAccountError = (account:string) => {
  if (!account) {
    return '請輸入手機號碼取得認證碼(外籍人士請輸入手機號碼或居留證號)';
  }
  if (!isIlearnAccountFormat(account)) {
    return '格式錯誤';
  }
  return '';
};

const getVerifyCodeError = (verifyCode: string) => {
  if (!verifyCode) {
    return '請輸入認證碼';
  }
  if (!/^\d{4}$/i.test(verifyCode)) {
    return '格式錯誤';
  }
  return '';
};

const getNameError = (name: string, registerMode : boolean) => {
  if (registerMode) {
    const trimmedName = name?.trim();
    const cleanedName = removeChineseNameSpaces(trimmedName);

    if (!cleanedName || !name) {
      return '請輸入真實姓名';
    }

    if (
      hasSpecialCharacterOrDigit(cleanedName) ||
    (!isAllChinese(cleanedName) && !isAllEnglish(cleanedName))) {
      return '姓名只可以是全部中文字或是全部英文字，且不含特殊字元及數字';
    }
  }
  return '';
};

const getEmailError = (email: string, registerMode : boolean) => {
  if (registerMode) {
    if (!email) {
      return '請輸入電子信箱';
    }
    if (!validateMail(email)) {
      return '格式錯誤';
    }
  }
  return '';
};

const getPasswordError = (password:string) => {
  if (!password) {
    return '請輸入密碼';
  }
  if (!/^[a-zA-Z0-9]{8,16}$/i.test(password)) {
    return '格式錯誤';
  }
  return '';
};

const getPasswordConfirmError = (password:string, passwordConfirm:string) => {
  if (password !== passwordConfirm) {
    return '密碼不一致';
  }
  return '';
};


function useFormValidation(data: any, registerMode: boolean) {
  const [errorData, setErrorData] = useState<IInputContentErrorData>({});

  useEffect(() => {
    const { account, verifyCode, name, email, password, passwordConfirm } = data;
    const newErrorData: IInputContentErrorData = {
      accountError: getAccountError(account),
      verifyCodeError: isPhoneNumber(account) ? getVerifyCodeError(verifyCode) : '',
      nameError: getNameError(name, registerMode),
      emailError: getEmailError(email, registerMode),
      passwordError: getPasswordError(password),
      passwordConfirmError: getPasswordConfirmError(password, passwordConfirm),
    };

    setErrorData(newErrorData);
  }, [data, registerMode]);

  return { errorData };
}

export default useFormValidation;
