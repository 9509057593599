/**
 * FeaturePath: 會員系統-購物車-願望清單
 * Accountable: AlexCH Cheng, Landy Chu
 */

import objectAssign from 'object.assign';

import {
  SET_LIST,
} from '../constants/wishes';
// Object.assign is not yet fully supported in all browsers, so we fallback to
// a polyfill
const assign = Object.assign || objectAssign;

// The initial application state
const initialState = {
  list: [],
};

// Takes care of changing the application state
export function wishes(state = initialState, action: { type: string; [key: string]: any; }) {
  switch (action.type) {
    case SET_LIST:
      return assign({}, state, {
        list: action.list,
      });
    default:
      return state;
  }
}
